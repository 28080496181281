import { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import dayjs from 'dayjs'
import dayOfYear from 'dayjs/plugin/dayOfYear'
import axios from 'axios'
dayjs.extend(dayOfYear)
class ReservePage extends Component {
  max_date = dayjs().endOf('year').format('YYYY-MM-DD')
  initState = {
    tel: '',
    email: '',
    college: '',
    start_date: '',
    end_date: '',
    semester: '',
    tutor_name: '',
    amount: '',
    subject: '',
    plan: '',
    file: [],
    file_url: [],
    step: 1,
    isLoading: false,
  }
  constructor(props) {
    super(props)
    this.state = this.initState
  }
  fetchSemester = () => {
    axios
      .get('/en/prof/api/getSemesterFrontList')
      .then((res) => {
        if (res.data.status === 'OK') {
          const today = dayjs()
          let semesterList = res.data.result.semesterList
          let new_s = semesterList.find((s) => today.isAfter(s.start_date) && today.isBefore(s.end_date))
          new_s = new_s ? new_s : semesterList[semesterList.length - 1]
          this.setState({ semester: new_s })
        } else {
          // console.log(res.data.result)
          alert('獲取資料失敗')
        }
      })
      .catch((e) => {
        // console.log(e)
        alert('獲取資料失敗')
      })
  }

  componentDidMount() {
    this.fetchSemester()
    // console.log(this.max_date)
  }

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value })
  }

  handleFileInputChange = (e) => {
    let newFile = this.state.file
    for (const f of e.target.files) {
      newFile.push(f)
    }
    if (newFile.length <= 5) {
      this.setState({ file: newFile })
      // console.log(newFile)
    } else {
      alert('最多上傳五個')
    }
  }
  showSelectedFile = (file) => {
    return (
      <div className='selected-file d-flex justify-content-between p-3 mb-2'>
        <p className='m-0'>{file.name}</p>
        <i type='button' onClick={() => this.deleteSelectedFile(file.name)} class='fas fa-trash'></i>
      </div>
    )
  }
  deleteSelectedFile = (fileName) => {
    const newFile = this.state.file.filter((e) => e.name !== fileName)
    if (newFile.length === 0) document.getElementById('file').value = ''
    this.setState({ file: newFile })
  }
  nextStep = (step) => {
    this.setState({ step: step + 1 })
  }
  handleSubmit = async (e) => {
    e.preventDefault()
    for (const f of this.state.file) {
      let formdata = new FormData()
      formdata.append('file1', f)
      try {
        const res = await axios.post('/en/prof/api/upload_file/' + this.props.login.token, formdata)
        if (res.data.status === 'OK') {
          let new_f = this.state.file_url
          new_f.push(res.data.result.url)
          this.setState({ file_url: new_f })
        } else {
          // console.log(res.data)
          alert(res.data.result)
        }
      } catch (err) {
        // console.log(err)
        alert('上傳檔案失敗')
      }
    }

    const body = {
      mode: 'Add',
      token: this.props.login.token,
      semester_id: parseInt(this.state.semester.semester_id),
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      tutor_name: this.state.tutor_name,
      amount: this.state.amount,
      subject: this.state.subject,
      plan: this.state.plan,
      tel: this.state.tel,
      attachment: this.state.file_url[0],
    }
    // console.log(body)
    this.setState({ isLoading: true })
    axios
      .post('/en/prof/api/deptApplicationSubmit', body)
      .then((res) => {
        if (res.data.status === 'OK') {
          this.nextStep(2)
        } else {
          // console.log(res.data.result)
          alert('發生錯誤')
        }
      })
      .catch((e) => {
        // console.log(e)
        alert('發生錯誤')
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }

  reset = () => {
    this.setState((s) => ({
      ...this.initState,
      file: [],
      file_url: [],
      semester: s.semester,
    }))
  }
  render() {
    return (
      <div className='container mb-5'>
        <div className='absolute-bg'></div>
        <h1 className='size-1 my-5 text-left text-sm-center'>線上申請</h1>

        <div className={'p-2 p-sm-5 bg-white reserve-box size-5' + (this.state.step !== 1 ? ' d-none' : '')}>
          <h2 className='size-4 text-center mb-2 mb-sm-5'>請填寫您的聯絡資料</h2>

          <Form.Group as={Row} controlId='name'>
            <Form.Label column sm='2'>
              承辦人員
            </Form.Label>
            <Col sm='10'>
              <Form.Control plaintext readOnly defaultValue={this.props.login.data.name} />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId='college'>
            <Form.Label column sm='2'>
              學院
            </Form.Label>
            <Col sm='10'>
              <Form.Control plaintext readOnly defaultValue={this.props.login.data.degree} />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId='dept'>
            <Form.Label column sm='2'>
              學系
            </Form.Label>
            <Col sm='10'>
              <Form.Control plaintext readOnly defaultValue={this.props.login.data.dept} />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId='email'>
            <Form.Label column sm='2'>
              信箱
            </Form.Label>
            <Col sm='10'>
              <Form.Control plaintext readOnly defaultValue={this.props.login.data.email} />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId='tel'>
            <Form.Label column sm='2'>
              電話號碼
            </Form.Label>
            <Col sm='10'>
              <Form.Control
                onChange={this.handleChange}
                value={this.state.tel}
                required
                type='phone'
                placeholder='例：(02)0000-0000 #123'
              />
            </Col>
          </Form.Group>

          <div className='text-center'>
            <button className='submit-btn text-white' onClick={() => this.nextStep(1)} disabled={!this.state.tel}>
              下一步
            </button>
          </div>
        </div>

        <div className={'p-2 p-sm-5 bg-white reserve-box size-5' + (this.state.step !== 2 ? ' d-none' : '')}>
          <h2 className='size-4 text-center mb-2 mb-sm-5'>請填寫申請資料</h2>

          <Form.Group as={Row} controlId='semester'>
            <Form.Label column sm='2'>
              學期
            </Form.Label>
            <Col sm='10'>
              <Form.Control plaintext readOnly defaultValue={this.state.semester.title} />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId='start_date'>
            <Form.Label column sm='2'>
              開始日期
            </Form.Label>
            <Col sm='10'>
              <Form.Control
                type='date'
                max={this.max_date}
                onChange={this.handleChange}
                value={this.state.start_date}
              ></Form.Control>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId='end_date'>
            <Form.Label column sm='2'>
              結束日期
            </Form.Label>
            <Col sm='10'>
              <Form.Control
                type='date'
                max={this.max_date}
                onChange={this.handleChange}
                value={this.state.end_date}
              ></Form.Control>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId='tutor_name'>
            <Form.Label column sm='2'>
              小老師姓名
            </Form.Label>
            <Col sm='10'>
              <Form.Control
                onChange={this.handleChange}
                value={this.state.tutor_name}
                required
                type='text'
                placeholder='請輸入小老師姓名'
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId='amount'>
            <Form.Label column sm='2'>
              申請金額
            </Form.Label>
            <Col sm='10'>
              <Form.Control
                onChange={this.handleChange}
                value={this.state.amount}
                required
                type='number'
                placeholder='請輸入您欲申請之金額'
              />
              <Form.Text className='text-muted'>金額請以整學期計，不含勞健保費</Form.Text>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId='subject'>
            <Form.Label column sm='2'>
              輔導科目
            </Form.Label>
            <Col sm='10'>
              <Form.Control
                onChange={this.handleChange}
                value={this.state.subject}
                required
                type='text'
                placeholder='請輸入小老師預計輔導的科目全稱'
              />
              <Form.Text className='text-muted'>科目之間請以全形或半形逗號隔開</Form.Text>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId='plan'>
            <Form.Label column sm='2'>
              輔導規劃
            </Form.Label>
            <Col sm='10'>
              <Form.Control
                onChange={this.handleChange}
                value={this.state.plan}
                required
                type='text'
                as='textarea'
                placeholder='請輸入您預計的輔導規劃'
              />
              <Form.Text className='text-muted'>請說明為何需要針對該科目開設課業輔導</Form.Text>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId='file'>
            <Form.Label column sm='2'>
              應徵資料表與成績單
            </Form.Label>
            <Col sm='10' className='text-center'>
              {this.state.file.map(this.showSelectedFile)}
              <Form.File
                label='上傳檔案 (限.pdf)'
                onChange={this.handleFileInputChange}
                disabled={this.state.file.length > 0}
                accept='.pdf'
                custom
              />
            </Col>
          </Form.Group>

          <Row>
            <Col className='text-right' xs={6}>
              <button className='submit-btn text-white' onClick={() => this.nextStep(0)}>
                上一步
              </button>
            </Col>
            <Col className='text-left' xs={6}>
              <button
                className='submit-btn text-white'
                onClick={this.handleSubmit}
                disabled={
                  !(
                    this.state.semester &&
                    this.state.start_date &&
                    this.state.end_date &&
                    this.state.tutor_name &&
                    this.state.amount &&
                    this.state.subject &&
                    this.state.plan
                  ) || this.state.isLoading
                }
              >
                {this.state.isLoading ? '請稍等' : '送出'}
              </button>
            </Col>
          </Row>
        </div>

        <div
          className={'p-2 p-sm-5 bg-white reserve-box size-5 text-center' + (this.state.step !== 3 ? ' d-none' : '')}
        >
          <i class='fas fa-check reserve-success-icon'></i>

          <p className='size-4 my-3'>如有進一步消息會儘速通知您</p>

          <div>
            <Link to='/special/apply-record'>
              <Button variant='orange'>查詢申請紀錄</Button>
            </Link>
          </div>
          <div className='mt-3'>
            <Button variant='orange' onClick={this.reset}>
              繼續申請
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
  }
}

export default connect(mapStateToProps)(ReservePage)
