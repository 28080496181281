import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import NewsItem from 'components/basic_page/news/news_item.js'

function NewsList({ news }) {
  const lang = useSelector((s) => s.front.lang)
  return (
    <div>
      {news.map((post) => (
        <Link to={`/basic/news/${post.article_id}`}>
          <NewsItem
            isNewest={post.stick_to_top == 1 ? true : false}
            date={post.date}
            title={lang === 'zh' ? post.title_zh : post.title_en}
            content={lang === 'zh' ? post.content_zh : post.content_en}
          />
        </Link>
      ))}
    </div>
  )
}

export default NewsList
