import { useSelector } from 'react-redux'
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom'
import jumbotronPic from 'img/logos/weblogo/advanced_1.svg'

function Jumbotron({ alert }) {
  const lang = useSelector((s) => s.front.lang)
  let content = {
    english: {
      emergency: 'Emergency',
      title: 'Professional Service',
      text: 'Tackle your problem with tutors and improve your learning results',
      button: 'Department apply',
    },
    chinese: {
      emergency: '緊急通知',
      title: '專業學科學習諮詢',
      text: '與小老師一同面對課業問題，提升學習成效',
      button: '系辦申請',
    },
  }
  if (lang === 'zh') {
    content = content.chinese
  } else if (lang === 'en') {
    content = content.english
  }

  let alertItem = <div></div>
  if (alert !== '') {
    alertItem = (
      <div class='alert alert-danger mt-3' role='alert'>
        {content.emergency}
        <div
          className='alert-danger-text'
          dangerouslySetInnerHTML={{ __html: lang === 'zh' ? alert.content_zh : alert.content_en }}
        ></div>
      </div>
    )
  }

  return (
    <div className='row jumbotron-container'>
      <div className='col-sm-5 order-sm-1 order-2 d-flex flex-column justify-content-center px-5'>
        <h3 className='size-1 text-center text-sm-left'>{content.title}</h3>
        <h4 className='size-4 py-3 text-center text-sm-left'>{content.text}</h4>
        <div className='text-center text-sm-left'>
          <Link to='/special/apply'>
            <Button variant='orange'>{content.button}</Button>
          </Link>
        </div>

        {alertItem}
      </div>
      <div className='col-sm-7 order-sm-2 order-1 text-center text-sm-right mb-5 mb-sm-0'>
        <img src={jumbotronPic} className='basic-1' alt='' />
      </div>
    </div>
  )
}

export default Jumbotron
