import { Suspense, lazy } from 'react'
import BasicPage from 'containers/basic_page/main/basic_page'
import { Switch, Route } from 'react-router-dom'
import { Provider } from 'react-redux'
import { basic_store } from 'store/basic/configureStore'
import PrivateRoute from 'private-route.js'
import SetTokenFromCookie from 'set-token.js'
const BasicDBPage = lazy(() => import('containers/basic_db/main/basic_db'))

function Basic() {
  return (
    <Switch>
      <Provider store={basic_store}>
        <SetTokenFromCookie cate='basic' />
        <Suspense fallback={<div>載入中...</div>}>
          <PrivateRoute path='/basic_db' check_role='admin' cate='basic' Component={BasicDBPage} />
        </Suspense>
        <Route path='/basic' render={({ match }) => <BasicPage match={match} />} />
      </Provider>
    </Switch>
  )
}

export default Basic
