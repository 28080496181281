import { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import LoginButton from 'components/basic_page/login_button.js'
import SmallNavbar from 'components/basic_page/small_navbar.js'
import navLogo from 'img/logos/logo/logo_color_bg.svg'
import { setLang } from 'actions/basic/actions'

class BasicPageNavbar extends Component {
  constructor(props) {
   
    super(props)
    this.state = {
      smallOpen: false,
    }

    this.content = {
      english: {
        reserve: 'Online Reservation',
        reserve_record: 'Reservation Records',
        checkin: 'Check-In',
        history: 'Tutoring Records',
        class_history: 'Tutoring Feedbacks',
        index: 'Home',
        news: 'News',
        service: 'About the Service',
        download_important: 'Regulations',
        download_normal: 'Form Download',
        schedule: 'Course Schedule',
        qna: 'FAQ',
        contact: 'Contact Us',
        eval: 'End-of-term Evaluation',
      },
      chinese: {
        reserve: '線上預約',
        reserve_record: '預約紀錄',
        checkin: '打卡',
        history: '歷史紀錄',
        class_history: '輔導紀錄',
        index: '首頁',
        news: '最新消息',
        service: '關於服務',
        download_important: '重要法規',
        download_normal: '線上教材',
        schedule: '課表',
        qna: '常見問題',
        contact: '聯絡我們',
        eval: '期末意見調查',
      },
    }
  
  }
 
  smallNavbarClick = () => {
    const body = document.body
    if (body.classList.contains('modal-open')) {
      body.classList.remove('modal-open')
    } else {
      body.classList.add('modal-open')
    }
    this.setState((state) => ({ smallOpen: !state.smallOpen }))
  }

  render() {
    let content
    if (this.props.lang === 'zh') {
      content = this.content.chinese
    } else if (this.props.lang === 'en') {
      content = this.content.english
    }
   // console.log(this.props.login.data);
    let nav_item
   
    if (this.props.login.role === 'tutor') {
      nav_item = (
        <>
          <Link to={`${this.props.match.path}/checkin/checkin_record`} className='text-light mx-1 mx-md-3 navlinks'>
            {content.checkin}
          </Link>
          <Link to={`${this.props.match.path}/record/tutor`} className='text-light mx-1 mx-md-3 navlinks'>
            {content.reserve_record}
          </Link>
          <Link to={`${this.props.match.path}/history/tutor`} className='text-light mx-1 mx-md-3 navlinks'>
            {content.history}
          </Link>
          {this.props.login.data.startReponse && this.props.login.data.questionnaire_array[this.props.login.role]?
          <a
            href={`${process.env.REACT_APP_BACKEND_API}/zh/feedback/${this.props.login.data.questionnaire_array[this.props.login.role]}/${this.props.login.token}`}
            className='text-light mx-1 mx-md-3 navlinks'
          > 
            {content.eval}
          </a>:''}
        </>
      )
    } else if (this.props.login.role === 'student') {
   
      nav_item = (
        <>
          <Link to={`${this.props.match.path}/reserve`} className='text-light mx-1 mx-md-3 navlinks'>
            {content.reserve}
          </Link>
          <Link to={`${this.props.match.path}/record/student`} className='text-light mx-1 mx-md-3 navlinks'>
            {content.reserve_record}
          </Link>
          <Link to={`${this.props.match.path}/history/student`} className='text-light mx-1 mx-md-3 navlinks'>
            {content.class_history}
          </Link>
          {this.props.login.data.startReponse && this.props.login.data.questionnaire_array[this.props.login.role]?
          <a
            href={`${process.env.REACT_APP_BACKEND_API}/zh/feedback/${this.props.login.data.questionnaire_array[this.props.login.role]}/${this.props.login.token}`}
            className='text-light mx-1 mx-md-3 navlinks'
          > 
            {content.eval}
          </a>:''}
        </>
      )
    }

    return (
      <div className='basic-navbar'>
        <Navbar expand='lg' bg='success' variant='success' className='top-navbar'>
          <Container fluid className='px-0'>
            <Navbar.Brand>
              <Link to='/' className='mx-0'>
                <img src={navLogo} className='d-inline-block align-top mx-0 mx-md-3' alt='' loading='lazy' />
                <div className='d-none d-sm-inline-block main-page-text'>
                  <h1 className='nav-logo-text'>臺大學習諮詢</h1>
                  <h2 className='nav-logo-subtext'>NTU Academic Counseling Service</h2>
                </div>
              </Link>
            </Navbar.Brand>

            <div className='d-flex justify-content-end'>
              <div className='d-none d-lg-block navlinks'>{this.props.login.token !== '' ? nav_item : null}</div>

              <LoginButton />

              <Navbar.Toggle onClick={this.smallNavbarClick}>
                <i class='fas fa-bars text-light'></i>
              </Navbar.Toggle>
            </div>
          </Container>
        </Navbar>

        <nav className='navbar d-none d-lg-flex justify-content-between text-dark py-3 bg-white'>
          <ul className='navbar-nav flex-row mr-auto'>
            <li className='nav-item mx-2'>
              <Link to={this.props.match.path} className='nav-link'>
                {content.index}
              </Link>
            </li>
            <li className='nav-item mx-2'>
              <Link to={`${this.props.match.path}/news`} className='nav-link'>
                {content.news}
              </Link>
            </li>
            <li className='nav-item mx-2 dropdown'>
              <Link to={`${this.props.match.path}/service`} className='nav-link'>
                {content.service}
              </Link>
            </li>
            <li className='nav-item mx-2'>
              <Link to={`${this.props.match.path}/schedule`} className='nav-link'>
                {content.schedule}
              </Link>
            </li>
            <li className='nav-item mx-2'>
              <Link to={`${this.props.match.path}/question`} className='nav-link'>
                {content.qna}
              </Link>
            </li>
            <li className='nav-item mx-2'>
              <Link to={`${this.props.match.path}/contact`} className='nav-link'>
                {content.contact}
              </Link>
            </li>
            <li className='nav-item mx-2'>
              <Link to={`${this.props.match.path}/download/regulation`} className='nav-link'>
                {content.download_important}
              </Link>
            </li>
            <li className='nav-item mx-2'>
              <Link to={`${this.props.match.path}/download/form`} className='nav-link'>
                {content.download_normal}
              </Link>
            </li>
          </ul>

          <div className='mr-1 lang text-bold'>
            <div
              className={'mx-3 lang-option d-inline ' + (this.props.lang === 'zh' ? 'lang-active' : '')}
              type='button'
              onClick={() => this.props.dispatch(setLang('zh'))}
            >
              中文
            </div>
            |
            <div
              className={'mx-3 lang-option d-inline ' + (this.props.lang === 'en' ? 'lang-active' : '')}
              type='button'
              onClick={() => this.props.dispatch(setLang('en'))}
            >
              EN
            </div>
          </div>
        </nav>

        <div className='d-lg-none'>
          <SmallNavbar match={this.props.match} smallNavbarClick={this.smallNavbarClick} open={this.state.smallOpen} />
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
    lang: state.front.lang,
  }
}

export default connect(mapStateToProps)(BasicPageNavbar)
