import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown'
import { setLoginRoleAndVerify, fetchProfile, userLogout, userFakeLogin } from 'actions/individual/actions'
import axios from 'axios'

function LoginButton() {
  const lang = useSelector((s) => s.front.lang)
  const token = useSelector((s) => s.login.token)
  const isAdmin = useSelector((s) => s.login.isAdmin)
  const role = useSelector((s) => s.login.role)
  const name = useSelector((s) => s.login.name)
  const name_en = useSelector((s) => s.login.data.name_en)
  const dispatch = useDispatch()
  const history = useHistory()

  let content = {
    english: {
      switch_student: 'Change to student',
      switch_tutor: 'Change to tutor',
      switch_teacher: 'Change to teacher',
      sign_out: 'Sign out',
      sign_in: 'Sign in',
    },
    chinese: {
      switch_student: '切換學生身份',
      switch_tutor: '切換小老師身份',
      switch_teacher: '切換教師身份',
      sign_out: '登出',
      sign_in: '登入',
    },
  }

  if (lang === 'zh') {
    content = content.chinese
  } else if (lang === 'en') {
    content = content.english
  }

  const changeRole = (r) => {
    dispatch(setLoginRoleAndVerify(r, token))
    history.push('/individual')
  }

  useEffect(() => {
    if (token != '' && name === '') {
      dispatch(fetchProfile(token))
    }
    if (token != '' && isAdmin === undefined) {
      axios.get(`/en/spcl/api/tokenChecknValidation/${token}/admin`).then((res) => {
        dispatch({ type: 'SET_ISADMIN', isAdmin: res.data.status === 'OK' })
      })
    }
  }, [token])

  const fakeLogin = () => {
    dispatch(userFakeLogin())
  }

  const logout = () => {
    dispatch(userLogout())
    history.push('/individual')
  }

  if (token !== '') {
    let dropdown_item

    if (role === 'tutor') {
      dropdown_item = <Dropdown.Item onClick={() => changeRole('student')}>{content.switch_student}</Dropdown.Item>
    } else if (role === 'student') {
      dropdown_item = <Dropdown.Item onClick={() => changeRole('tutor')}>{content.switch_tutor}</Dropdown.Item>
    } else if (role === 'admin') {
      dropdown_item = (
        <>
          <Dropdown.Item onClick={() => changeRole('student')}>{content.switch_student}</Dropdown.Item>
          <Dropdown.Item onClick={() => changeRole('tutor')}>{content.switch_tutor}</Dropdown.Item>
          <Dropdown.Item onClick={() => changeRole('lecturer')}>{content.switch_teacher}</Dropdown.Item>
        </>
      )
    }

    return (
      <Dropdown className='text-right'>
        <Dropdown.Toggle variant='orange' className='loginButton'>
          {'Hi, ' + (lang === 'zh' ? name : name_en)}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {dropdown_item}
          {isAdmin ? (
            <Link to='/individual_db'>
              <Dropdown.Item href='.'>前往後台</Dropdown.Item>
            </Link>
          ) : null}
          <Dropdown.Item onClick={logout}>{content.sign_out}</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    )
  } else {
    if (process.env.NODE_ENV === 'production')
      return (
        <Button
          href={`${process.env.REACT_APP_BACKEND_API}/en/spcl/api/signin/student?to=${
            window.location.href.split(/[?#]/)[0]
          }?role=student`}
          variant='orange'
          className='loginButton'
        >
          {content.sign_in}
        </Button>
      )
    else
      return (
        <Button variant='orange' onClick={fakeLogin} className='loginButton'>
          {content.sign_in}
        </Button>
      )
  }
}

export default LoginButton
