import { useState } from 'react'
import { useSelector } from 'react-redux'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { Link } from 'react-router-dom'
import jumbotronPic from 'img/logos/weblogo/basic_1.svg'

function Jumbotron({ alert }) {
  const lang = useSelector((s) => s.front.lang)
  let content = {
    english: {
      emergency: 'Emergency',
      title: 'Common Courses Tutoring Service',
      text: 'Tackle your problem with tutors and improve your learning results',
      button: 'Reserve Now',
    },
    chinese: {
      emergency: '緊急通知',
      title: '基礎學科學習諮詢',
      text: '與小老師一同面對課業問題，提升學習成效',
      button: '馬上預約',
    },
  }
  if (lang === 'zh') {
    content = content.chinese
  } else if (lang === 'en') {
    content = content.english
  }

  // let alertItem = <div></div>
  // if (alert) {
  //   alertItem = (
  //     <div class='alert alert-danger mt-3' role='alert'>
  //       {content.emergency}
  //       <div
  //         className='alert-danger-text'
  //         dangerouslySetInnerHTML={{ __html: lang === 'zh' ? alert.content_zh : alert.content_en }}
  //       ></div>
  //     </div>
  //   )
  // }

  return (
    <div className='row jumbotron-container'>
      <div className='col-sm-5 order-sm-1 order-2 d-flex flex-column justify-content-center px-5'>
        <h3 className='size-1 text-center text-sm-left'>{content.title}</h3>
        <h4 className='size-4 py-3 text-center text-sm-left'>{content.text}</h4>
        <div className='text-center text-sm-left'>
          <Link to='/basic/reserve'>
            <Button variant='orange'>{content.button}</Button>
          </Link>
        </div>

        {alert ? <AlertModal alert={alert} /> : null}
      </div>
      <div className='col-sm-7 order-sm-2 order-1 text-center text-sm-right mb-5 mb-sm-0'>
        <img src={jumbotronPic} className='basic-1' alt='jombotron' />
      </div>
    </div>
  )
}

export default Jumbotron

function AlertModal({ alert }) {
  const [show, setShow] = useState(true)
  const lang = useSelector((s) => s.front.lang)
  let content = {
    english: {
      emergency: 'Emergency',
      close: 'Close',
    },
    chinese: {
      emergency: '緊急通知',
      close: '關閉',
    },
  }
  if (lang === 'zh') {
    content = content.chinese
  } else if (lang === 'en') {
    content = content.english
  }
  const handleClose = () => setShow(false)

  return (
    <Modal size='lg' show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{content.emergency}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          className='alert-danger-text'
          dangerouslySetInnerHTML={{ __html: lang === 'zh' ? alert.content_zh : alert.content_en }}
        ></div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          {content.close}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
