import { useState } from 'react'
import { useSelector } from 'react-redux'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'

const ChineseWeek = ['星期一', '星期二', '星期三', '星期四', '星期五']

function ScheduleTable({ schedule, showSubject }) {
  const today = dayjs()
  const lang = useSelector((s) => s.front.lang)
  const show_time_slot = useSelector((s) => s.front.timeSlot)

  const getRowData = (schedule, slot) => {
    let res = []
    schedule.forEach((el) => {
      let ccname = 'p-2 course-div'
      if (el.date.isSame(today, 'day')) {
        ccname += ' today-red'
      } else if (el.date.isAfter(today, 'day')) {
        ccname += ' over-today'
      }

      const this_slot_course = el.course.filter((c) => c.time_slot_id == slot)
      const end_day = dayjs().add(14, 'day')

      if (el.course.length > 0) {
        res.push(
          <Col xs={2} className={ccname}>
            <ScheduleModal
              data={this_slot_course}
              date={
                lang === 'zh'
                  ? el.date.format('YYYY/MM/DD ') + ChineseWeek[el.date.get('day') - 1]
                  : el.date.format('YYYY/MM/DD ddd')
              }
              showSubject={showSubject}
              disable={
                !el.date.isAfter(today, 'day') ||
                el.date.isAfter(end_day, 'day') ||
                this_slot_course.find((c) => c.studentBookBefore)
              }
            />
          </Col>
        )
      } else {
        res.push(<Col xs={2} className={ccname}></Col>)
      }
    })
    return res
  }

  return (
    <div className='schedule-wrapper d-flex flex-column text-darkgrey mt-3 mt-sm-5'>
      <Row className='text-center flex-nowrap'>
        <Col xs={2} className='schedule-timelabel'></Col>
        {schedule.map((el) => {
          let ccname = 'schedule-header size-3'
          if (el.date.isSame(today, 'day')) {
            ccname += ' today-head'
          } else if (el.date.isAfter(today, 'day')) {
            ccname += ' over-today-head'
          }
          return (
            <Col xs={2} className={ccname}>
              <p className='m-0 p-1 px-1 px-sm-3 schedule-date text-light'>{el.date.format('MM/DD')}</p>
              <p className='schedule-date-word mb-1 mb-sm-3'>
                {lang === 'zh' ? ChineseWeek[el.date.get('day') - 1] : el.date.format('ddd')}
              </p>
            </Col>
          )
        })}
      </Row>

      {show_time_slot.map((time_slot, idx) => (
        <Row key={idx} className='text-center flex-nowrap schedule-course-row'>
          <Col xs={2} className='my-auto schedule-timelabel'>
            {time_slot.code}
            <br />
            {time_slot.time}
          </Col>
          {getRowData(schedule, time_slot.id)}
        </Row>
      ))}
    </div>
  )
}

export default ScheduleTable

function ScheduleModal(props) {
  const lang = useSelector((s) => s.front.lang)
  const [show_modal, setShowModal] = useState(new Array(props.data.length).fill(false))
  const [show, setShow] = useState(false)
  const [showCourse, setShowCourse] = useState(false)
  const show_time_slot = useSelector((s) => s.front.timeSlot)

  const handleClose = () => {
    setShowModal(new Array(props.data.length).fill(false))
    setShowCourse(false)
    setShow(false)
  }
  const handleShow = () => setShow(true)

  const handleShowCourse = (id) => {
    let new_a = new Array(props.data.length).fill(false)
    new_a[id] = true
    setShowModal(new_a)
    setShowCourse(true)
  }

  let content = {
    english: {
      disabled: "Can't Reserve",
      enabled: 'Reservation Available',
      date: 'Date',
      time_slot: 'Period',
      subject: 'Subject',
      tutor: 'Tutor',
      status: 'Reservation Status',
      reserve: 'Reserve Now',
      substitute: ' (Substitute)',
      leave: ' (Canceled)',
    },
    chinese: {
      disabled: '無法預約',
      enabled: '尚有名額',
      date: '日期',
      time_slot: '節次',
      subject: '科目',
      tutor: '小老師',
      status: '預約狀況',
      reserve: '我要預約',
      substitute: '（代）',
      leave: '（假）',
    },
  }
  if (lang === 'zh') {
    content = content.chinese
  } else if (lang === 'en') {
    content = content.english
  }

  props.data.forEach((c) => {
    if (c.on_replacement) c.tutor_detail = c.tutor_replacement
    c.disabled = (c.on_leave && !c.on_replacement) || c.studentBookBefore || c.booked_slot >= 2
  })

  return (
    <>
      <div onClick={handleShow}>
        {props.data.map((c) => {
          let text = props.showSubject
            ? lang === 'zh'
              ? c.tutor_detail.tutor_subjects
              : c.tutor_detail.tutor_subjects_en
            : lang === 'zh'
            ? c.tutor_detail.name
            : c.tutor_detail.name_en

          if (c.on_replacement) {
            text += content.substitute
          } else if (c.on_leave) {
            text += content.leave
          }
          return <p className='mb-1 p-0 px-1 p-sm-1 bg-darkgrey text-white text-left small-course-name'>{text}</p>
        })}
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.date}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='d-flex flex-column font-weight-normal'>
          {props.data.map((c, idx) => (
            <div onClick={() => handleShowCourse(idx)} className={showCourse ? 'd-none' : ''} type='button'>
              <p>
                {(lang === 'zh' ? c.tutor_detail.tutor_subjects : c.tutor_detail.tutor_subjects_en) +
                  (c.on_replacement ? content.substitute : '') +
                  (c.on_leave && !c.on_replacement ? content.leave : '')}
              </p>
              <p className='text-grey text-right'>
                {props.disable || c.disabled ? content.disabled : content.enabled}
                <i class='fas fa-chevron-right ml-2' style={{ color: '#9FCECD' }}></i>
              </p>
            </div>
          ))}
          {props.data.map((c, idx) => (
            <div className={show_modal[idx] ? '' : 'd-none'}>
              <div className='d-flex justify-content-between mb-3'>
                <span>{content.date}</span>
                <span>{props.date}</span>
              </div>
              <div className='d-flex justify-content-between mb-3'>
                <span>{content.time_slot}</span>
                <span>
                  {c.time_slot_text +
                    (lang === 'zh'
                      ? ' 第' + show_time_slot.find((s) => s.id == c.time_slot_id).code + '節'
                      : ' No.' + show_time_slot.find((s) => s.id == c.time_slot_id).code)}
                </span>
              </div>
              <div className='d-flex justify-content-between mb-3'>
                <span>{content.subject}</span>
                <span className='text-right'>
                  {lang === 'zh' ? c.tutor_detail.tutor_subjects : c.tutor_detail.tutor_subjects_en}
                </span>
              </div>
              <div className='d-flex justify-content-between mb-3'>
                <span>{content.tutor}</span>
                <span>{lang === 'zh' ? c.tutor_detail.name : c.tutor_detail.name_en}</span>
              </div>
              <div className='d-flex justify-content-between mb-3'>
                <span>{content.status}</span>
                <span>
                  {[...Array(parseInt(c.booked_slot))].map((e) => (
                    <i class='fas fa-user ml-1'></i>
                  ))}
                  {[...Array(2 - parseInt(c.booked_slot))].map((e) => (
                    <i class='far fa-user ml-1'></i>
                  ))}
                </span>
              </div>
              <hr className='mb-3' />
              <Link
                to={{
                  pathname: '/basic/reserve',
                  state: { course: c, date: props.date },
                }}
              >
                <Button
                  variant='primary'
                  size='lg'
                  className='i-want-reserve-btn'
                  block
                  disabled={props.disable || c.disabled}
                >
                  {props.disable || c.disabled ? content.disabled : content.reserve}
                </Button>
              </Link>
            </div>
          ))}
        </Modal.Body>
      </Modal>
    </>
  )
}
