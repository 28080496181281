import { Component } from 'react'
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Link } from 'react-router-dom'

import axios from 'axios'
class NewsShow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      news: {
        title: '',
        content: '',
        date: '',
        isNewest: false,
      },
    }

    this.content = {
      english: {
        title: 'News',
        back: 'Back to News',
      },
      chinese: {
        title: '最新消息',
        back: '返回最新消息',
      },
    }
  }

  componentDidMount() {
    const id = this.props.match.params.id
    axios
      .get('/en/api/getArticleFrontDetail/' + id)
      .then((res) => {
        // console.log(res)
        if (res.data.status === 'OK') {
          const news = res.data.result.articleDetail
          this.setState({
            news: news,
          })
        } else {
          alert(res.data.result)
        }
      })
      .catch((err) => {
        // console.log(err)
        alert(this.props.lang === 'zh' ? '獲取消息失敗' : 'Fetch news failed!')
      })
  }

  render() {
    let content
    if (this.props.lang === 'zh') {
      content = this.content.chinese
    } else if (this.props.lang === 'en') {
      content = this.content.english
    }

    const news = this.state.news
    return (
      <div className='container mh-100'>
        <h1 className='size-1 text-center my-5'>{content.title}</h1>

        <Row>
          <Col sm='auto' className='mb-2 mb-sm-2'>
            <Button type='reset' variant={news.stick_to_top == 1 ? 'danger' : 'green'} style={{ cursor: 'initial' }}>
              {news.date}
            </Button>
          </Col>
          <Col sm='auto' className='my-auto'>
            <h1 className='size-3 m-0'>{this.props.lang === 'zh' ? news.title_zh : news.title_en}</h1>
          </Col>
        </Row>

        <hr className='my-4' />
        <div dangerouslySetInnerHTML={{ __html: this.props.lang === 'zh' ? news.content_zh : news.content_en }}></div>

        <div className='text-center my-5'>
          <Link to='/basic/news'>
            <Button variant='orange'>{content.back}</Button>
          </Link>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    lang: state.front.lang,
  }
}

export default connect(mapStateToProps)(NewsShow)
