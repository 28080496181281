import { Component } from 'react'
import CreatableSelect from 'react-select'
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import axios from 'axios'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import { Link } from 'react-router-dom'
import { fetchShowTimeSlot } from 'actions/basic/actions'

dayjs.extend(isBetween)

const ChineseWeek = ['星期一', '星期二', '星期三', '星期四', '星期五']

class ReservePage extends Component {
  constructor(props) {
    super(props)
    const course = this.props.location.state?.course
    const date = this.props.location.state?.date?.split(' ')[0].replaceAll('/', '-')

    this.state = {
      date: date || '',
      time_slot: course?.time_slot_id || '',
      teacher: course?.user_id || '',
      subject: '',
      selectValue: [],
      phone: '',
      email: '',
      scope: '',
      file: [],
      file_url: [],
      accompany: '',
      step: 1,
      schedule: [],
      isLoading: false,
    }

    this.content = {
      english: {
        title: 'Online Reservation',
        tips: 'Please select courses you want to take',
        date_label: 'Date',
        date_placeholder: 'Please choose a date',
        time_slot: 'Period',
        time_slot_placeholder: 'Please choose a time slot',
        tutor: 'Tutor',
        tutor_placeholder: 'Please choose a tutor',
        subject: 'Subject',
        subject_placeholder: 'Please choose a subject',
        subject_tip: 'Multiple subjects is allowed',
        submit: 'Submit',
        tips2: 'Please fill in your basic information',
        phone: 'Phone Number',
        phone_placeholder: 'Please enter your phone number',
        email: 'E-mail',
        email_placeholder: 'Please enter your E-mail',
        scope: 'Specific parts of the subject',
        scope_placeholder: 'Please enter the specific parts you want to ask of the subject',
        file: "Questions' File",
        file_label: 'Add a new file',
        accompany: 'Accompanying Classmate',
        one: 'One',
        no: 'None',
        previous: 'Back',
        finish: 'You have completed the online reservation!',
        loading: 'Please wait',
        record: 'Search for Reservation Records',
        file_restrict: 'File must be smaller than 10MB, and the maximum number of files is 5',
        file_size_restict_alert: 'File must be smaller than 10MB',
        file_cnt_restict_alert: 'The maximum number of files is 5',
        disclaimer:
          'Please rest assured that the above information is for emergency contact only, no information will be leaked.',
        disclaimer2:
          'Our tutors have the right to refuse questions beyond the curriculum or deemed inappropriate. Please respect and understand this to foster a positive learning environment',
      },
      chinese: {
        title: '線上預約',
        tips: '請點選您想要預約的課程',
        date_label: '日期',
        date_placeholder: '請選擇日期',
        time_slot: '節次',
        time_slot_placeholder: '請選擇節次',
        tutor: '小老師',
        tutor_placeholder: '請選擇小老師',
        subject: '科目',
        subject_placeholder: '請選擇科目',
        subject_tip: '可以選擇多個科目',
        submit: '送出',
        tips2: '請填寫預約基本資料',
        phone: '手機號碼',
        phone_placeholder: '請輸入您的手機號碼',
        email: '電子信箱',
        email_placeholder: '請輸入您的電子信箱',
        scope: '科目範圍',
        scope_placeholder: '請輸入欲詢問的科目範圍',
        file: '題目檔案',
        file_label: '新增檔案',
        accompany: '隨同同學',
        one: '一位',
        no: '無',
        previous: '上一步',
        finish: '您已完成線上預約手續！',
        loading: '請稍等',
        record: '查詢預約紀錄',
        file_restrict: '檔案大小不得超過10MB，至多上傳五個檔案',
        file_size_restict_alert: '檔案大小不得超過10M',
        file_cnt_restict_alert: '至多上傳五個檔案',
        disclaimer: '以上資料僅供緊急聯絡時使用，不會外洩個資，敬請放心。',
        disclaimer2:
          '小老師保有拒絕回答超出課程範圍、不適當或不符專業界限之問題的權利，請尊重並理解，以營造正面學習環境。',
      },
    }
  }
  fetchSchedule = (week) => {
    axios
      .get(`/en/api/getTimeTableSchedule/${week}`, { params: { token: this.props.login.token } })
      .then((res) => {
        // console.log(res.data.result.schedule)
        const sche = res.data.result.schedule
        let new_a = []
        const start_day = dayjs()
        const end_day = dayjs().add(14, 'day')
        for (let [key, value] of Object.entries(sche)) {
          const date = dayjs(key)
          const unavaliable_time_slot = value.course.filter((c) => c.studentBookBefore).map((c) => c.time_slot_id)
          const filteredCourse = value.course.filter(
            (c) => !(c.on_leave && !c.on_replacement) && !c.studentBookBefore && c.booked_slot < 2
          )
          filteredCourse.forEach((c) => {
            if (c.on_replacement) c.tutor_detail = c.tutor_replacement
          })

          if (filteredCourse.length > 0 && date.isBetween(start_day, end_day, 'day', '(]')) {
            const available_time_slot = []
            for (const c of filteredCourse) {
              const newTimeSlot = parseInt(c.time_slot_id)
              if (!available_time_slot.includes(newTimeSlot) && !unavaliable_time_slot.includes(c.time_slot_id))
                available_time_slot.push(newTimeSlot)
            }
            new_a.push({
              date: date,
              date_str: date.format('YYYY/MM/DD') + ' (' + ChineseWeek[date.get('day') - 1] + ')',
              date_str_en: date.format('YYYY/MM/DD ddd'),
              available_time_slot,
              course: filteredCourse,
            })
          }
        }
        this.setState((s) => ({
          schedule: s.schedule.concat(new_a).sort((a, b) => a.date - b.date),
        }))
      })
      .catch((err) => {
        // console.log(err)
      })
  }
  componentDidMount() {
    this.props.dispatch(fetchShowTimeSlot())
    for (const w of [1, 2, 3]) {
      this.fetchSchedule(w)
    }
    const psnlData = this.props.login.data
    if (psnlData.tel) {
      this.setState({ phone: psnlData.tel })
    }
    if (psnlData.email2 || psnlData.email) {
      this.setState({ email: psnlData.email2 || psnlData.email })
    }
  }
  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value })
  }
  clearSelect = () => {
    this.setState({ selectValue: [], subject: '' })
  }
  handleCheckbox = (e) => {
    this.setState({ accompany: e.target.id })
  }
  handleFileInputChange = (e) => {
    let newFile = this.state.file
    let lang = this.props.lang
    let cnt_restict_alert
    let size_restict_alert
    if (lang === 'zh') {
      cnt_restict_alert = this.content.chinese.file_cnt_restict_alert
      size_restict_alert = this.content.chinese.file_size_restict_alert
    } else if (lang === 'en') {
      cnt_restict_alert = this.content.english.file_cnt_restict_alert
      size_restict_alert = this.content.english.file_size_restict_alert
    }
    if (e.target.files.length + newFile.length > 5) {
      return alert(cnt_restict_alert)
    } else {
      for (const f of e.target.files) {
        if (f.size > 10 * 1024 * 1024) {
          return alert(size_restict_alert)
        } else newFile.push(f)
      }
      this.setState({ file: newFile })
    }
  }
  showSelectedFile = (file) => {
    return (
      <div className='selected-file d-flex justify-content-between p-3 mb-2'>
        <p className='m-0 w-100 text-truncate'>{file.name}</p>
        <i type='button' onClick={() => this.deleteSelectedFile(file.name)} class='fas fa-trash'></i>
      </div>
    )
  }
  deleteSelectedFile = (fileName) => {
    const newFile = this.state.file.filter((e) => e.name !== fileName)
    if (newFile.length === 0) document.getElementById('file').value = ''
    this.setState({ file: newFile })
  }
  nextStep = (step) => {
    this.setState({ step: step + 1 })
  }
  handleSubmit = async () => {
    for (const f of this.state.file) {
      let formdata = new FormData()
      formdata.append('file1', f)
      try {
        const res = await axios.post('/en/api/upload_file/' + this.props.login.token, formdata)
        if (res.data.status === 'OK') {
          let new_f = this.state.file_url
          new_f.push(res.data.result.url)
          this.setState({ file_url: new_f })
        } else {
          // console.log(res.data)
          alert(res.data.result)
        }
      } catch (err) {
        // console.log(err)
        alert('上傳檔案失敗')
      }
    }

    const body = {
      mode: 'Add',
      token: this.props.login.token,
      date: this.state.date,
      tutor_id: parseInt(this.state.teacher),
      tel: this.state.phone,
      email: this.state.email,
      subject: this.state.subject,
      scope: this.state.scope,
      accompany: parseInt(this.state.accompany),
      attachment: this.state.file_url,
      time_slot_id: parseInt(this.state.time_slot),
    }
    // console.log(body)
    this.setState({ isLoading: true })
    axios
      .post('/en/api/studentReservationSubmit', body)
      .then((res) => {
        // console.log(res.data)
        if (res.data.status !== 'ERROR') {
          this.nextStep(2)
        } else {
          alert(res.data.result)
        }
      })
      .catch((err) => {
        // console.log(err)
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }
  render() {
    let lang = this.props.lang
    let content
    if (lang === 'zh') {
      content = this.content.chinese
    } else if (lang === 'en') {
      content = this.content.english
    }

    const customStyles = {
      multiValue: (provided, state) => ({
        ...provided,
        backgroundColor: '#06386B',
        color: 'white',
      }),
      multiValueLabel: (provided, state) => ({
        color: 'white',
        paddingLeft: '3px',
      }),
    }

    const handleSelect = (obj) => {
      let subject = ''
      if (obj) {
        obj.map((e) => (subject += e.value + ','))
      }
      subject = subject.replace(/,+$/, '')
      this.setState({ subject: subject })
      this.setState({ selectValue: obj })
    }

    let schedule = this.state.schedule
    let selected_date = schedule.find((s) => s.date.format('YYYY-MM-DD') === this.state.date)
    let selected_time_slot = selected_date
      ? selected_date.course.filter((c) => c.time_slot_id == this.state.time_slot)
      : null
    let selected_teacher = selected_time_slot
      ? selected_time_slot.find((c) => (c.tutor_detail.user_id || c.user_id) == this.state.teacher)
      : null
    let options = []
    if (selected_teacher) {
      if (lang === 'zh') {
        options = selected_teacher.tutor_detail.tutor_subjects.split(',').map((s) => ({
          value: s,
          label: s,
        }))
      } else {
        options = selected_teacher.tutor_detail.tutor_subjects_en.split(',').map((s) => ({
          value: s,
          label: s,
        }))
      }
    }

    return (
      <div className='container mb-5'>
        <div className='absolute-bg'></div>
        <h1 className='size-1 my-5 text-left text-sm-center'>{content.title}</h1>

        <div className={'p-2 p-sm-5 bg-white reserve-box size-5' + (this.state.step !== 1 ? ' d-none' : '')}>
          <h2 className='size-4 text-center mb-2 mb-sm-5'>{content.tips}</h2>

          <Form.Group as={Row} controlId='date'>
            <Form.Label column sm='2'>
              {content.date_label}
            </Form.Label>
            <Col sm='10'>
              <Form.Control
                as='select'
                onChange={(e) => {
                  this.handleChange(e)
                  this.clearSelect()
                }}
                value={this.state.date}
              >
                <option disabled selected hidden value=''>
                  {content.date_placeholder}
                </option>
                {schedule.map((s) => (
                  <option value={s.date.format('YYYY-MM-DD')}>{lang === 'zh' ? s.date_str : s.date_str_en}</option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId='time_slot'>
            <Form.Label column sm='2'>
              {content.time_slot}
            </Form.Label>
            <Col sm='10'>
              <Form.Control
                as='select'
                onChange={(e) => {
                  this.handleChange(e)
                  this.clearSelect()
                }}
                value={this.state.time_slot}
              >
                <option disabled selected hidden value=''>
                  {content.time_slot_placeholder}
                </option>
                {selected_date ? (
                  selected_date.available_time_slot.map((t_id) => {
                    let this_slot = this.props.timeSlot.find((t) => t.id == t_id)
                    if (this_slot) {
                      return (
                        <option value={t_id}>
                          {this_slot.time + (lang === 'zh' ? ' 第' + this_slot.code + '節' : ' No.' + this_slot.code)}
                        </option>
                      )
                    }
                  })
                ) : (
                  <div></div>
                )}
              </Form.Control>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId='teacher'>
            <Form.Label column sm='2'>
              {content.tutor}
            </Form.Label>
            <Col sm='10'>
              <Form.Control
                as='select'
                onChange={(e) => {
                  this.handleChange(e)
                  this.clearSelect()
                }}
                value={this.state.teacher}
              >
                <option disabled selected hidden value=''>
                  {content.tutor_placeholder}
                </option>
                {selected_time_slot ? (
                  selected_time_slot.map((c) => (
                    <option value={c.tutor_detail.user_id || c.user_id}>
                      {lang === 'zh' ? c.tutor_detail.name : c.tutor_detail.name_en}
                    </option>
                  ))
                ) : (
                  <div></div>
                )}
              </Form.Control>
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm='2'>
              {content.subject}
            </Form.Label>
            <Col sm='10'>
              <CreatableSelect
                isMulti
                closeMenuOnSelect={false}
                value={this.state.selectValue}
                options={options}
                styles={customStyles}
                classNamePrefix='courses'
                placeholder={content.subject_placeholder}
                onChange={handleSelect}
              />
              <Form.Text className='text-muted text-left'>{content.subject_tip}</Form.Text>
            </Col>
          </Form.Group>

          <div className='text-center'>
            <button
              className='submit-btn text-white'
              onClick={() => this.nextStep(1)}
              disabled={!(this.state.date && this.state.time_slot && this.state.teacher && this.state.subject)}
            >
              {content.submit}
            </button>
          </div>
        </div>

        <div className={'p-2 p-sm-5 bg-white reserve-box size-5' + (this.state.step !== 2 ? ' d-none' : '')}>
          <h2 className='size-4 text-center mb-2 mb-sm-5'>{content.tips2}</h2>

          <Form.Group as={Row} controlId='phone'>
            <Form.Label column sm='2'>
              {content.phone}
              <i class='fas fa-star-of-life red-star ml-1 my-auto'></i>
            </Form.Label>
            <Col sm='10'>
              <Form.Control
                onChange={this.handleChange}
                required
                type='text'
                placeholder={content.phone_placeholder}
                value={this.state.phone}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId='email'>
            <Form.Label column sm='2'>
              {content.email}
              <i class='fas fa-star-of-life red-star ml-1 my-auto'></i>
            </Form.Label>
            <Col sm='10'>
              <Form.Control
                onChange={this.handleChange}
                required
                type='email'
                placeholder={content.email_placeholder}
                value={this.state.email}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId='scope'>
            <Form.Label column sm='2'>
              {content.scope}
              <i class='fas fa-star-of-life red-star ml-1 my-auto'></i>
            </Form.Label>
            <Col sm='10'>
              <Form.Control onChange={this.handleChange} required type='text' placeholder={content.scope_placeholder} />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId='file'>
            <Form.Label column sm='2'>
              {content.file}
            </Form.Label>
            <Col sm='10' className='text-center'>
              {this.state.file.map(this.showSelectedFile)}
              <Form.File
                label={content.file_label}
                onChange={this.handleFileInputChange}
                disabled={this.state.file.length > 5}
                multiple
                custom
                style={{ minHeight: 'auto' }}
              />
              <Form.Text className='text-muted text-left'>{content.file_restrict}</Form.Text>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId='radio'>
            <Form.Label column sm='2'>
              {content.accompany}
              <i class='fas fa-star-of-life red-star ml-1'></i>
            </Form.Label>
            <Col sm='10'>
              <div class='custom-control custom-radio custom-control-inline'>
                <input
                  onChange={this.handleCheckbox}
                  type='radio'
                  id='1'
                  name='accompany'
                  class='custom-control-input'
                />
                <label class='custom-control-label' for='1'>
                  {content.one}
                </label>
              </div>
              <div class='custom-control custom-radio custom-control-inline'>
                <input
                  onChange={this.handleCheckbox}
                  type='radio'
                  id='0'
                  name='accompany'
                  class='custom-control-input'
                />
                <label class='custom-control-label' for='0'>
                  {content.no}
                </label>
              </div>
            </Col>
          </Form.Group>

          <h4 className='size-6 text-center mb-2 mb-sm-4 text-muted'>{content.disclaimer}</h4>
          <h4 className='size-6 text-center mb-2 mb-sm-4 text-muted'>{content.disclaimer2}</h4>

          <Row>
            <Col className='text-right' xs={6}>
              <button className='submit-btn text-white' onClick={() => this.nextStep(0)}>
                {content.previous}
              </button>
            </Col>
            <Col className='text-left' xs={6}>
              <button
                className='submit-btn text-white'
                onClick={this.handleSubmit}
                disabled={
                  !(this.state.phone && this.state.email && this.state.scope && this.state.accompany) ||
                  this.state.isLoading ||
                  !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email)
                }
              >
                {this.state.isLoading ? content.loading : content.submit}
              </button>
            </Col>
          </Row>
        </div>

        <div
          className={'p-2 p-sm-5 bg-white reserve-box size-5 text-center' + (this.state.step !== 3 ? ' d-none' : '')}
        >
          <i class='fas fa-check reserve-success-icon'></i>

          <p className='size-4 my-3'>{content.finish}</p>

          <Link to='/basic/record/student'>
            <Button variant='orange'>{content.record}</Button>
          </Link>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
    timeSlot: state.front.timeSlot,
    lang: state.front.lang,
  }
}

export default connect(mapStateToProps)(ReservePage)
