import { useSelector } from 'react-redux'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Link } from 'react-router-dom'
import onlineReservePic from 'img/logos/weblogo/online_reserve.svg'
import consultPic from 'img/logos/weblogo/consult.svg'
import feedbackPic from 'img/logos/weblogo/feedback.svg'

function Service() {
  const lang = useSelector((s) => s.front.lang)
  let content = {
    english: {
      title: 'Service Flow',
      button: 'Learn More',
      step1: 'Online Reservation',
      step2: 'In-Person Counselling',
      step3: 'Give Feedbacks',
    },
    chinese: {
      title: '服務流程',
      button: '了解更多',
      step1: '線上預約',
      step2: '到場諮詢',
      step3: '填寫回饋',
    },
  }
  if (lang === 'zh') {
    content = content.chinese
  } else if (lang === 'en') {
    content = content.english
  }

  const cards = (text, img_src) => {
    return (
      <div className='bg-white text-center service-cards2 rounded pt-2 pt-sm-3'>
        <div className='service-content2 text-dark'>
          <h2 className='service-card-text'>{text}</h2>
          <img src={img_src} alt='logo' className='w-75 h-auto' />
        </div>
      </div>
    )
  }

  const arrow = (
    <Col xs='auto' className='px-auto px-sm-4'>
      <i class='fas fa-caret-right text-green size-2 vertical-center'></i>
    </Col>
  )

  return (
    <>
      <h3 className='size-2 text-blue text-center py-3'>{content.title}</h3>
      <Row>
        <Col xs={true} className='px-0'>
          {cards(content.step1, onlineReservePic)}
        </Col>
        {arrow}
        <Col xs={true} className='px-0'>
          {cards(content.step2, consultPic)}
        </Col>
        {arrow}
        <Col xs={true} className='px-0'>
          {cards(content.step3, feedbackPic)}
        </Col>
      </Row>

      <div className='text-center mt-4'>
        <Link to='/basic/service'>
          <Button variant='orange'>{content.button}</Button>
        </Link>
      </div>
    </>
  )
}

export default Service
