import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import axios from 'axios'

function DownloadPage() {
  const lang = useSelector((s) => s.front.lang)
  const { type } = useParams()
  const [data, setData] = useState([])

  useEffect(() => {
    axios
      .get(`/en/spcl/api/getFrontdocumentList/${type}`)
      .then((res) => {
        if (res.data.status === 'OK') {
          // console.log(res.data.result.detail)
          setData(res.data.result)
        } else {
          // console.log(res.data)
          alert(res.data.result)
        }
      })
      .catch((err) => {
        // console.log(err)
        alert('發生錯誤')
      })
  },[type])

  return (
    <div className='container mb-5'>
      <h1 className='size-1 my-5 text-left text-sm-center'>{lang === 'zh' ?( type === "regulation"?'重要法規':'常用表單') +' 檔案下載' : ( type === "regulation"?'Regulation':'Form') +' Download'}</h1>

      {data.map((e) => (
          <a href={e.url} target='_blank' className='download-area mb-3 d-block p-2 p-sm-3' type='button'>
          <div className='blue-circle mr-3 d-inline-block'>
            <i class='fas fa-file-alt'></i>
          </div>
          <span>{lang === 'zh' ?e.title:e.title_en}</span>
        </a>
      ))}
    </div>
  )
}

export default DownloadPage

const files_ori = [
  {
    url: 'https://drive.google.com/file/d/17LBKWBbJWlD4OqdP5c6IBBKtx_h6eXuM/view?usp=sharing',
    text: '個案學習諮詢補助計畫--實施要點',
    textEn: 'Implementation Guidelines for Individual Counseling Subsidy Program',
  },
  {
    url: 'https://drive.google.com/file/d/1XlsItA4Ox9yJ4qXrrniZwZ3sXJFot26-/view?usp=sharing',
    text: '個案學習諮詢補助計畫--行政作業須知',
    textEn: 'Supplemental Instruction for Administrative Work of Individual Counseling Subsidy Program',
  },
  {
    url: 'https://drive.google.com/file/d/1uqV9FPGWwWHFnc6sOM4ygNfV5_4UpJmK/view?usp=sharing',
    text: '教務處深耕計畫短期人員聘僱申請書(含聲明書、證件影本黏貼表)',
    textEn: 'Employment Application',
  },
  {
    url: 'https://drive.google.com/file/d/1iPFHc8cWRuTHiw-fdG_XARbyT8HBoEQS/view?usp=sharing',
    text: '保險費經費分攤同意書',
    textEn: 'Insurance Premium Sharing Scheme',
  },
  {
    url: 'https://drive.google.com/file/d/1j4DuUk07-zg6yHuJeOc3oP_nDLXQdazG/view?usp=sharing',
    text: '薪資領據',
    textEn: 'Salary Receipt',
  },
  {
    url: 'https://drive.google.com/file/d/1wD5jWr1kh0v666U6FsULLqanNOLJEIXs/view?usp=sharing',
    text: '薪資領據(範本)',
    textEn: 'Salary Receipt (example)',
  },
  {
    url: 'https://drive.google.com/file/d/1IeUhwCODng0W-AmQfbAq4ww5vib-mzBK/view?usp=sharing',
    text: '勞(健)保退保申請書',
    textEn: 'Application for Termination of Labor/Health Insurance',
  },
  {
    url: 'https://drive.google.com/file/d/1F0jwKbQfBLoP4SlbTiupIOuHrFm36oOy/view?usp=sharing',
    text: '勞(健)保及勞退金異動申請書',
    textEn: 'Application for Termination of Labor/Health Insurance Premium Adjustment',
  },
  {
    url: 'https://drive.google.com/file/d/11xOrAw4Q-_HUNRaT9cNIchLMuyCdgTtN/view?usp=sharing',
    text: '表單填寫教學',
    textEn: 'Form Filling Guideline',
  },
  {
    url: 'https://drive.google.com/file/d/1K1FbYlVKhBpFm4pvW0yWgsJggp4etRsY/view?usp=sharing',
    text: '勞保、健保及勞退金每月個人與單位負擔費用對照表',
    textEn: 'Insurance Premium Conversion Table',
  },
  {
    url: 'https://drive.google.com/file/d/1P4gi-autwSd92lMQdEW95G1uWYtj9isa/view?usp=sharing',
    text: '個案學習諮詢相關檔案打包下載',
    textEn: 'Download All',
  },
]
