import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link, useRouteMatch } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import axios from 'axios'

function ReserveRecordPage() {
  const token = useSelector((s) => s.login.token)
  const { path } = useRouteMatch()
  const [data, setData] = useState([])

  useEffect(() => {
    let url = '/en/spcl/api/getLecturerApplicationList/' + token + '/9999999/1'
    axios
      .get(url)
      .then((res) => {
        if (res.data.status === 'OK') {
          const new_data = res.data.result.applicationList
          // console.log(new_data)
          setData(new_data)
        } else {
          // console.log(res.data)
          alert(res.data.result)
        }
      })
      .catch((err) => {
        // console.log(err)
        alert('發生錯誤')
      })
  }, [token])

  return (
    <div className='container mb-5' id='record'>
      <div className='absolute-bg'></div>
      <h1 className='size-1 my-5 text-left text-sm-center'>申請紀錄</h1>

      <div className='mobile-table d-block d-lg-none size-6'>
        {data.length <= 0 ? '目前尚無申請記錄' : null}
        {data.map((d) => (
          <div className='mobile-table-item p-3'>
            <div className='d-flex justify-content-between'>
              <span className='text-bold'>申請日期</span>
              <span className='text-grey'>{d.date}</span>
            </div>
            <div className='d-flex justify-content-between'>
              <span className='text-bold'>學生姓名</span>
              <span className='text-grey'>{d.student_detail.name}</span>
            </div>
            <div className='d-flex justify-content-between'>
              <span className='text-bold'>申請科目</span>
              <span className='text-grey'>{d.subject}</span>
            </div>
            <div className='d-flex justify-content-between'>
              <span className='text-bold'>查看詳情</span>
              <span className='text-grey'>
                <Link to={`${path}/${d.application_id}`}>
                  <Button size='sm' variant='green' className='size-6'>
                    查看詳情
                  </Button>
                </Link>
              </span>
            </div>
            <div className='d-flex justify-content-between'>
              <span className='text-bold'>狀態</span>
              <span className='text-grey'>{d.status_text}</span>
            </div>
          </div>
        ))}
      </div>

      <Table striped borderless variant='green' className='text-grey text-center d-none d-lg-table'>
        <thead>
          <tr>
            <th scope='col'>申請日期</th>
            <th scope='col'>學生姓名</th>
            <th scope='col'>申請科目</th>
            <th scope='col'>查看詳情</th>
            <th scope='col'>狀態</th>
          </tr>
        </thead>
        <tbody className='reserve-record-tobdy'>
          {data.map((d) => (
            <tr>
              <td>{d.date}</td>
              <td>{d.student_detail.name}</td>
              <td>{d.subject}</td>
              <td>
                <Link to={`${path}/${d.application_id}`}>
                  <Button variant='green'>查看詳情</Button>
                </Link>
              </td>
              <td>{d.status_text}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

export default ReserveRecordPage
