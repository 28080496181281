import 'front.css'
import MyErrorBoundary from 'error-boundary.js'
import MainPage from './containers/main_page/main_page'
import { Switch, Route } from 'react-router-dom'
import Basic from 'basic.js'
import Special from 'special.js'
import Individual from 'individual.js'

function App() {
  return (
    <Switch>
      <MyErrorBoundary>
        <Route exact path='/' component={MainPage} />
        <Route path={['/basic', '/basic_db']} render={Basic} />
        <Route path={['/special', '/special_db']} render={Special} />
        <Route path={['/individual', '/individual_db']} render={Individual} />
      </MyErrorBoundary>
    </Switch>
  )
}

export default App
