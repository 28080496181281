import { useSelector } from 'react-redux'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Link } from 'react-router-dom'
import onlineReservePic from 'img/logos/weblogo/online_reserve.svg'
import consultPic from 'img/logos/weblogo/consult.svg'
import feedbackPic from 'img/logos/weblogo/feedback.svg'

function Service() {
  const lang = useSelector((s) => s.front.lang)
  let content = {
    english: {
      title: 'Service Flow',
      button: 'Learn More',
      step1: 'Online Reservation',
      step1_text:
        'The service should be applied by advisors. After logging in to this website, advisors can start the online application.',
      step2: 'Counseling Service',
      step2_text:
        'After the application is accepted, tutors recruited by advisors can start negotiating for the time and place to hold tutoring sessions.',
      step3: 'Fill In the Records',
      step3_text:
        'After tutoring sessions, both tutors and students should log in to the website and fill in the tutoring records and feedbacks.',
    },
    chinese: {
      title: '服務流程',
      button: '了解更多',
      step1: '線上申請',
      step1_text: '由各系導師作為申請人，於本站登入後進行線上申請',
      step2: '輔導諮詢',
      step2_text: '申請通過後，導師徵選之小老師即可與學生協調授課時間、地點',
      step3: '填寫紀錄',
      step3_text: '授課結束，小老師與學生須於本站登入填寫授課紀錄及回饋',
    },
  }
  if (lang === 'zh') {
    content = content.chinese
  } else if (lang === 'en') {
    content = content.english
  }

  const cards = (title, text, img_src, rev = false) => {
    return (
      <Row className='text-center'>
        <Col sm={{ span: 6, order: rev ? 'last' : 'first' }}>
          <img src={img_src} className='service-img w-50' alt='online reserve' />
        </Col>
        <Col sm={{ span: 6, order: rev ? 'first' : 'last' }} className='my-auto pt-3 pt-0'>
          <h2 className='text-dark size-3'>{title}</h2>
          <p className='size-4'>{text}</p>
        </Col>
      </Row>
    )
  }

  return (
    <div>
      <h3 className='size-2 text-blue text-center py-3'>{content.title}</h3>

      <div className='mb-3'>{cards(content.step1, content.step1_text, onlineReservePic)}</div>
      <div className='mb-3'>{cards(content.step2, content.step2_text, consultPic, true)}</div>
      <div className='mb-3'>{cards(content.step3, content.step3_text, feedbackPic)}</div>

      <div className='text-center mt-4'>
        <Link to='/individual/service'>
          <Button variant='orange'>{content.button}</Button>
        </Link>
      </div>
    </div>
  )
}

export default Service
