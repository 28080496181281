import { Component } from 'react'
import { connect } from 'react-redux'
import NewsList from 'containers/basic_page/main/news_list.js'
import Button from 'react-bootstrap/Button'
import FrontPagination from 'components/Pagination'
import axios from 'axios'

class NewsPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      perPage: 5,
      currentPage: 0,
      pageCount: 0,
      query: '',
    }

    this.content = {
      english: {
        title: 'News',
        query_placeholder: 'Enter Keywords',
        search: 'Search',
      },
      chinese: {
        title: '最新消息',
        query_placeholder: '請輸入關鍵字',
        search: '搜尋',
      },
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData = (query) => {
    axios
      .get(`/en/api/getArticleFrontList/${this.state.perPage}/${this.state.currentPage + 1}`, {
        params: {
          'filter[content]': query,
        },
      })
      .then((res) => {
        if (res.data.status === 'OK') {
          const result = res.data.result
          // console.log(result.article)
          this.setState({
            data: result.article,
            pageCount: Math.ceil(parseInt(result.total_count) / this.state.perPage),
          })
        } else {
          alert(res.data.result)
        }
      })
      .catch((e) => {
        // console.log(e)
        alert(this.props.lang === 'zh' ? '獲取最新消失敗' : 'Fetch news failed!')
      })
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected

    this.setState(
      {
        currentPage: selectedPage,
      },
      () => {
        this.fetchData(this.state.query)
      }
    )
  }

  search = (e) => {
    e.preventDefault()
    this.setState(
      {
        currentPage: 0,
      },
      () => {
        this.fetchData(this.state.query)
      }
    )
  }

  render() {
    let content
    if (this.props.lang === 'zh') {
      content = this.content.chinese
    } else if (this.props.lang === 'en') {
      content = this.content.english
    }

    return (
      <div className='container'>
        <h1 className='size-1 my-5 text-left text-sm-center'>{content.title}</h1>

        <div class='input-group mb-4 text-center'>
          <input
            type='text'
            class='form-control'
            placeholder={content.query_placeholder}
            value={this.state.query}
            onChange={(e) => this.setState({ query: e.target.value })}
            onKeyPress={(e) => {
              if (e.key === 'Enter') this.search(e)
            }}
          />
          <Button variant='primary' className='text-light ml-3 news-btn px-4' onClick={this.search}>
            {content.search}
          </Button>
        </div>

        <NewsList news={this.state.data} />

        <FrontPagination pageCount={this.state.pageCount} onPageChange={this.handlePageClick} />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    lang: state.front.lang,
  }
}

export default connect(mapStateToProps)(NewsPage)
