import { Component } from 'react'
import { connect } from 'react-redux'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Badge from 'react-bootstrap/Badge'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import axios from 'axios'

class ProfilePage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showEdit: false,
      profile: {},
      tmp_profile: {},
    }

    this.content = {
      english: {
        title: 'Profile',
      },
      chinese: {
        title: '基本資訊',
      },
    }
  }
  fetchData = () => {
    axios
      .get('/en/api/tokenChecknValidation/' + this.props.token + '/tutor')
      .then((res) => {
        // console.log(res.data.result.result)
        if (res.data.status === 'OK') {
          this.setState({
            profile: res.data.result.result,
            tmp_profile: res.data.result.result,
          })
        } else {
          alert(res.data.result)
        }
      })
      .catch((err) => {
        // console.log(err)
      })
  }
  componentDidMount() {
    if (this.props.token) this.fetchData()
  }
  componentDidUpdate(prevProps) {
    if (prevProps.token != this.props.token) this.fetchData()
  }
  handleClose = () => {
    this.setState({ showEdit: false })
  }
  handleSave = () => {
    const tmp_profile = this.state.tmp_profile
    let body = {
      token: this.props.token,
      name: this.state.profile.name,
      name_en: tmp_profile.name_en,
      email: tmp_profile.email,
      email2: tmp_profile.email2,
      avatar: tmp_profile.avatar,
      tutor_speciality_zh: tmp_profile.tutor_speciality_zh,
      tutor_speciality_en: tmp_profile.tutor_speciality_en,
    }

    axios
      .post('/en/api/tutorUpdateProfile', body)
      .then((res) => {
        // console.log(res)
        this.setState({ showEdit: false, profile: tmp_profile })
      })
      .catch((e) => {
        // console.log(e)
        alert('ERROR')
      })
  }
  handleShow = () => {
    this.setState({ showEdit: true })
  }
  handleChange = (e) => {
    let new_profile = this.state.profile
    new_profile[e.target.name] = e.target.value
    this.setState({ tmp_profile: new_profile })
  }
  render() {
    let lang = this.props.lang
    let content
    if (lang === 'zh') {
      content = this.content.chinese
    } else if (lang === 'en') {
      content = this.content.english
    }

    let profile = this.state.profile
    let tutor_subjects = profile.tutor_subjects || ''
    return (
      <Container className='mb-5'>
        <div className='absolute-bg'></div>
        <h1 className='size-1 my-5 text-left text-sm-center'>{content.title}</h1>

        <Row className='page_body' noGutters={true}>
          <Col sm={4} className='bg-green text-center left-col'>
            <div className='circle-img mx-auto my-3'>
              <img
                src={
                  profile.avatar ||
                  'https://images.pexels.com/photos/3777549/pexels-photo-3777549.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
                }
                class='card-img-top rounded-circle'
              />
            </div>

            <p className='size-4 text-light m-1'>{profile.name}</p>
            <p className='size-6 text-light m-1 mb-2'>{profile.dept}</p>
            {tutor_subjects.split(',').map((c) => (
              <Badge variant='light' className='p-2 mb-3 mr-1 text-green'>
                {c}
              </Badge>
            ))}
          </Col>
          <Col sm={8} className='p-3'>
            <p className='text-green mb-2'>英文姓名</p>
            <p className={this.state.showEdit ? 'd-none' : ''}>{profile.name_en}</p>
            <Form.Control
              className={this.state.showEdit ? 'mb-3' : 'd-none'}
              onChange={this.handleChange}
              value={profile.name_en}
              name='name_en'
              type='text'
              placeholder='請輸入您的英文名字'
            />

            <p className='text-green mb-2'>學校信箱</p>
            <p className={this.state.showEdit ? 'd-none' : ''}>{profile.email}</p>
            <Form.Control
              className={this.state.showEdit ? 'mb-3' : 'd-none'}
              onChange={this.handleChange}
              value={profile.email}
              name='email'
              type='text'
              placeholder='請輸入您的學校信箱'
            />

            <p className='text-green mb-2'>其他信箱</p>
            <p className={this.state.showEdit ? 'd-none' : ''}>{profile.email2}</p>
            <Form.Control
              className={this.state.showEdit ? 'mb-3' : 'd-none'}
              onChange={this.handleChange}
              value={profile.email2}
              name='email2'
              type='text'
              placeholder='請輸入您的其他信箱'
            />

            <p className='text-green mb-2'>自我介紹</p>
            <p className={this.state.showEdit ? 'd-none' : ''}>{profile.tutor_speciality_zh}</p>
            <Form.Control
              className={this.state.showEdit ? 'mb-3' : 'd-none'}
              onChange={this.handleChange}
              value={profile.tutor_speciality_zh}
              as='textarea'
              rows='5'
              name='tutor_speciality_zh'
              type='text'
              placeholder='請輸入您的自我介紹'
            />

            <p className='text-green mb-2'>自我介紹（英文）</p>
            <p className={this.state.showEdit ? 'd-none' : ''}>{profile.tutor_speciality_en}</p>
            <Form.Control
              className={this.state.showEdit ? 'mb-3' : 'd-none'}
              onChange={this.handleChange}
              value={profile.tutor_speciality_en}
              as='textarea'
              rows='5'
              name='tutor_speciality_en'
              type='text'
              placeholder='請輸入您的英文自我介紹'
            />

            <div className={this.state.showEdit ? 'text-right' : 'd-none'}>
              <Button onClick={this.handleSave} variant='orange'>
                儲存
              </Button>
            </div>
            <div className={this.state.showEdit ? 'd-none' : 'edit-circle-div'}>
              <div onClick={this.handleShow} className='bg-yellow edit-circle text-center'>
                <i class='fas fa-edit text-white'></i>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
    token: state.login.token,
    lang: state.front.lang,
  }
}

export default connect(mapStateToProps)(ProfilePage)
