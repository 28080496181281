import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Table from 'react-bootstrap/Table'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Button from 'react-bootstrap/Button'
import axios from 'axios'
import dayjs from 'dayjs'
import { fetchProfile } from 'actions/special/actions'

const BACKEND_API = process.env.REACT_APP_BACKEND_API

function TutorSalaryPage() {
  const dispatch = useDispatch()
  const token = useSelector((s) => s.login.token)
  const createdDate = useSelector((s) => s.login.data.created_date)
  const [data, setData] = useState([])
  const [total, setTotal] = useState({ hours: 0, salary: 0 })
  const [time, setTime] = useState(dayjs().format('YYYY-MM'))
  let dropdown_item = []
  const today = dayjs()
  const end_month = dayjs(createdDate)
  for (let d = today; d.isAfter(end_month, 'month'); d = d.subtract(1, 'month')) {
    dropdown_item.push(<Dropdown.Item eventKey={d.format('YYYY-MM')}>{d.format('YYYY-MM')}</Dropdown.Item>)
  }

  const fetchData = (year = today.format('YYYY'), month = today.format('MM')) => {
    axios
      .get(`/en/prof/api/getTutorSalarySummary/${year}/${month}/${token}/0`)
      .then((res) => {
        if (res.data.status === 'OK') {
          // console.log(res.data.result)
          setData(res.data.result.salary)
          setTotal(res.data.result.total)
        } else {
          // console.log(res.data.result)
          alert(res.data.result)
        }
      })
      .catch((e) => {
        // console.log(e)
        alert('發生錯誤')
      })
  }

  useEffect(() => {
    fetchData()
    dispatch(fetchProfile(token))
  }, [token])

  const handleSelect = (e) => {
    setTime(e)
    const [year, month] = e.split('-')
    fetchData(year, month)
  }

  return (
    <div className='container mb-5' id='record'>
      <div className='absolute-bg'></div>
      <h1 className='size-1 my-5 text-left text-sm-center'>薪資查詢</h1>

      <DropdownButton
        className='py-3 w-100 salary-dropdown'
        bsPrefix='db-dropdown'
        title={time}
        onSelect={handleSelect}
      >
        {dropdown_item}
      </DropdownButton>

      <div className='bg-white py-2 d-flex justify-content-around rounded mb-3'>
        <div style={{ lineHeight: '40px' }}>
          <span className='text-grey align-middle mr-1'>總時數</span>
          <span className='align-middle'>{total.hours}小時</span>
        </div>
        <div style={{ lineHeight: '40px' }}>
          <span className='text-grey mr-1'>薪資</span>
          <span>{total.salary}元</span>
        </div>
        <div>
          <Button
            style={{ maxWidth: '5rem' }}
            variant='primary'
            className='rounded'
            href={`${BACKEND_API}/en/prof/api/getTutorSalarySummary/${time.replace('-', '/')}/${token}/1`}
          >
            匯出
          </Button>
        </div>
      </div>

      <Table striped variant='green' className='text-grey text-center'>
        <thead>
          <tr>
            <th scope='col'>日期</th>
            <th scope='col'>起迄時間</th>
            <th scope='col'>時數</th>
          </tr>
        </thead>
        <tbody>
          {data.map((e) => (
            <tr>
              <td>{e.date}</td>
              <td>{e.times}</td>
              <td>{e.total_hour}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

export default TutorSalaryPage
