import { Component } from 'react'
import { connect } from 'react-redux'
import NewsList from 'containers/special_page/main/news_list.js'
import FrontPagination from 'components/Pagination'
import axios from 'axios'

class NewsPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      perPage: 5,
      currentPage: 0,
      pageCount: 0,
    }

    this.content = {
      english: {
        title: 'News',
      },
      chinese: {
        title: '最新消息',
      },
    }
  }
  componentDidMount() {
    this.fetchData()
  }
  fetchData = () => {
    axios
      .get('/en/prof/api/getArticleFrontList/' + this.state.perPage + '/' + (this.state.currentPage + 1))
      .then((res) => {
        if (res.data.status === 'OK') {
          const result = res.data.result
          // console.log(result.article)
          this.setState({
            data: result.article,
            pageCount: Math.ceil(parseInt(result.total_count) / this.state.perPage),
          })
        } else {
          alert(res.data.result)
        }
      })
      .catch((e) => {
        // console.log(e)
        alert('獲取最新消失敗')
      })
  }
  handlePageClick = (e) => {
    const selectedPage = e.selected

    this.setState(
      {
        currentPage: selectedPage,
      },
      () => {
        this.fetchData(this.state.query)
      }
    )
  }

  render() {
    let content
    if (this.props.lang === 'zh') {
      content = this.content.chinese
    } else if (this.props.lang === 'en') {
      content = this.content.english
    }

    return (
      <div className='container'>
        <h1 className='size-1 my-5 text-left text-sm-center'>{content.title}</h1>

        <NewsList news={this.state.data} />

        <FrontPagination pageCount={this.state.pageCount} onPageChange={this.handlePageClick} />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    lang: state.front.lang,
  }
}

export default connect(mapStateToProps)(NewsPage)
