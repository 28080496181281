import { useSelector } from 'react-redux'
import jumbotronPic from 'img/logos/weblogo/advanced_1.svg'

function ContactPage() {
  const lang = useSelector((s) => s.front.lang)
  let content = {
    english: {
      title: 'Contact Us',
      address: 'Contact Address',
      address_detail: 'No. 1, Sec. 4, Roosevelt Rd., Taipei 10617, Taiwan (R.O.C.)',
      phone: 'Contact Number',
      email: 'Contact E-mail',
    },
    chinese: {
      title: '聯絡我們',
      address: '聯絡地址',
      address_detail: '10617 臺北市大安區羅斯福路四段一號',
      phone: '聯絡電話',
      email: '聯絡信箱',
    },
  }
  if (lang === 'zh') {
    content = content.chinese
  } else if (lang === 'en') {
    content = content.english
  }

  return (
    <div className='container pb-5'>
      <h1 className='size-1 my-5 text-left text-sm-center'>{content.title}</h1>
      <div className='row'>
        <div className='col-12 col-sm-6 text-center text-sm-right mb-5 mb-sm-0'>
          <img src={jumbotronPic} className='basic-1 text-center' alt='logo' />
        </div>
        <div className='col-12 col-sm-6 mt-3 my-sm-auto pl-5'>
          <div className='smal-title'>{content.address}</div>
          <p className='size-3 m-0'>{content.address_detail}</p>
          <div className='smal-title mt-2 mt-sm-4'>{content.phone}</div>
          <a href='tel:02-33663367#557' className='size-3 text-blue'>
            02-33663367#557
          </a>
          <div className='smal-title mt-2 mt-sm-4'>{content.email}</div>
          <a href='mailto:vinateng@ntu.edu.tw' className='size-3 text-blue'>
            vinateng@ntu.edu.tw
          </a>
        </div>
      </div>
    </div>
  )
}

export default ContactPage
