import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useRouteMatch, Switch } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import Table from 'react-bootstrap/Table'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import FrontPagination from 'components/Pagination'
import PrivateRoute from 'private-route.js'
import { fetchDefaultTimeSlot } from 'actions/basic/actions'
import axios from 'axios'

const BACKEND_API = process.env.REACT_APP_BACKEND_API

function ReserveHistoryPage() {
  const lang = useSelector((s) => s.front.lang)
  let content = {
    english: {
      student_title: 'Tutoring Feedbacks',
      tutor_title: 'Records History',
    },
    chinese: {
      student_title: '輔導紀錄',
      tutor_title: '歷史紀錄',
    },
  }
  if (lang === 'zh') {
    content = content.chinese
  } else if (lang === 'en') {
    content = content.english
  }

  const role = useSelector((s) => s.login.role)
  const dispatch = useDispatch()
  let { path } = useRouteMatch()

  useEffect(() => {
    dispatch(fetchDefaultTimeSlot())
  }, [])

  return (
    <div className='container mb-5' id='record'>
      <div className='absolute-bg'></div>
      <h1 className='size-1 my-5 text-left text-sm-center'>
        {role === 'student' ? content.student_title : content.tutor_title}
      </h1>

      <Switch>
        <PrivateRoute exact path={path + '/student'} check_role='student' cate='basic' Component={StudentTable} />
        <PrivateRoute path={path + '/tutor'} check_role='tutor' cate='basic' Component={TutorTable} />
      </Switch>
    </div>
  )
}

export default ReserveHistoryPage

function TutorTable() {
  const lang = useSelector((s) => s.front.lang)
  const token = useSelector((s) => s.login.token)
  let content = {
    english: {
      all_semester: 'All Semesters',
      search: 'Search',
      search_text: "PPlease enter student's name or ID",
      date: 'Date',
      time: 'Perios',
      student: 'Student',
      student_id: 'ID',
      attendance: 'Attendance Status',
      detail: 'Record Details',
      feedback: 'Feedback Records',
      class: 'Tutoring Records',
      attendance_default: 'unfilled',
      attendance_yes: 'Attended',
      attendance_no: 'Absent',
      check_feedback: 'See Feedback',
      no_feedback: 'Not Yet Submitted',
      noData: 'There is no record',
    },
    chinese: {
      all_semester: '全部學期',
      search: '搜尋',
      search_text: '請輸入姓名或學號',
      date: '日期',
      time: '節數',
      student: '學生',
      student_id: '學號',
      attendance: '出席狀況',
      detail: '紀錄詳情',
      feedback: '回饋紀錄',
      class: '輔導紀錄',
      attendance_default: '未填寫',
      attendance_yes: '已出席',
      attendance_no: '未出席',
      check_feedback: '查看回饋',
      no_feedback: '未填寫',
      noData: '目前尚無申請記錄',
    },
  }
  if (lang === 'zh') {
    content = content.chinese
  } else if (lang === 'en') {
    content = content.english
  }

  const [data, setData] = useState([])
  const [semesterlist, setSemesterlist] = useState([])
  const [selected_semester, setSelected_semester] = useState({
    name: content.all_semester,
    value: 'ALL',
  })
  const perPage = 5
  const [currentPage, setCurrentPage] = useState(0)
  const [pageCount, setPageCount] = useState(0)
  const [query, setQuery] = useState('')
  const time_slot = useSelector((s) => s.front.defaultTimeSlot)

  const fetchData = () => {
    axios
      .get(`/en/api/getTutorReservationHistoryList/${token}/${perPage}/${currentPage + 1}`, {
        params: {
          'filter[student_info]': query,
          'filter[semester]': selected_semester.value,
        },
      })
      .then((res) => {
        if (res.data.status === 'OK') {
          const new_result = res.data.result
          // console.log(new_result.dataList)
          const new_data = new_result.dataList.filter((r) => r.cancel_status != 1)
          setData(new_data)
          const new_page_count = Math.ceil(parseInt(new_result.inlineCount) / perPage)
          setPageCount(new_page_count)
        } else {
          // console.log(res.data.result)
          alert('獲取資料失敗')
        }
      })
      .catch((e) => {
        // console.log(e)
        alert('獲取資料失敗')
      })
  }

  useEffect(() => {
    axios
      .get('/en/api/getSemesterFrontList')
      .then((res) => {
        if (res.data.status === 'OK') {
          let new_s = res.data.result.semesterList.map((s) => ({
            name: s.title,
            id: s.semester_id,
          }))
          let { name, id } = new_s[new_s.length - 1]
          new_s.unshift({
            name: '全部學期',
            value: 'ALL',
          })
          setSemesterlist(new_s)
          setSelected_semester({ name, value: id })
        } else {
          // console.log(res.data.result)
          alert(res.data.result)
        }
      })
      .catch((e) => {
        // console.log(e)
        alert(lang === 'zh' ? '獲取資料失敗' : 'Fetch data failed!')
      })
  }, [token])

  const handleSelect = (e) => {
    let [name, value] = e.split(',')
    setSelected_semester({ name, value })
  }

  const handlePageClick = (e) => {
    const selectedPage = e.selected
    setCurrentPage(selectedPage)
  }

  const handleSearch = (e) => {
    e.preventDefault()
    if (currentPage) setCurrentPage(0)
    else fetchData()
  }

  useEffect(() => {
    fetchData()
  }, [selected_semester, currentPage])

  return (
    <>
      <Form.Row className='mb-3'>
        <Col xs={true} sm={5}>
          <Form.Control
            className='h-100'
            placeholder={content.search_text}
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        </Col>
        <Col xs={'auto'} sm='auto'>
          <Button variant='primary' bsPrefix='db-btn' onClick={handleSearch}>
            {content.search}
          </Button>
        </Col>
        <Col xs={12} sm={true}>
          <div className='text-right h-100 mt-2 mt-sm-0'>
            <DropdownButton
              title={selected_semester.name}
              bsPrefix='db-dropdown'
              onSelect={handleSelect}
              className='h-100'
            >
              {semesterlist.map((s) => (
                <Dropdown.Item eventKey={[s.name, s.id]}>{s.name}</Dropdown.Item>
              ))}
            </DropdownButton>
          </div>
        </Col>
      </Form.Row>

      <div className='mobile-table d-block d-lg-none size-6'>
        {data.length <= 0 ? content.noData : null}
        {data.map((d, index) => (
          <div className='mobile-table-item p-3'>
            <div className='d-flex justify-content-between'>
              <span className='text-bold'>{content.date}</span>
              <span className='text-grey'>{d.date}</span>
            </div>
            <div className='d-flex justify-content-between'>
              <span className='text-bold'>{content.time}</span>
              <span className='text-grey'>
                {d.time_slot_text +
                  (lang === 'zh'
                    ? ' 第' + time_slot.find((s) => s.id == d.time_slot_id)?.code + '節'
                    : ' No.' + time_slot.find((s) => s.id == d.time_slot_id)?.code)}
              </span>
            </div>
            <div className='d-flex justify-content-between'>
              <span className='text-bold'>{content.student}</span>
              <span className='text-grey'>
                <a href={'mailto:' + (d.student_detail.email2 ? d.student_detail.email2 : d.student_detail.email)}>
                  {lang === 'zh' ? d.student_detail.name : d.student_detail.name_en}
                </a>
              </span>
            </div>
            <div className='d-flex justify-content-between'>
              <span className='text-bold'>{content.student_id}</span>
              <span className='text-grey'>{d.student_detail.account_no}</span>
            </div>
            <div className='d-flex justify-content-between'>
              <span className='text-bold'>{content.attendance}</span>
              <span className='text-grey'>
                {d.attendance_status == 1
                  ? content.attendance_yes
                  : d.attendance_status == 2
                  ? content.attendance_no
                  : content.attendance_default}
              </span>
            </div>
            <div className='d-flex justify-content-between'>
              <span className='text-bold'>{content.detail}</span>
              <span className='text-grey'>
                <DetailModal sm data={d} />
              </span>
            </div>
            <div className='d-flex justify-content-between'>
              <span className='text-bold'>{content.feedback}</span>
              <span className='text-grey'>
                {d.cancel_status == '1' ? (
                  '-'
                ) : d.feedback_status == '1' ? (
                  <Button
                    size='sm'
                    variant='green'
                    href={`${BACKEND_API}/${lang}/tutorGetQuestionaireReply/${d.reservation_id}/${token}`}
                    className='size-6'
                  >
                    {content.check_feedback}
                  </Button>
                ) : (
                  content.no_feedback
                )}
              </span>
            </div>
            <div className='d-flex justify-content-between'>
              <span className='text-bold'>{content.class}</span>
              <span className='text-grey'>
                {d.cancel_status == '1' ? '-' : <ClassRecordModal sm data={d} fetchData={fetchData} />}
              </span>
            </div>
          </div>
        ))}
      </div>

      <Table borderless striped className='text-grey text-center d-none d-lg-table'>
        <thead>
          <tr>
            <th scope='col'>{content.date}</th>
            <th scope='col'>{content.time}</th>
            <th scope='col'>{content.student}</th>
            <th scope='col'>{content.student_id}</th>
            <th scope='col'>{content.attendance}</th>
            <th scope='col'>{content.detail}</th>
            <th scope='col'>{content.feedback}</th>
            <th scope='col'>{content.class}</th>
          </tr>
        </thead>
        <tbody className='reserve-record-tobdy'>
          {data.map((d, index) => (
            <tr>
              <td>{d.date}</td>
              <td>
                {d.time_slot_text +
                  (lang === 'zh'
                    ? ' 第' + time_slot.find((s) => s.id == d.time_slot_id)?.code + '節'
                    : ' No.' + time_slot.find((s) => s.id == d.time_slot_id)?.code)}
              </td>
              <td>
                <a href={'mailto:' + (d.student_detail.email2 ? d.student_detail.email2 : d.student_detail.email)}>
                  {lang === 'zh' ? d.student_detail.name : d.student_detail.name_en}
                </a>
              </td>
              <td>{d.student_detail.account_no}</td>
              <td>
                {d.attendance_status == 1
                  ? content.attendance_yes
                  : d.attendance_status == 2
                  ? content.attendance_no
                  : content.attendance_default}
              </td>
              <td>
                <DetailModal data={d} />
              </td>
              {d.cancel_status == '1' ? (
                <>
                  <td>-</td>
                  <td>-</td>
                </>
              ) : (
                <>
                  <td>
                    {d.feedback_status == '1' ? (
                      <Button
                        variant='green'
                        href={`${BACKEND_API}/${lang}/tutorGetQuestionaireReply/${d.reservation_id}/${token}`}
                      >
                        {content.check_feedback}
                      </Button>
                    ) : (
                      content.no_feedback
                    )}
                  </td>
                  <td>
                    <ClassRecordModal data={d} fetchData={fetchData} />
                  </td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
      <FrontPagination currentPage={currentPage} pageCount={pageCount} onPageChange={handlePageClick} />
    </>
  )
}

function StudentTable() {
  const lang = useSelector((s) => s.front.lang)
  const token = useSelector((s) => s.login.token)
  let content = {
    english: {
      date: 'Date',
      time: 'Period',
      tutor: 'Tutor',
      detail: 'Record Details',
      feedback: 'Tutoring Feedbacks',
      no_feedback: 'Give Feedbacks',
      yes_feedback: 'Submitted',
      noData: 'There is no record',
    },
    chinese: {
      date: '日期',
      time: '節數',
      tutor: '小老師',
      detail: '紀錄詳情',
      feedback: '輔導回饋',
      class: '輔導紀錄',
      no_feedback: '填寫回饋',
      yes_feedback: '已填寫',
      noData: '目前尚無申請記錄',
    },
  }
  if (lang === 'zh') {
    content = content.chinese
  } else if (lang === 'en') {
    content = content.english
  }

  const perPage = 5
  const [currentPage, setCurrentPage] = useState(0)
  const [pageCount, setPageCount] = useState(0)
  const [data, setData] = useState([])
  const time_slot = useSelector((s) => s.front.defaultTimeSlot)

  const handlePageClick = (e) => {
    const selectedPage = e.selected
    setCurrentPage(selectedPage)
  }

  useEffect(() => {
    axios
      .get(`/en/api/getReservationHistoryList/${token}/${perPage}/${currentPage + 1}`)
      .then((res) => {
        const new_result = res.data.result
        // console.log(new_result.dataList)
        setData(new_result.dataList)
        const new_page_count = Math.ceil(parseInt(new_result.inlineCount) / perPage)
        setPageCount(new_page_count)
      })
      .catch((err) => {
        // console.log(err)
      })
  }, [token, currentPage])

  return (
    <>
      <div className='mobile-table d-block d-lg-none size-6'>
        {data.length <= 0 ? content.noData : null}
        {data.map((d) => (
          <div className='mobile-table-item p-3'>
            <div className='d-flex justify-content-between'>
              <span className='text-bold'>{content.date}</span>
              <span className='text-grey'>{d.date}</span>
            </div>
            <div className='d-flex justify-content-between'>
              <span className='text-bold'>{content.time}</span>
              <span className='text-grey'>
                {d.time_slot_text +
                  (lang === 'zh'
                    ? ' 第' + time_slot.find((s) => s.id == d.time_slot_id)?.code + '節'
                    : ' No.' + time_slot.find((s) => s.id == d.time_slot_id)?.code)}
              </span>
            </div>
            <div className='d-flex justify-content-between'>
              <span className='text-bold'>{content.tutor}</span>
              <span className='text-grey'>
                <TeacherModal tutor={d.tutor_detail} />
              </span>
            </div>
            <div className='d-flex justify-content-between'>
              <span className='text-bold'>{content.detail}</span>
              <span className='text-grey'>
                <DetailModal sm data={d} />
              </span>
            </div>
            <div className='d-flex justify-content-between'>
              <span className='text-bold'>{content.feedback}</span>
              <span className='text-grey'>
                {d.cancel_status == 1 || d.attendance_status == 2 ? (
                  '-'
                ) : d.feedback_status == 1 ? (
                  <div className='text-blud'>{content.yes_feedback}</div>
                ) : (
                  <Button
                    size='sm'
                    variant='green'
                    href={`${BACKEND_API}/${lang}/student_feedback/${d.reservation_id}/1/${token}`}
                    className='size-6'
                  >
                    {content.no_feedback}
                  </Button>
                )}
              </span>
            </div>
          </div>
        ))}
      </div>

      <Table borderless striped className='text-grey text-center d-none d-lg-table'>
        <thead>
          <tr>
            <th scope='col'>{content.date}</th>
            <th scope='col'>{content.time}</th>
            <th scope='col'>{content.tutor}</th>
            <th scope='col'>{content.detail}</th>
            <th scope='col'>{content.feedback}</th>
          </tr>
        </thead>
        <tbody className='reserve-record-tobdy'>
          {data.map((d) => (
            <tr>
              <td>{d.date}</td>
              <td>
                {d.time_slot_text +
                  (lang === 'zh'
                    ? ' 第' + time_slot.find((s) => s.id == d.time_slot_id)?.code + '節'
                    : ' No.' + time_slot.find((s) => s.id == d.time_slot_id)?.code)}
              </td>
              <td>
                <TeacherModal tutor={d.tutor_detail} />
              </td>
              <td>
                <DetailModal data={d} />
              </td>
              {d.cancel_status == 1 || d.attendance_status == 2 ? (
                <td>-</td>
              ) : (
                <td>
                  {d.feedback_status == 1 ? (
                    <div className='text-blud'>{content.yes_feedback}</div>
                  ) : (
                    <Button
                      variant='green'
                      href={`${BACKEND_API}/${lang}/student_feedback/${d.reservation_id}/1/${token}`}
                    >
                      {content.no_feedback}
                    </Button>
                  )}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </Table>

      <FrontPagination currentPage={currentPage} pageCount={pageCount} onPageChange={handlePageClick} />
    </>
  )
}

function DetailModal({ data, sm }) {
  const lang = useSelector((s) => s.front.lang)
  let content = {
    english: {
      date: 'Date',
      time: 'Period',
      detail: 'Record Details',
      subject: 'Subjects',
      scope: 'Specific parts of the subject',
      accompany: 'Accompanying Classmate',
      one: 'One',
      no: 'None',
      attendance: 'Attendance Status',
      cancel: 'Cancel',
      attendance_yes: 'Attended',
      attendance_no: 'Absent',
      attendance_default: 'unfilled',
    },
    chinese: {
      date: '日期',
      time: '節數',
      detail: '紀錄詳情',
      subject: '科目',
      scope: '科目範圍',
      accompany: '隨行同學',
      one: '一位',
      no: '無',
      attendance: '出席狀況',
      cancel: '已取消',
      attendance_yes: '已出席',
      attendance_no: '未出席',
      attendance_default: '未填寫',
    },
  }
  if (lang === 'zh') {
    content = content.chinese
  } else if (lang === 'en') {
    content = content.english
  }

  const [show, setShow] = useState(false)
  const time_slot = useSelector((s) => s.front.defaultTimeSlot)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const leftRow = lang === 'zh' ? 3 : 4

  return (
    <>
      <Button size={sm ? 'sm' : ''} onClick={handleShow} variant='green' className={sm ? 'size-6' : ''}>
        {content.detail}
      </Button>

      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className='text-grey size-3'>{content.detail}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='size-4'>
          <Container>
            <Row className='mb-3'>
              <Col xs={leftRow} className='text-grey'>
                {content.date}
              </Col>
              <Col xs={12 - leftRow}>{data.date}</Col>
            </Row>
            <Row className='mb-3'>
              <Col xs={leftRow} className='text-grey'>
                {content.time}
              </Col>
              <Col xs={12 - leftRow}>
                {data.time_slot_text +
                  (lang === 'zh'
                    ? ' 第' + time_slot.find((s) => s.id == data.time_slot_id)?.code + '節'
                    : ' No.' + time_slot.find((s) => s.id == data.time_slot_id)?.code)}
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col xs={leftRow} className='text-grey'>
                {content.subject}
              </Col>
              <Col xs={12 - leftRow}>{lang === 'zh' ? data.subject : data.subject_en}</Col>
            </Row>
            <Row className='mb-3'>
              <Col xs={leftRow} className='text-grey'>
                {content.scope}
              </Col>
              <Col xs={12 - leftRow}>{data.scope}</Col>
            </Row>
            <Row className='mb-3'>
              <Col xs={leftRow} className='text-grey'>
                {content.accompany}
              </Col>
              <Col xs={12 - leftRow}>{data.accompany == 1 ? content.one : content.no}</Col>
            </Row>
            <Row className='mb-3'>
              <Col xs={leftRow} className='text-grey'>
                {content.attendance}
              </Col>
              <Col xs={12 - leftRow}>
                {data.cancel_status == 1
                  ? content.cancel
                  : data.attendance_status == 1
                  ? content.attendance_yes
                  : data.attendance_status == 2
                  ? content.attendance_no
                  : content.attendance_default}
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  )
}

function ClassRecordModal({ data, fetchData, sm }) {
  const lang = useSelector((s) => s.front.lang)
  let content = {
    english: {
      title: 'Tutoring Records',
      button: 'Fill In the Records',
      edit_button: 'Edit the Records',
      placeholder: 'Please fill in the record.',
      yes: 'Attended',
      no: 'Absent',
      submit: 'Save',
    },
    chinese: {
      title: '輔導紀錄',
      button: '填寫紀錄',
      edit_button: '編輯紀錄',
      placeholder: '請輸入輔導紀錄',
      yes: '出席',
      no: '缺席',
      submit: '送出',
    },
  }
  if (lang === 'zh') {
    content = content.chinese
  } else if (lang === 'en') {
    content = content.english
  }

  const token = useSelector((s) => s.login.token)
  const [show, setShow] = useState(false)
  const [record, setRec] = useState(data.class_record || '')
  const [attend, setAtt] = useState(data.attendance_status == '1')

  useEffect(() => {
    if (data.reservation_id) {
      setRec(data.class_record)
      setAtt(data.attendance_status == '1')
    }
  }, [data.reservation_id])

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const handleSubmit = () => {
    axios
      .post('/en/api/tutorUpdateReservation', {
        id: parseInt(data.reservation_id),
        token,
        class_record: record,
        attendance_status: attend ? 1 : 2,
      })
      .then((res) => {
        if (res.data.status === 'OK') {
          fetchData()
          handleClose()
        } else {
          // console.log(res.data)
          alert(res.data.result)
        }
      })
      .catch((e) => {
        // console.log(e)
        alert('更新失敗')
      })
  }

  return (
    <>
      <Button size={sm ? 'sm' : ''} onClick={handleShow} variant='green' className={sm ? 'size-6' : ''}>
        {data.class_record ? content.edit_button : content.button}
      </Button>

      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className='text-grey size-3'>{content.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='size-4'>
          <Form.Group>
            <Form.Control
              as='textarea'
              placeholder={content.placeholder}
              className='radius-border'
              style={{
                background: 'rgba(159, 206, 205, 0.2)',
                border: '1px solid #9FCECD',
              }}
              rows={6}
              value={record}
              onChange={(e) => setRec(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Check
              inline
              type='radio'
              label={content.yes}
              value='true'
              id='yes'
              checked={attend}
              onChange={(e) => setAtt(e.target.value === 'true')}
            />
            <Form.Check
              inline
              type='radio'
              label={content.no}
              value='false'
              id='no'
              checked={!attend}
              onChange={(e) => setAtt(e.target.value === 'true')}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <button
            className='submit-btn text-white w-100'
            onClick={handleSubmit}
            style={{ maxWidth: 'none' }}
            disabled={attend && (record === '' || record === data.class_record)}
          >
            {content.submit}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

function TeacherModal({ tutor }) {
  const lang = useSelector((s) => s.front.lang)
  let content = {
    english: {
      title: "Tutor's Information",
      name: 'Name',
      dept: 'Department',
      subject: 'Tutoring Subjects',
      email: 'School E-mail',
      email2: 'Other E-mail',
    },
    chinese: {
      title: '小老師資訊',
      name: '姓名',
      dept: '系所',
      subject: '教授科目',
      email: '學校信箱',
      email2: '其他信箱',
    },
  }
  if (lang === 'zh') {
    content = content.chinese
  } else if (lang === 'en') {
    content = content.english
  }

  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <>
      <div role='button' onClick={handleShow} className='text-blue'>
        {lang === 'zh' ? tutor.name : tutor.name_en}
      </div>

      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className='text-grey size-3'>{content.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='size-4'>
          <Container>
            <Row className='mb-3'>
              <Col xs={3} className='text-grey'>
                {content.name}
              </Col>
              <Col xs={9}>{lang === 'zh' ? tutor.name : tutor.name_en}</Col>
            </Row>
            <Row className='mb-3'>
              <Col xs={3} className='text-grey'>
                {content.dept}
              </Col>
              <Col xs={9}>{lang === 'zh' ? tutor.dept : tutor.dept_en}</Col>
            </Row>
            <hr />
            <Row className='mb-3'>
              <Col xs={3} className='text-grey'>
                {content.subject}
              </Col>
              <Col xs={9}>{lang === 'zh' ? tutor.tutor_subjects : tutor.tutor_subjects_en}</Col>
            </Row>
            {tutor.email ? (
              <Row className='mb-3'>
                <Col xs={3} className='text-grey'>
                  {content.email}
                </Col>
                <Col xs={9} className='text-blue'>
                  <a href={'mailto:' + tutor.email}>{tutor.email}</a>
                </Col>
              </Row>
            ) : (
              <div></div>
            )}
            {tutor.email2 ? (
              <Row className='mb-3'>
                <Col xs={3} className='text-grey'>
                  {content.email2}
                </Col>
                <Col xs={9} className='text-blue'>
                  <a href={'mailto:' + tutor.email2}>{tutor.email2}</a>
                </Col>
              </Row>
            ) : (
              <div></div>
            )}
          </Container>
        </Modal.Body>
      </Modal>
    </>
  )
}
