import { Component } from 'react'
import { connect } from 'react-redux'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import TeamCard from 'components/basic_page/service/team_card.js'
import axios from 'axios'
import onlineReservePic from 'img/logos/weblogo/online_reserve.svg'
import consultPic from 'img/logos/weblogo/consult.svg'
import feedbackPic from 'img/logos/weblogo/feedback.svg'
import pinPic from 'img/deco-icons/pin.svg'
import bookPic from 'img/deco-icons/book.svg'
import clockPic from 'img/deco-icons/clock.svg'
import Rules from './rules.js'

class ServicePage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tutor: [],
    }

    this.content = {
      english: {
        title: 'About the Service',
        text1:
          'In order to enhance the learning effectiveness of NTU students and help them deal with problems encountered in basic subjects, the CTLD hires tutors to provide tutoring service for students in need.',
        informaiton: 'Basic Information',
        place: 'Tutoring Location',
        place1: 'Zonghe Lecture Building',
        place2: '102 and 103 classroom',
        subject: 'Available Subjects',
        subject_list: 'Calculus, Statistics, General Physics',
        reserve: 'Reservation Time',
        reserve_text: 'Each student may only reserve tutoring sessions within 14 days.',
        service: 'Service Flow',
        service1: 'Online Reservation',
        service2: 'In-Person Counselling',
        service3: 'Give Feedbacks',
        tean: 'Teams',
      },
      chinese: {
        title: '關於服務',
        text1: '為提昇本校學生的學習成效，並協助解決其基礎學科學習疑難，',
        text2: '特甄選課業輔導小老師，提供定時定點的學習諮詢服務。',
        informaiton: '基本資訊',
        place: '諮詢地點',
        place1: '綜合教學館',
        place2: '學習開放空間',
        subject: '輔導科目',
        subject_list: '微積分、統計學、普通物理',
        reserve: '預約開放',
        reserve_text: '可以預約14天內的課程',
        service: '服務流程',
        service1: '線上預約',
        service2: '到場諮詢',
        service3: '填寫回饋',
        tean: '諮詢團隊',
      },
    }
  }
  cards = (text, img_src) => {
    return (
      <div className='bg-white text-center service-cards2 rounded pt-2 pt-sm-3'>
        <div className='service-content2 text-dark'>
          <h2 className='service-card-text'>{text}</h2>
          <img src={img_src} alt='logo' className='w-75 h-auto' />
        </div>
      </div>
    )
  }
  componentDidMount() {
    axios
      .get('/en/api/getTutorFrontList')
      .then((res) => {
        if (res.data.status === 'OK') {
          // // console.log(res.data.result.tutor)
          this.setState({ tutor: res.data.result.tutor })
        } else {
          // console.log(res.data)
          alert(res.data.result)
        }
      })
      .catch((e) => {
        // console.log(e)
        alert(this.props.lang === 'zh' ? '獲取小老師資訊失敗' : 'Fetch tutor failed!')
      })
  }

  render() {
    let content
    let lang = this.props.lang
    if (lang === 'zh') {
      content = this.content.chinese
    } else if (this.props.lang === 'en') {
      content = this.content.english
    }

    let tutor = this.state.tutor

    let result_list = tutor
      .map((x, i) => {
        return i % 3 === 0 ? tutor.slice(i, i + 3) : null
      })
      .filter((x) => x != null)

    const arrow = (
      <Col xs='auto' className='px-auto px-sm-4'>
        <i class='fas fa-caret-right text-green size-2 vertical-center'></i>
      </Col>
    )

    return (
      <div>
        <section id='s-about' className='text-center container mb-5'>
          <h2 className='my-5 text-left text-sm-center'>{content.title}</h2>

          <p className='size-3'>
            {content.text1}
            <br />
            {content.text2}
          </p>

          <h3 className='size-3 text-blue text-left'>{content.informaiton}</h3>
          <div className='row mt-3 p-sm-3'>
            <div className='col px-2 d-flex flex-column flex-sm-row border-right'>
              <div>
                <img src={pinPic} className='service-img' alt='' />
              </div>

              <div className='col-sm-9 pt-3 pt-sm-0 size-4 text-center text-sm-left'>
                <div className='smal-title'>{content.place}</div>
                <p>
                  {content.place1}
                  <br />
                  {content.place2}
                </p>
              </div>
            </div>
            <div className='col px-2 d-flex flex-column flex-sm-row border-right'>
              <div>
                <img src={bookPic} className='service-img' alt='' />
              </div>

              <div className='col-sm-9 pt-3 pt-sm-0 size-4 text-center text-sm-left'>
                <div className='smal-title'>{content.subject}</div>
                <p>{content.subject_list}</p>
              </div>
            </div>
            <div className='col pl-4 d-flex flex-column flex-sm-row'>
              <div>
                <img src={clockPic} className='service-img' alt='' />
              </div>

              <div className='col-sm-9 pl-0 pl-sm-4 pt-3 pt-sm-0 size-4 text-center text-sm-left'>
                <div className='smal-title'>{content.reserve}</div>
                <p>{content.reserve_text}</p>
              </div>
            </div>
          </div>
        </section>

        <section id='s-rundown' className='bg-grey mb-5'>
          <Container className='py-5'>
            <h3 className='size-3 text-blue pb-3 text-left'>{content.service}</h3>

            <Row>
              <Col xs={true} className='px-0'>
                {this.cards(content.service1, onlineReservePic)}
              </Col>
              {arrow}
              <Col xs={true} className='px-0'>
                {this.cards(content.service2, consultPic)}
              </Col>
              {arrow}
              <Col xs={true} className='px-0'>
                {this.cards(content.service3, feedbackPic)}
              </Col>
            </Row>
          </Container>
        </section>

        <section id='s-team' className='container mb-5'>
          <h3 className='size-3 text-blue pb-3 text-left'>{content.team}</h3>

          {result_list.map((result, idx) => (
            <div className='row' key={idx}>
              {result.map((t) => (
                <div className='col-sm-4 my-3'>
                  <TeamCard teacher={t} />
                </div>
              ))}
            </div>
          ))}
        </section>

        <section id='sq' className='bg-grey'>
          <Rules />
        </section>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    lang: state.front.lang,
  }
}

export default connect(mapStateToProps)(ServicePage)
