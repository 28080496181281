import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useRouteMatch, Switch } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import Container from 'react-bootstrap/Container'
import { useHistory } from 'react-router'
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom'
import axios from 'axios'

function CheckinChecked() {
  const lang = useSelector((s) => s.front.lang)
  const token = useSelector((s) => s.login.token)
  const [data, setData] = useState({})
  const [modalStatus, setmodalStatus] = useState(false)
  const [modalmode, setmodalmode] = useState('in')
  const [loadingshow, setloadingshow] = useState(false)

  const currentPath = window.location.pathname

  let content = {
    english: {
      title: 'Reservation Reminder',
      iwantcheckin: 'Check-In Now',
      deartutor: 'Dear Tutor',
      incomingreserve: 'You have one Incoming Reservation ',
      reservetime: 'Reservation Time: ',
      remembercheckin: 'Please Remember to Check-In ！',
      lastreserve: 'You last Reservation has end ',
      reserveendtime: 'Reservation End Time: ',
      remembercheckout: 'Please Remember to Check-Out ！',
      iwantcheckout: 'Check-Out Now',
      checkinsuccess: 'Update Successfully !',
    },
    chinese: {
      title: '預約打卡提醒',
      iwantcheckin: '我要打卡上班',
      deartutor: '親愛的小老師',
      incomingreserve: '您有一項預約即將到來 ',
      reservetime: '預約時間： ',
      remembercheckin: '請記得打卡上班哦 ！',
      lastreserve: '您上一項預約已經結束 ',
      reserveendtime: '預約結束時間： ',
      remembercheckout: '請記得打卡下班哦 ！',
      iwantcheckout: '我要打卡下班',
      checkinsuccess: '打卡成功 ！',
    },
  }
  if (lang === 'zh') {
    content = content.chinese
  } else if (lang === 'en') {
    content = content.english
  }

  const handleModal = () => {
    setmodalStatus(false)
  }
  const gotocheckin = () => {
    let BACKEND_API = process.env.REACT_APP_BACKEND_API
   
    setloadingshow(!loadingshow);
    const body = {
      token: token,
      checkinMode: modalmode,
    }
    axios.post(BACKEND_API + '/en/api/tutorCheckinNow', body)
      .then((res) => {
        setloadingshow(!loadingshow);
        if (res.data.status === 'OK') {
          setmodalStatus(false)
          if (currentPath.indexOf('checkin_record') > -1) {
            setmodalStatus(false)
            window.location.reload();
          } else {
            setmodalStatus(false)
            window.location.reload();
          }
          alert(content.checkinsuccess)
        } else {
          // console.log(res.data.result)
          alert(res.data.result)
        }
      })
      .catch((e) => {
        setloadingshow(!loadingshow);
        // console.log(e)
        alert('發生錯誤')
      })
  }

  const fetchData = (tokennow) => {
    setmodalStatus(false);
    setTimeout(() => {
      let BACKEND_API = process.env.REACT_APP_BACKEND_API
      //// console.log(token);
      axios
        .get('/en/api/getTutorReservationReached/' + tokennow)
        .then((res) => {
          if (res.data.status === 'OK') {
           
            if (res.data.result.poupNow) {
              setmodalmode(res.data.result.mode)
              const new_data = res.data.result.reservation
              setData(new_data)
              setmodalStatus(true)
            }
            
          }
        })
        .catch((e) => { })
    }, 300)
  }

  useEffect(() => {
    if (token) {
      fetchData(token)
    }
    setInterval(() => {
      if (token) {
        fetchData(token)
      }
    }, 60000)

  }, [token])

  return (
    <Modal centered show={modalStatus} onHide={handleModal}>
      <Modal.Header closeButton>
        <Modal.Title className='text-grey size-3'>{content.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className='size-4'>
        <Container className='text-center'>
          <h4> {content.deartutor}</h4>
          {modalmode == 'in' && (
            <p>
              {/* {content.incomingreserve} <br />
              {content.reservetime} {data.time_start} <br /> */}
              {content.remembercheckin}
            </p>
          )}
          {modalmode == 'out' && (
            <p>
              {/* {content.lastreserve} <br />
              {content.reserveendtime} {data.time_end} <br /> */}
              {content.remembercheckout}
            </p>
          )}

          <hr />
          <div class='mt-4 mb-4 text-center'>
            <Button variant='primary' onClick={gotocheckin}>
              {modalmode == 'in' ? content.iwantcheckin : content.iwantcheckout}
              {loadingshow && <i class='fa fa-spin fa-spinner'></i>}
            </Button>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  )
}

export default CheckinChecked
