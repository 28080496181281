import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import NewsList from 'containers/basic_page/main/news_list.js'

function News({ news }) {
  const lang = useSelector((s) => s.front.lang)
  let content = {
    english: {
      title: 'News',
      button: 'More News',
    },
    chinese: {
      title: '最新消息',
      button: '更多消息',
    },
  }
  if (lang === 'zh') {
    content = content.chinese
  } else if (lang === 'en') {
    content = content.english
  }

  return (
    <>
      <h3 className='size-2 text-light text-center py-3'>{content.title}</h3>
      <div>
        <NewsList news={news} show={4} />
      </div>
      <div className='text-center mt-4'>
        <Link to='/basic/news'>
          <Button variant='orange'>{content.button}</Button>
        </Link>
      </div>
    </>
  )
}

export default News
