import ReactPaginate from 'react-paginate'

function FrontPagination({ currentPage, pageCount, onPageChange }) {
  return (
    <div className='text-center d-flex justify-content-center my-5'>
      <ReactPaginate
        previousLabel={<i class='fas fa-chevron-left'></i>}
        nextLabel={<i class='fas fa-chevron-right'></i>}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={pageCount}
        marginPagesDisplayed={1}
        pageRangeDisplayed={2}
        onPageChange={onPageChange}
        forcePage={currentPage}
        containerClassName='pagination pagination-front text-green'
        activeClassName={'p-active'}
        pageClassName='p-num-li m-0 mx-sm-2'
        pageLinkClassName='p-num-a'
        disabledClassName='d-none'
        previousClassName='prev p-num-li m-0 mr-sm-2'
        previousLinkClassName='p-num-a'
        nextClassName='next p-num-li m-0 ml-sm-2'
        nextLinkClassName='p-num-a'
      />
    </div>
  )
}

function BackStagePagination({ currentPage, pageCount, onPageChange }) {
  return (
    <div className='text-center d-flex justify-content-center my-5'>
      <ReactPaginate
        previousLabel={<i class='fas fa-chevron-left'></i>}
        nextLabel={<i class='fas fa-chevron-right'></i>}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={pageCount}
        forcePage={currentPage}
        marginPagesDisplayed={2}
        pageRangeDisplayed={3}
        onPageChange={onPageChange}
        containerClassName='pagination db-pagination text-darkblue'
        activeClassName={'p-active-db'}
        pageClassName='db-p-num-li m-0 mx-sm-2'
        pageLinkClassName='db-p-num-a'
        disabledClassName='d-none'
        previousClassName='prev db-p-num-li m-0 mr-sm-2'
        previousLinkClassName='db-p-num-a'
        nextClassName='next db-p-num-li m-0 ml-sm-2'
        nextLinkClassName='db-p-num-a'
      />
    </div>
  )
}

export default FrontPagination
export { BackStagePagination }
