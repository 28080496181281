import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useRouteMatch, Switch } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import PrivateRoute from 'private-route.js'
import { fetchDefaultTimeSlot } from 'actions/basic/actions'
import axios from 'axios'

function ReserveRecordPage() {
  const lang = useSelector((s) => s.front.lang)
  const dispatch = useDispatch()
  let { path } = useRouteMatch()

  useEffect(() => {
    dispatch(fetchDefaultTimeSlot())
  }, [])

  return (
    <div className='container mb-5' id='record'>
      <div className='absolute-bg'></div>
      <h1 className='size-1 my-5 text-left text-sm-center'>{lang === 'zh' ? '預約紀錄' : 'Reserve records'}</h1>

      <Switch>
        <PrivateRoute exact path={path + '/student'} check_role='student' cate='basic' Component={StudentTable} />
        <PrivateRoute path={path + '/tutor'} check_role='tutor' cate='basic' Component={TutorTable} />
      </Switch>
    </div>
  )
}

export default ReserveRecordPage

function TutorTable() {
  const lang = useSelector((s) => s.front.lang)
  const token = useSelector((s) => s.login.token)
  let content = {
    english: {
      date: 'Date',
      time: 'Period',
      student: 'Student',
      detail: 'Reservation Details',
      profile: 'Basic Information',
      noData: 'There is no record',
    },
    chinese: {
      date: '日期',
      time: '節數',
      student: '學生',
      detail: '紀錄詳情',
      profile: '基本資料',
      noData: '目前尚無申請記錄',
    },
  }
  if (lang === 'zh') {
    content = content.chinese
  } else if (lang === 'en') {
    content = content.english
  }

  const [data, setData] = useState([])
  const time_slot = useSelector((s) => s.front.defaultTimeSlot)

  useEffect(() => {
    if (token === '') {
      return setData([])
    }
    let url = '/en/api/getTutorReservationList/' + token + '/9999999/1'
    axios
      .get(url)
      .then((res) => {
        const new_data = res.data.result.dataList
        // console.log(new_data)
        setData(new_data)
      })
      .catch((err) => {
        // console.log(err)
      })
  }, [token])

  return (
    <>
      <div className='mobile-table d-block d-lg-none size-6'>
        {data.length <= 0 ? content.noData : null}
        {data.map((d) => (
          <div className='mobile-table-item p-3'>
            <div className='d-flex justify-content-between'>
              <span className='text-bold'>{content.date}</span>
              <span className='text-grey'>{d.date}</span>
            </div>
            <div className='d-flex justify-content-between'>
              <span className='text-bold'>{content.time}</span>
              <span className='text-grey'>
                {d.time_slot_text +
                  (lang === 'zh'
                    ? ' 第' + time_slot.find((s) => s.id == d.time_slot_id)?.code + '節'
                    : ' No.' + time_slot.find((s) => s.id == d.time_slot_id)?.code)}
              </span>
            </div>
            <div className='d-flex justify-content-between'>
              <span className='text-bold'>{content.student}</span>
              <span className='text-grey'>{lang === 'zh' ? d.student_detail.name : d.student_detail.name_en}</span>
            </div>
            <div className='d-flex justify-content-between'>
              <span className='text-bold'>{content.profile}</span>
              <span className='text-grey'>
                <StudentModal sm student={d.student_detail} />
              </span>
            </div>
            <div className='d-flex justify-content-between'>
              <span className='text-bold'>{content.detail}</span>
              <span className='text-grey'>
                <DetailModal sm data={d} />
              </span>
            </div>
          </div>
        ))}
      </div>

      <Table borderless striped className='text-grey text-center d-none d-lg-table'>
        <thead>
          <tr>
            <th scope='col'>{content.date}</th>
            <th scope='col'>{content.time}</th>
            <th scope='col'>{content.student}</th>
            <th scope='col'>{content.profile}</th>
            <th scope='col'>{content.detail}</th>
          </tr>
        </thead>
        <tbody className='reserve-record-tobdy'>
          {data.map((d) => (
            <tr>
              <td>{d.date}</td>
              <td>
                {d.time_slot_text +
                  (lang === 'zh'
                    ? ' 第' + time_slot.find((s) => s.id == d.time_slot_id)?.code + '節'
                    : ' No.' + time_slot.find((s) => s.id == d.time_slot_id)?.code)}
              </td>
              <td>{lang === 'zh' ? d.student_detail.name : d.student_detail.name_en}</td>
              <td>
                <StudentModal student={d.student_detail} />
              </td>
              <td>
                <DetailModal data={d} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )
}

function StudentTable() {
  const lang = useSelector((s) => s.front.lang)
  const token = useSelector((s) => s.login.token)
  let content = {
    english: {
      date: 'Date',
      time: 'Period',
      tutor: 'Tutor',
      detail: 'Reservation Details',
      modify_file: 'Revised File',
      cancel: 'Cancel Reservation',
      noData: 'There is no record',
    },
    chinese: {
      date: '日期',
      time: '節數',
      tutor: '小老師',
      detail: '預約詳情',
      modify_file: '修改檔案',
      cancel: '取消預約',
      noData: '目前尚無申請記錄',
    },
  }
  if (lang === 'zh') {
    content = content.chinese
  } else if (lang === 'en') {
    content = content.english
  }

  const time_slot = useSelector((s) => s.front.defaultTimeSlot)
  const [data, setData] = useState([])

  const fetchData = () => {
    let url = '/en/api/getReservationList/' + token + '/999999999/1'
    axios
      .get(url)
      .then((res) => {
        const new_data = res.data.result.dataList
        // console.log(new_data)
        setData(new_data)
      })
      .catch((err) => {
        // console.log(err)
      })
  }

  useEffect(() => {
    if (token === '') {
      return setData([])
    }
    fetchData()
  }, [token])

  const removeRecord = (id) => {
    let d = data
    d = d.filter((d) => parseInt(d.reservation_id) !== id)
    setData(d)
  }

  return (
    <>
      <div className='mobile-table d-block d-lg-none size-6'>
        {data.length <= 0 ? content.noData : null}
        {data.map((d) => (
          <div className='mobile-table-item p-3'>
            <div className='d-flex justify-content-between'>
              <span className='text-bold'>{content.date}</span>
              <span className='text-grey'>{d.date}</span>
            </div>
            <div className='d-flex justify-content-between'>
              <span className='text-bold'>{content.time}</span>
              <span className='text-grey'>
                {d.time_slot_text +
                  (lang === 'zh'
                    ? ' 第' + time_slot.find((s) => s.id == d.time_slot_id)?.code + '節'
                    : time_slot.find((s) => s.id == d.time_slot_id)?.code)}
              </span>
            </div>
            <div className='d-flex justify-content-between'>
              <span className='text-bold'>{content.tutor}</span>
              <span className='text-grey'>
                <TeacherModal tutor={d.tutor_detail} />
              </span>
            </div>
            <div className='d-flex justify-content-between'>
              <span className='text-bold'>{content.detail}</span>
              <span className='text-grey'>
                <DetailModal sm data={d} />
              </span>
            </div>
            <div className='d-flex justify-content-between'>
              <span className='text-bold'>{content.modify_file}</span>
              <span className='text-grey'>
                <FileModal sm data={d} fetchData={fetchData} />
              </span>
            </div>
            <div className='d-flex justify-content-between'>
              <span className='text-bold'>{content.cancel}</span>
              <span className='text-grey'>
                <CancelModal sm data={d} token={token} removeRecord={removeRecord} />
              </span>
            </div>
          </div>
        ))}
      </div>

      <Table borderless striped className='text-grey text-center d-none d-lg-table'>
        <thead>
          <tr>
            <th scope='col'>{content.date}</th>
            <th scope='col'>{content.time}</th>
            <th scope='col'>{content.tutor}</th>
            <th scope='col'>{content.detail}</th>
            <th scope='col'>{content.modify_file}</th>
            <th scope='col'>{content.cancel}</th>
          </tr>
        </thead>
        <tbody className='reserve-record-tobdy'>
          {data.map((d) => (
            <tr>
              <td>{d.date}</td>
              <td>
                {d.time_slot_text +
                  (lang === 'zh'
                    ? ' 第' + time_slot.find((s) => s.id == d.time_slot_id)?.code + '節'
                    : time_slot.find((s) => s.id == d.time_slot_id)?.code)}
              </td>
              <td>
                <TeacherModal tutor={d.tutor_detail} />
              </td>
              <td>
                <DetailModal data={d} />
              </td>
              <td>
                <FileModal data={d} fetchData={fetchData} />
              </td>
              <td>
                <CancelModal data={d} token={token} removeRecord={removeRecord} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )
}

function DetailModal({ data, sm }) {
  const lang = useSelector((s) => s.front.lang)
  let content = {
    english: {
      date: 'Date',
      time: 'Period',
      detail: 'Reservation Details',
      modify_file: 'Revised File',
      subject: 'Subjects',
      scope: 'Specific parts of the subject',
      accompany: 'Accompanying Classmate',
      one: 'One',
      no: 'None',
      file: 'Files',
    },
    chinese: {
      date: '日期',
      time: '節數',
      detail: '紀錄詳情',
      modify_file: '修改檔案',
      subject: '科目',
      scope: '科目範圍',
      accompany: '隨行同學',
      one: '一位',
      no: '無',
      file: '檔案',
    },
  }
  if (lang === 'zh') {
    content = content.chinese
  } else if (lang === 'en') {
    content = content.english
  }

  const time_slot = useSelector((s) => s.front.defaultTimeSlot)
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  let download
  if (data.student_detail) {
    download = data.attachment.map((url) => (
      <Row className='mb-3'>
        <Col xs={3} className='text-grey'>
          {content.file}
        </Col>
        <Col xs={9} className='text-blue'>
          <a href={url} className='d-inline-block w-100 text-truncate'>
            <i class='fas fa-download'></i> {url}
          </a>
        </Col>
      </Row>
    ))
  }

  const leftRow = lang === 'zh' ? 3 : 4

  return (
    <>
      <Button size={sm ? 'sm' : ''} onClick={handleShow} variant='green' className={sm ? 'size-6' : ''}>
        {content.detail}
      </Button>

      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className='text-grey size-3'>{content.detail}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='size-4'>
          <Container>
            <Row className='mb-3'>
              <Col xs={leftRow} className='text-grey'>
                {content.date}
              </Col>
              <Col xs={12 - leftRow}>{data.date}</Col>
            </Row>
            <Row className='mb-3'>
              <Col xs={leftRow} className='text-grey'>
                {content.time}
              </Col>
              <Col xs={12 - leftRow}>
                {data.time_slot_text +
                  (lang === 'zh'
                    ? ' 第' + time_slot.find((s) => s.id == data.time_slot_id)?.code + '節'
                    : time_slot.find((s) => s.id == data.time_slot_id)?.code)}
              </Col>
            </Row>
            <hr />
            <Row className='mb-3'>
              <Col xs={leftRow} className='text-grey'>
                {content.subject}
              </Col>
              <Col xs={12 - leftRow}>{lang === 'zh' ? data.subject : data.subject_en}</Col>
            </Row>
            <Row className='mb-3'>
              <Col xs={leftRow} className='text-grey'>
                {content.scope}
              </Col>
              <Col xs={12 - leftRow}>{data.scope}</Col>
            </Row>
            <Row className='mb-3'>
              <Col xs={leftRow} className='text-grey'>
                {content.accompany}
              </Col>
              <Col xs={12 - leftRow}>{data.accompany == 1 ? content.one : content.no}</Col>
            </Row>
            {download}
          </Container>
        </Modal.Body>
      </Modal>
    </>
  )
}

function FileModal({ data, fetchData, sm }) {
  const lang = useSelector((s) => s.front.lang)
  let content = {
    english: {
      title: 'Revised File',
      no_file: 'No File Now',
      restrict: 'Upload Five Files At Most',
      new_file: 'Add New Files',
      fail: 'Upload failed!',
    },
    chinese: {
      title: '修改檔案',
      no_file: '目前尚無檔案',
      restrict: '最多上傳五個',
      new_file: '新增檔案',
      fail: '上傳檔案失敗',
    },
  }
  if (lang === 'zh') {
    content = content.chinese
  } else if (lang === 'en') {
    content = content.english
  }

  const token = useSelector((s) => s.login.token)
  const [show, setShow] = useState(false)
  const [file_url, setFileUrl] = useState(data.attachment)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const upadteAttach = async (attachment) => {
    const body = {
      id: parseInt(data.reservation_id),
      mode: 'Edit',
      token: token,
      date: data.date,
      tutor_id: parseInt(data.tutor_id),
      tel: data.tel,
      email: data.email,
      subject: data.subject,
      scope: data.scope,
      accompany: parseInt(data.accompany),
      attachment,
      time_slot_id: parseInt(data.time_slot_id),
    }
    // console.log(body)
    try {
      const res = await axios.post('/en/api/studentReservationSubmit', body)
      if (res.data.status === 'OK') {
        setFileUrl(attachment)
        fetchData()
      } else {
        // console.log(res.data)
        alert(res.data.result)
      }
    } catch (err) {
      // console.log(err)
      alert('更新檔案失敗')
    }
  }

  const deleteFile = async (url) => {
    let new_attachment = data.attachment.filter((a) => a !== url)
    await upadteAttach(new_attachment)
  }

  const showSelectedFile = (file_url) => {
    return (
      <div className='selected-file d-flex justify-content-between p-3 mb-2'>
        <a className='m-0 w-100 text-truncate' href={file_url} target='_blank'>
          {file_url}
        </a>
        <i type='button' onClick={() => deleteFile(file_url)} class='fas fa-trash'></i>
      </div>
    )
  }

  const handleFileInputChange = async (e) => {
    if (data.attachment.length + e.target.files.length > 5) {
      alert(content.restrict)
      return
    }
    for (const f of e.target.files) {
      let new_file_url
      // upload file
      let formdata = new FormData()
      formdata.append('file1', f)
      try {
        const res = await axios.post('/en/api/upload_file/' + token, formdata)
        if (res.data.status === 'OK') {
          new_file_url = res.data.result.url
        } else {
          // console.log(res.data)
          alert(res.data.result)
          continue
        }
      } catch (err) {
        // console.log(err)
        alert(content.fail)
        continue
      }
      //update reservation
      let new_attachment = data.attachment
      new_attachment.push(new_file_url)
      await upadteAttach(new_attachment)
    }
  }

  return (
    <>
      <Button size={sm ? 'sm' : ''} onClick={handleShow} variant='green' className={sm ? 'size-6' : ''}>
        {content.title}
      </Button>

      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className='text-grey size-3'>{content.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='text-center size-4'>
          {file_url.length > 0 ? file_url.map(showSelectedFile) : <p className='text-grey'>{content.no_file}</p>}
          <p className='text-red'>{content.restrict}</p>
          <hr />
          <div class='custom-file'>
            <input
              multiple
              type='file'
              class='custom-file-input'
              id='file'
              onChange={handleFileInputChange}
              disabled={file_url.length > 5}
            />
            <label class='custom-file-label' for='file'>
              <p>{content.new_file}</p>
            </label>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

function CancelModal({ data, sm, removeRecord, token }) {
  const lang = useSelector((s) => s.front.lang)
  let content = {
    english: {
      title: 'Are you sure to cancel the reservation?',
      button: 'Cancel Reservation',
      reject: 'No',
      accept: 'Yes',
    },
    chinese: {
      title: '確定取消預約？',
      button: '取消預約',
      reject: '取消',
      accept: '確定',
    },
  }
  if (lang === 'zh') {
    content = content.chinese
  } else if (lang === 'en') {
    content = content.english
  }
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const cancelResv = () => {
    const id = parseInt(data.reservation_id)
    const body = {
      id,
      token,
    }
    // console.log(body)
    axios
      .post(`/${lang}/api/studentCancelReservation/`, body)
      .then((res) => {
        // console.log(res.data)
        if (res.data.status === 'OK') {
          removeRecord(id)
        } else {
          alert(res.data.result)
        }
        handleClose()
      })
      .catch((err) => {
        // console.log(err)
        alert(lang === 'zh' ? '取消失敗' : 'Cancel failed')
      })
  }

  return (
    <>
      <Button size={sm ? 'sm' : ''} onClick={handleShow} variant='danger' className={sm ? 'size-6' : ''}>
        {content.button}
      </Button>

      <Modal centered show={show} onHide={handleClose}>
        <Modal.Body className='text-center size-4'>
          <p className='text-center'>{content.title}</p>
          <hr />
          <Container>
            <Row>
              <Col onClick={handleClose} type='button' className='text-center py-3'>
                {content.reject}
              </Col>
              <Col onClick={cancelResv} type='button' className='text-center py-3 record-confirm'>
                {content.accept}
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  )
}

function TeacherModal({ tutor }) {
  const lang = useSelector((s) => s.front.lang)
  let content = {
    english: {
      title: "Tutor's Information",
      name: 'Name',
      dept: 'Department',
      subject: 'Tutoring Subjects',
      email: 'School E-mail',
      email2: 'Other E-mail',
    },
    chinese: {
      title: '小老師資訊',
      name: '姓名',
      dept: '系所',
      subject: '教授科目',
      email: '學校信箱',
      email2: '其他信箱',
    },
  }
  if (lang === 'zh') {
    content = content.chinese
  } else if (lang === 'en') {
    content = content.english
  }

  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <>
      <div role='button' onClick={handleShow} className='text-blue'>
        {lang === 'zh' ? tutor.name : tutor.name_en}
      </div>

      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className='text-grey size-3'>{content.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='size-4'>
          <Container>
            <Row className='mb-3'>
              <Col xs={3} className='text-grey'>
                {content.name}
              </Col>
              <Col xs={9}>{lang === 'zh' ? tutor.name : tutor.name_en}</Col>
            </Row>
            <Row className='mb-3'>
              <Col xs={3} className='text-grey'>
                {content.dept}
              </Col>
              <Col xs={9}>{lang === 'zh' ? tutor.dept : tutor.dept_en}</Col>
            </Row>
            <hr />
            <Row className='mb-3'>
              <Col xs={3} className='text-grey'>
                {content.subject}
              </Col>
              <Col xs={9}>{lang === 'zh' ? tutor.tutor_subjects : tutor.tutor_subjects_en}</Col>
            </Row>
            {tutor.email ? (
              <Row className='mb-3'>
                <Col xs={3} className='text-grey'>
                  {content.email}
                </Col>
                <Col xs={9} className='text-blue'>
                  {tutor.email}
                </Col>
              </Row>
            ) : (
              <div></div>
            )}
            {tutor.email2 ? (
              <Row className='mb-3'>
                <Col xs={3} className='text-grey'>
                  {content.email2}
                </Col>
                <Col xs={9} className='text-blue'>
                  {tutor.email2}
                </Col>
              </Row>
            ) : (
              <div></div>
            )}
          </Container>
        </Modal.Body>
      </Modal>
    </>
  )
}

function StudentModal({ student, sm }) {
  const lang = useSelector((s) => s.front.lang)
  let content = {
    english: {
      title: 'Basic Information',
      name: 'Name',
      dept: 'Department',
      subject: 'Tutoring Subjects',
      email: 'School E-mail',
      email2: 'Other E-mail',
    },
    chinese: {
      title: '基本資料',
      name: '姓名',
      dept: '系所',
      subject: '教授科目',
      email: '學校信箱',
      phone: '聯絡電話',
    },
  }
  if (lang === 'zh') {
    content = content.chinese
  } else if (lang === 'en') {
    content = content.english
  }

  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <>
      <Button size={sm ? 'sm' : ''} onClick={handleShow} variant='green' className={sm ? 'size-6' : ''}>
        {content.title}
      </Button>

      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className='text-grey size-3'>{content.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='size-4'>
          <Container>
            <Row className='mb-3'>
              <Col xs={3} className='text-grey'>
                {content.name}
              </Col>
              <Col xs={9}>{lang === 'zh' ? student.name : student.name_en}</Col>
            </Row>
            <Row className='mb-3'>
              <Col xs={3} className='text-grey'>
                {content.dept}
              </Col>
              <Col xs={9}>{lang === 'zh' ? student.dept : student.dept_en}</Col>
            </Row>
            <Row className='mb-3'>
              <Col xs={3} className='text-grey'>
                {content.email}
              </Col>
              <Col xs={9} className='text-blue'>
                {student.email}
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col xs={3} className='text-grey'>
                {content.phone}
              </Col>
              <Col xs={9} className='text-blue'>
                {student.tel}
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  )
}
