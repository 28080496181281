import axios from 'axios'
import Cookies from 'js-cookie'

export const USER_FAKE_LOGIN = 'USER_FAKE_LOGIN'
export const USER_LOGOUT = 'USER_LOGOUT'
export const SET_LOGIN_ROLE = 'SET_LOGIN_ROLE'
export const SET_LOGIN_TOKEN = 'SET_LOGIN_TOKEN'
export const SET_ISLOGIN = 'SET_ISLOGIN'
export const ERROR = 'ERROR'
export const RECEIVE_LOGIN_PROFILE = 'RECEIVE_LOGIN_PROFILE'
export const SET_LANG = 'SET_LANG'
const allRole = ['student', 'tutor', 'admin', 'dept_admin']

export function setLang(lang) {
  window.localStorage.setItem('lang', lang)
  return {
    type: SET_LANG,
    lang,
  }
}
export function setLoginRole(role) {
  return {
    type: SET_LOGIN_ROLE,
    role,
  }
}

async function checkAccess(token, role, dispatch, access) {
  await Promise.all(
    allRole.map(async (requestRole) => {
      if ((role !== requestRole && access[requestRole]) === undefined) {
        const res = await axios.get(`/en/prof/api/tokenChecknValidation/${token}/${requestRole}`).then((res) => {
          return res.data.status === 'OK'
        })
        dispatch({
          type: 'ADD_ROLE',
          role: requestRole,
          res,
        })
      }
    })
  )
}

export function setLoginRoleAndVerify(role, token) {
  return async (dispatch, getState) => {
    if (!token) return

    const r = getState().login.role
    const t = getState().login.token
    const access = getState().login.access
    let res
    try {
      res = await axios.get(`/en/prof/api/tokenChecknValidation/${token}/${role}`)
    } catch (err) {
      // console.log(err)
      dispatch(pop_alert('切換角色失敗', false))
      dispatch(userLogout())
      return
    }
    if (res.data.status === 'OK') {
      if (r !== role) dispatch(setLoginRole(role))
      if (t !== token)
        dispatch({
          type: SET_LOGIN_TOKEN,
          token,
        })
      dispatch(receiveProfile(res.data.result.result))
      window.localStorage.setItem('role', role)

      await checkAccess(token, role, dispatch, access)
    } else {
      alert('你沒有權限')
      dispatch(userLogout())
    }
  }
}

export function setLoginToken(token) {
  return async (dispatch, getState) => {
    const access = getState().login.access
    await checkAccess(token, null, dispatch, access)

    Cookies.remove('token')
    dispatch({
      type: SET_LOGIN_TOKEN,
      token,
    })
  }
}

export function setIsLogin(role) {
  return {
    type: SET_ISLOGIN,
    role,
  }
}

export function setError(msg) {
  return {
    type: ERROR,
    msg,
  }
}

function receiveProfile(res) {
  return {
    type: RECEIVE_LOGIN_PROFILE,
    data: res,
  }
}

export function fetchProfile(token) {
  return (dispatch) => {
    return axios
      .get('/en/prof/api/tokenChecknValidation/' + token)
      .then((res) => {
        if (res.data.status === 'OK') dispatch(receiveProfile(res.data.result.result))
        else {
          dispatch(userLogout())
          alert(res.data.result)
        }
      })
      .catch((e) => {
        // console.log(e)
        dispatch(userLogout())
        alert('登入錯誤')
      })
  }
}

function logoutSuccess() {
  Cookies.remove('token')
  Cookies.remove('special_token')
  window.localStorage.removeItem('role')
  return {
    type: USER_LOGOUT,
  }
}

export function userLogout() {
  return (dispatch, getState) => {
    if (process.env.NODE_ENV !== 'production') return dispatch(logoutSuccess())
    else if (getState().login.token)
      return axios
        .get('/en/prof/api/signout')
        .then((res) => {
          if (res.data.status === 'OK') {
            dispatch(logoutSuccess())
          } else {
            // console.log(res.data)
            alert(res.data.result)
          }
        })
        .catch((e) => {
          // console.log(e)
          alert('登出失敗')
        })
  }
}

const token = '239d4145c10005733320263865ac40b7'
export function userFakeLogin(role = 'student') {
  Cookies.set('special_token', token)
  return {
    type: USER_FAKE_LOGIN,
    login: {
      name: '',
      role,
      token: token,
    },
  }
}

// dashboard
export const RECEIVE_NEWS = 'RECEIVE_NEWS'
export const RECEIVE_FAQ = 'RECEIVE_FAQ'
export const RECEIVE_IMPORTANTDOCUMENT = 'RECEIVE_IMPORTANTDOCUMENT'
export const RECEIVE_NORMALDOCUMENT = 'RECEIVE_NORMALDOCUMENT'
export const RECEIVE_APPLICATIONDOCUMENT = 'RECEIVE_APPLICATIONDOCUMENT'
export const RECEIVE_SEMESTER = 'RECEIVE_SEMESTER'
export const RECEIVE_SUSPEND = 'RECEIVE_SUSPEND'
export const RECEIVE_TUTOR = 'RECEIVE_TUTOR'
export const RECEIVE_SUBJECT = 'RECEIVE_SUBJECT'
export const RECEIVE_DEPT = 'RECEIVE_DEPT'
export const POP_ALERT = 'POP_ALERT'

function receiveNews(res) {
  return {
    type: RECEIVE_NEWS,
    data: res,
  }
}

function receiveFaq(res) {
  return {
    type: RECEIVE_FAQ,
    data: res,
  }
}

function receiveNormaldocs(res) {
  return {
    type: RECEIVE_NORMALDOCUMENT,
    data: res,
  }
}

function receiveApplicationdocs(res) {
  return {
    type: RECEIVE_APPLICATIONDOCUMENT,
    data: res,
  }
}

function receiveImportantdocs(res) {
  return {
    type: RECEIVE_IMPORTANTDOCUMENT,
    data: res,
  }
}

function receiveSemester(res, current) {
  return {
    type: RECEIVE_SEMESTER,
    data: res,
    current,
  }
}

function receiveSuspend(res) {
  return {
    type: RECEIVE_SUSPEND,
    data: res,
  }
}

function receiveTutor(res) {
  return {
    type: RECEIVE_TUTOR,
    data: res,
  }
}

function receiveSubject(res) {
  return {
    type: RECEIVE_SUBJECT,
    data: res,
  }
}

function receiveDept(res) {
  return {
    type: RECEIVE_DEPT,
    data: res,
  }
}

export function pop_alert(msg, success = true) {
  return {
    type: POP_ALERT,
    msg,
    success,
  }
}

export function fetchNews(count = 99999, page = 1) {
  return (dispatch, getState) => {
    let token = getState().login.token
    return axios
      .get('/en/prof/api/getArticleList/' + token, {
        params: {
          count,
          page,
        },
      })
      .then((res) => {
        if (res.data.status === 'OK') dispatch(receiveNews(res.data.result.articleList))
        else dispatch(pop_alert('獲取最新消息列表失敗', false))
      })
      .catch((e) => {
        // console.log(e)
        dispatch(pop_alert('獲取最新消息列表失敗', false))
      })
  }
}

export function fetchFaq() {
  return (dispatch, getState) => {
    let token = getState().login.token
    return axios
      .get('/en/prof/api/getQnaList/' + token)
      .then((res) => {
        if (res.data.status === 'OK') dispatch(receiveFaq(res.data.result.qnaList))
        else dispatch(pop_alert('獲取FAQ列表失敗', false))
      })
      .catch((e) => {
        dispatch(pop_alert('獲取FAQ列表失敗', false))
      })
  }
}

export function fetchImportantdocs() {
  return (dispatch, getState) => {
    let token = getState().login.token
    return axios
      .get('/en/prof/api/getDownload_documentList/' + token + '?filter[type]=重要法規')
      .then((res) => {
        if (res.data.status === 'OK') dispatch(receiveImportantdocs(res.data.result.download_documentList))
        else dispatch(pop_alert('獲取重要法規列表失敗', false))
      })
      .catch((e) => {
        dispatch(pop_alert('獲取重要法規列表失敗', false))
      })
  }
}

export function fetchNormaldocs() {
  return (dispatch, getState) => {
    let token = getState().login.token
    return axios
      .get('/en/prof/api/getDownload_documentList/' + token + '?filter[type]=常用表單')
      .then((res) => {
        if (res.data.status === 'OK') dispatch(receiveNormaldocs(res.data.result.download_documentList))
        else dispatch(pop_alert('獲取常用表單列表失敗', false))
      })
      .catch((e) => {
        dispatch(pop_alert('獲取常用表單列表失敗', false))
      })
  }
}

export function fetchApplicationdocs() {
  return (dispatch, getState) => {
    let token = getState().login.token
    return axios
      .get('/en/prof/api/getDownload_documentList/' + token + '?filter[type]=各科系科目申請')
      .then((res) => {
        if (res.data.status === 'OK') dispatch(receiveApplicationdocs(res.data.result.download_documentList))
        else dispatch(pop_alert('獲取各科系科目申請列表失敗', false))
      })
      .catch((e) => {
        dispatch(pop_alert('獲取各科系科目申請列表失敗', false))
      })
  }
}

export function fetchSemester(title, everytime = true, count = 99999, page = 1) {
  return (dispatch, getState) => {
    let token = getState().login.token
    if (everytime || getState().db.semester.list.length === 0)
      return axios
        .get('/en/prof/api/getSemesterList/' + token, {
          params: {
            count,
            page,
            'filter[title]': title,
          },
        })
        .then((res) => {
          if (res.data.status === 'OK')
            dispatch(
              receiveSemester(
                res.data.result.semesterList.sort((a, b) => (a.title < b.title ? -1 : 1)),
                res.data.result.current_semester
              )
            )
          else dispatch(pop_alert('獲取學期列表失敗', false))
        })
        .catch((e) => {
          dispatch(pop_alert('獲取學期列表失敗', false))
        })
  }
}

export function fetchSuspend(count = 99999, page = 1) {
  return (dispatch, getState) => {
    let token = getState().login.token
    return axios
      .get('/en/prof/api/getClosetimeList/' + token, {
        params: {
          count,
          page,
        },
      })
      .then((res) => {
        if (res.data.status === 'OK') dispatch(receiveSuspend(res.data.result.closetimeList))
        else dispatch(pop_alert('獲取停課列表失敗', false))
      })
      .catch((e) => {
        dispatch(pop_alert('獲取停課列表失敗', false))
      })
  }
}

export function fetchTutor(name, account, count = 99999, page = 1) {
  return (dispatch, getState) => {
    let token = getState().login.token
    return axios
      .get('/en/prof/api/getTutorList/' + token, {
        params: {
          count,
          page,
          'filter[name]': name,
          'filter[account_no]': account,
        },
      })
      .then((res) => {
        if (res.data.status === 'OK') dispatch(receiveTutor(res.data.result.tutorList))
        else dispatch(pop_alert('獲取小老師列表失敗', false))
      })
      .catch((e) => {
        dispatch(pop_alert('獲取小老師列表失敗', false))
      })
  }
}

export function fetchSubject(count = 99999, page = 1) {
  return (dispatch, getState) => {
    let token = getState().login.token
    return axios
      .get('/en/prof/api/getSubjectList/' + token, {
        params: {
          count,
          page,
        },
      })
      .then((res) => {
        if (res.data.status === 'OK') dispatch(receiveSubject(res.data.result.subjectList))
        else dispatch(pop_alert(res.data.result, false))
      })
      .catch((e) => {
        dispatch(pop_alert('獲取科目列表失敗', false))
      })
  }
}

export function fetchDept(name, count = 99999, page = 1) {
  return (dispatch, getState) => {
    let token = getState().login.token
    return axios
      .get('/en/prof/api/getDept_adminList/' + token, {
        params: {
          count,
          page,
          'filter[name]': name,
        },
      })
      .then((res) => {
        if (res.data.status === 'OK') dispatch(receiveDept(res.data.result.dept_adminList))
        else dispatch(pop_alert(res.data.result, false))
      })
      .catch((e) => {
        dispatch(pop_alert('獲取科系列表失敗', false))
      })
  }
}
