import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useRouteMatch, Switch, Link } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Modal from 'react-bootstrap/Modal'
import Table from 'react-bootstrap/Table'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import FrontPagination from 'components/Pagination'
import PrivateRouter from 'private-route.js'
import axios from 'axios'

const BACKEND_API = process.env.REACT_APP_BACKEND_API

function ConsultRecordPage() {
  let { path } = useRouteMatch()
  const lang = useSelector((s) => s.front.lang)

  return (
    <div className='container mb-5' id='record'>
      <div className='absolute-bg'></div>
      <h1 className='size-1 my-5 text-left text-sm-center'>{lang === 'zh' ? '諮詢記錄' : 'Consult Records'}</h1>

      <Switch>
        <PrivateRouter exact path={path + '/student'} check_role='student' cate='special' Component={StudentTable} />
        <PrivateRouter path={path + '/tutor'} check_role='tutor' cate='special' Component={TutorTable} />
      </Switch>
    </div>
  )
}

export default ConsultRecordPage

function TutorTable() {
  const [data, setData] = useState([])
  const [semesterlist, setSemesterlist] = useState([])
  const [selected_semester, setSelected_semester] = useState({
    name: '全部學期',
    value: 'ALL',
  })
  const perPage = 5
  const [currentPage, setCurrentPage] = useState(0)
  const [pageCount, setPageCount] = useState(0)
  const [query, setQuery] = useState('')
  const token = useSelector((s) => s.login.token)

  const fetchData = () => {
    let url = `/en/prof/api/getTutorConsultationList/${token}/${perPage}/${currentPage + 1}`
    axios
      .get(url, {
        params: {
          'filter[semester_id]': selected_semester.value,
          'filter[student_name]': query,
        },
      })
      .then((res) => {
        if (res.data.status === 'OK') {
          const new_result = res.data.result
          // console.log(new_result.consultationList)
          setData(new_result.consultationList)
          const new_page_count = Math.ceil(parseInt(new_result.inlineCount) / perPage)
          setPageCount(new_page_count)
        } else {
          // console.log(res.data.result)
          alert('獲取資料失敗')
        }
      })
      .catch((err) => {
        // console.log(err)
        alert('獲取資料失敗')
      })
  }

  useEffect(() => {
    axios
      .get('/en/prof/api/getSemesterFrontList')
      .then((res) => {
        if (res.data.status === 'OK') {
          let new_s = res.data.result.semesterList.map((s) => ({
            name: s.title,
            id: s.semester_id,
          }))
          let { name, id } = new_s[new_s.length - 1]
          new_s.unshift({
            name: '全部學期',
            value: 'ALL',
          })
          setSemesterlist(new_s)
          setSelected_semester({ name, value: id })
        } else {
          // console.log(res.data.result)
          alert('獲取資料失敗')
        }
      })
      .catch((e) => {
        // console.log(e)
        alert('獲取資料失敗')
      })
  }, [token])

  const handleSelect = (e) => {
    let [name, value] = e.split(',')
    setSelected_semester({ name, value })
  }

  const handlePageClick = (e) => {
    const selectedPage = e.selected
    setCurrentPage(selectedPage)
  }

  const handleSearch = (e) => {
    e.preventDefault()
    if (currentPage) setCurrentPage(0)
    else fetchData()
  }

  useEffect(() => {
    fetchData()
  }, [selected_semester, currentPage])

  return (
    <>
      <Form.Row className='mb-3'>
        <Col xs={12} sm='auto' className='mb-1 mb-lg-0'>
          <div className='text-right h-100'>
            <DropdownButton
              title={selected_semester.name}
              className='h-100'
              bsPrefix='db-dropdown'
              onSelect={handleSelect}
            >
              {semesterlist.map((s) => (
                <Dropdown.Item eventKey={[s.name, s.id]}>{s.name}</Dropdown.Item>
              ))}
            </DropdownButton>
          </div>
        </Col>
        <Col xs={true} lg={5}>
          <Form.Control
            className='h-100'
            placeholder='請輸入姓名'
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        </Col>
        <Col xs={'auto'}>
          <Button variant='primary' bsPrefix='db-btn' onClick={handleSearch}>
            搜尋
          </Button>
        </Col>
        <Col xs={12} lg={true} className='mt-1 mt-lg-0'>
          <div className='text-right'>
            <Link to='/special/new-consult'>
              <Button variant='primary' bsPrefix='db-btn' className='px-3'>
                <i class='fas fa-plus-circle'></i> 新增諮詢紀錄
              </Button>
            </Link>
          </div>
        </Col>
      </Form.Row>

      <div className='mobile-table d-block d-lg-none size-6'>
        {data.length <= 0 ? '目前尚無申請記錄' : null}
        {data.map((d) => (
          <div className='mobile-table-item p-3'>
            <div className='d-flex justify-content-between'>
              <span className='text-bold'>日期</span>
              <span className='text-grey'>{d.date}</span>
            </div>
            <div className='d-flex justify-content-between'>
              <span className='text-bold'>時間</span>
              <span className='text-grey'>{`${d.start_time}-${d.end_time}`}</span>
            </div>
            <div className='d-flex justify-content-between'>
              <span className='text-bold'>學生姓名</span>
              <span className='text-grey'>
                <a href={'mailto:' + d.student_account_no + '@ntu.edu.tw'}>{d.student_name}</a>
              </span>
            </div>
            <div className='d-flex justify-content-between'>
              <span className='text-bold'>科目</span>
              <span className='text-grey'>{d.subject}</span>
            </div>
            <div className='d-flex justify-content-between'>
              <span className='text-bold'>回饋紀錄</span>
              <span className='text-grey'>
                {d.feedback_status == 1 ? (
                  <Button
                    size='sm'
                    variant='green'
                    href={`${BACKEND_API}/zh/tutorGetQuestionaireReply_prof/${d.consultation_id}/${token}`}
                    className='size-6'
                  >
                    查看回饋
                  </Button>
                ) : (
                  '未填寫'
                )}
              </span>
            </div>
            <div className='d-flex justify-content-between'>
              <span className='text-bold'>輔導紀錄</span>
              <span className='text-grey'>
                {d.cancel_status == 1 ? '-' : <ClassRecordModal sm data={d} fetchData={fetchData} />}
              </span>
            </div>
            <div className='d-flex justify-content-between'>
              <span className='text-bold'>取消紀錄</span>
              <span className='text-grey'>
                {d.cancel_status == 1 ? (
                  '已取消'
                ) : (
                  <>{d.feedback_status == 1 ? '-' : <CancelModal sm data={d} fetchData={fetchData} />}</>
                )}
              </span>
            </div>
          </div>
        ))}
      </div>

      <Table borderless striped className='text-grey text-center d-none d-lg-table'>
        <thead>
          <tr>
            <th scope='col'>日期</th>
            <th scope='col'>時間</th>
            <th scope='col'>學生姓名</th>
            <th scope='col'>科目</th>
            <th scope='col'>回饋紀錄</th>
            <th scope='col'>輔導紀錄</th>
            <th scope='col'>取消紀錄</th>
          </tr>
        </thead>
        <tbody className='reserve-record-tobdy'>
          {data.map((d) => (
            <tr>
              <td>{d.date}</td>
              <td>{`${d.start_time}-${d.end_time}`}</td>
              <td>
                <a href={'mailto:' + d.student_account_no + '@ntu.edu.tw'}>{d.student_name}</a>
              </td>
              <td>{d.subject}</td>
              <td>
                {d.feedback_status == 1 ? (
                  <Button
                    variant='green'
                    href={`${BACKEND_API}/zh/tutorGetQuestionaireReply_prof/${d.consultation_id}/${token}`}
                  >
                    查看回饋
                  </Button>
                ) : (
                  '未填寫'
                )}
              </td>
              {d.cancel_status == 1 ? (
                <>
                  <td>-</td>
                  <td>已取消</td>
                </>
              ) : (
                <>
                  <td>
                    <ClassRecordModal data={d} fetchData={fetchData} />
                  </td>
                  <td>{d.feedback_status == 1 ? '-' : <CancelModal data={d} fetchData={fetchData} />}</td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
      <FrontPagination currentPage={currentPage} pageCount={pageCount} onPageChange={handlePageClick} />
    </>
  )
}

function StudentTable() {
  const lang = useSelector((s) => s.front.lang)
  const token = useSelector((s) => s.login.token)

  let content = {
    english: {
      date: 'Date',
      time: 'Time',
      tutor: 'Tutor',
      subject: 'Subject',
      feedback: 'Feedback',
      write_fb: 'Enter Feedback',
      had_fb: 'Done',
      noData: 'There is no data',
    },
    chinese: {
      date: '日期',
      time: '時間',
      tutor: '小老師',
      subject: '科目',
      feedback: '回饋紀錄',
      write_fb: '填寫回饋',
      had_fb: '已填寫',
      noData: '目前尚無申請記錄',
    },
  }
  if (lang === 'zh') {
    content = content.chinese
  } else if (lang === 'en') {
    content = content.english
  }

  const perPage = 5
  const [currentPage, setCurrentPage] = useState(0)
  const [pageCount, setPageCount] = useState(0)
  const [data, setData] = useState([])

  const handlePageClick = (e) => {
    const selectedPage = e.selected
    setCurrentPage(selectedPage)
  }

  useEffect(() => {
    axios
      .get(`/en/prof/api/getStudentConsultationList/${token}/${perPage}/${currentPage + 1}`)
      .then((res) => {
        const new_result = res.data.result
        // console.log(new_result.consultationList)
        setData(new_result.consultationList)
        const new_page_count = Math.ceil(parseInt(new_result.inlineCount) / perPage)
        setPageCount(new_page_count)
      })
      .catch((err) => {
        // console.log(err)
      })
  }, [token, currentPage])

  return (
    <>
      <div className='mobile-table d-block d-lg-none size-6'>
        {data.length <= 0 ? content.noData : null}
        {data.map((d) => (
          <div className='mobile-table-item p-3'>
            <div className='d-flex justify-content-between'>
              <span className='text-bold'>{content.date}</span>
              <span className='text-grey'>{d.date}</span>
            </div>
            <div className='d-flex justify-content-between'>
              <span className='text-bold'>{content.time}</span>
              <span className='text-grey'>{`${d.start_time}-${d.end_time}`}</span>
            </div>
            <div className='d-flex justify-content-between'>
              <span className='text-bold'>{content.tutor}</span>
              <span className='text-grey'>{lang === 'zh' ? d.tutor_detail.name : d.tutor_detail.name_en}</span>
            </div>
            <div className='d-flex justify-content-between'>
              <span className='text-bold'>{content.subject}</span>
              <span className='text-grey'>{lang === 'zh' ? String(d.subjects_zh) : String(d.subjects_en)}</span>
            </div>
            <div className='d-flex justify-content-between'>
              <span className='text-bold'>{content.feedback}</span>
              <span className='text-grey'>
                {d.feedback_status == '0' ? (
                  <Button
                    size='sm'
                    variant='green'
                    href={`${BACKEND_API}/${lang}/professional_student_feedback/${d.consultation_id}/1/${token}`}
                    className='size-6'
                  >
                    {content.write_fb}
                  </Button>
                ) : (
                  content.had_fb
                )}
              </span>
            </div>
          </div>
        ))}
      </div>

      <Table borderless striped className='text-grey text-center d-none d-lg-table'>
        <thead>
          <tr>
            <th scope='col'>{content.date}</th>
            <th scope='col'>{content.time}</th>
            <th scope='col'>{content.tutor}</th>
            <th scope='col'>{content.subject}</th>
            <th scope='col'>{content.feedback}</th>
          </tr>
        </thead>
        <tbody className='reserve-record-tobdy'>
          {data.map((d) => (
            <tr>
              <td>{d.date}</td>
              <td>{`${d.start_time}-${d.end_time}`}</td>
              <td>{lang === 'zh' ? d.tutor_detail.name : d.tutor_detail.name_en}</td>
              <td>{lang === 'zh' ? String(d.subjects_zh) : String(d.subjects_en)}</td>
              <td>
                {d.feedback_status == '0' ? (
                  <Button
                    variant='green'
                    href={`${BACKEND_API}/${lang}/professional_student_feedback/${d.consultation_id}/1/${token}`}
                  >
                    {content.write_fb}
                  </Button>
                ) : (
                  content.had_fb
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <FrontPagination currentPage={currentPage} pageCount={pageCount} onPageChange={handlePageClick} />
    </>
  )
}

function CancelModal({ data, fetchData, sm }) {
  const [show, setShow] = useState(false)
  const token = useSelector((s) => s.login.token)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const cancelResv = () => {
    const body = {
      id: parseInt(data.consultation_id),
      token,
    }
    // console.log(body)
    axios
      .post('/en/prof/api/tutorCancelConsultation', body)
      .then((res) => {
        if (res.data.status === 'OK') {
          fetchData()
        } else {
          alert(res.data.result)
        }
        handleClose()
      })
      .catch((err) => {
        // console.log(err)
        alert('取消失敗')
      })
  }

  return (
    <>
      <Button size={sm ? 'sm' : ''} onClick={handleShow} variant='danger' className={sm ? 'size-6' : null}>
        取消紀錄
      </Button>

      <Modal centered show={show} onHide={handleClose}>
        <Modal.Body className='text-center size-4'>
          <p className='text-center'>確定要取消紀錄？</p>
          <hr />
          <Container>
            <Row>
              <Col onClick={handleClose} type='button' className='text-center py-3'>
                取消
              </Col>
              <Col onClick={cancelResv} type='button' className='text-center py-3 record-confirm'>
                確定
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  )
}

function ClassRecordModal({ data, fetchData, sm }) {
  const token = useSelector((s) => s.login.token)
  const [show, setShow] = useState(false)
  const [record, setRec] = useState(data.class_record || '')

  useEffect(() => {
    if (data.consultation_id) {
      setRec(data.class_record)
    }
  }, [data.consultation_id])

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const handleSubmit = () => {
    axios
      .post('/en/prof/api/tutorUpdateConsultationClassRecord', {
        id: parseInt(data.consultation_id),
        token,
        class_record: record,
      })
      .then((res) => {
        if (res.data.status === 'OK') {
          fetchData()
          handleClose()
        } else {
          // console.log(res.data)
          alert(res.data.result)
        }
      })
      .catch((e) => {
        // console.log(e)
        alert('更新失敗')
      })
  }

  return (
    <>
      <Button size={sm ? 'sm' : ''} onClick={handleShow} variant='green' className={sm ? 'size-6' : null}>
        {data.class_record === '' ? '填寫紀錄' : '編輯紀錄'}
      </Button>

      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className='text-grey size-3'>輔導紀錄</Modal.Title>
        </Modal.Header>
        <Modal.Body className='size-4'>
          <Form.Group>
            <Form.Control
              as='textarea'
              placeholder='請輸入輔導紀錄'
              className='radius-border'
              style={{
                background: 'rgba(159, 206, 205, 0.2)',
                border: '1px solid #9FCECD',
              }}
              rows={6}
              value={record}
              onChange={(e) => setRec(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <button
            className='submit-btn text-white w-100'
            onClick={handleSubmit}
            style={{ maxWidth: 'none' }}
            disabled={record === '' || record === data.class_record}
          >
            送出
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
