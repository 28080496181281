import { useSelector } from 'react-redux'

function TeamCard({ teacher }) {
  const lang = useSelector((s) => s.front.lang)

  const email_link = (email) => {
    if (email) {
      return (
        <a href={'mailto:' + email} className='d-inline'>
          <i class='fas fa-envelope text-light'></i>
        </a>
      )
    } else {
      return <div style={{ minHeight: '0.75rem' }} className='d-inline-block'></div>
    }
  }

  const subjects = lang === 'zh' ? teacher.tutor_subjects : teacher.tutor_subjects_en

  return (
    <div class='card'>
      <div className='card-top text-center pb-3'>
        <div className='circle-img mx-auto my-3'>
          <img
            src={
              teacher.avatar ||
              'https://images.pexels.com/photos/3777549/pexels-photo-3777549.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
            }
            class='card-img-top rounded-circle'
            alt='...'
          />
        </div>
        <p className='size-4 text-light m-1'>{lang === 'zh' ? teacher.name : teacher.name_en}</p>
        <p className='size-6 text-light m-1'>{lang === 'zh' ? teacher.dept : teacher.dept_en}</p>
        {email_link(teacher.email)} {email_link(teacher.email2)}
      </div>

      <div class='card-body'>
        {subjects?.map((c) => (
          <span class='badge badge-success text-white p-2 mb-3 mr-1'>{c}</span>
        ))}

        <p class='card-text'>{lang === 'zh' ? teacher.tutor_speciality_zh : teacher.tutor_speciality_en}</p>
        <hr />
        <p className='text-grey mb-1'>{lang === 'zh' ? '學生評價' : 'Student review'}</p>
        <p className='card-text mt-1'>{lang === 'zh' ? teacher.tutor_review_zh : teacher.tutor_review_en}</p>
      </div>
    </div>
  )
}

export default TeamCard
