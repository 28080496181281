import { useState, useEffect } from 'react'
import { Component } from 'react'
import { connect } from 'react-redux'
import { useSelector, useDispatch } from 'react-redux'
import { useRouteMatch, Switch } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import PrivateRoute from 'private-route.js'
import { fetchDefaultTimeSlot } from 'actions/basic/actions'
import axios from 'axios'
import { extend } from 'dayjs'

/*
-------------------
Order of the methods 
--------------------
componentWillMount()
set the initial state in the constructor
render()
componentDidMount()
setState()
render()

*/

class CheckinRecordPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      data: [],
      start_date: '',
      end_date: '',
      token: props.login.token,
      modalStatus: false,
      loadingshow: false,
      todayCheckin: {},
      checkinMode: 'in',
    }
    this.content = {
      english: {
        date: 'Date',
        starttime: 'Check-In Time',
        endtime: 'Check-Out Time',
        iwantcheck: 'Check-In',
        iwantcheckin: 'Check-In Now',
        iwantcheckout: 'Check-Out Now',
        search: 'Search',
        lastcheckin: 'Today Last Check-In Record',
        lastcheckintime: 'Check-In Time',
        lastcheckouttime: 'Check-Out Time',
        noRecord: 'No Record',
        checkinsuccess: 'Update Successfully !',
      },
      chinese: {
        date: '日期',
        starttime: '開始時間',
        endtime: '結束時間',
        iwantcheck: '我要打卡',
        iwantcheckin: '我要打卡上班',
        iwantcheckout: '我要打卡下班',
        search: '搜尋',
        lastcheckin: '今日最後打卡記錄',
        lastcheckintime: '打卡上班',
        lastcheckouttime: '打卡下班',
        noRecord: '沒有記錄',
        checkinsuccess: '打卡成功 ！',
      },
    }
  }

  fetchData = (startdate, enddate, count = 99999999, page = 1) => {
    setTimeout(() => {
      axios
        .get(`/en/api/getTutorCheckinRecordList/` + this.props.token + `/${count}/${page}`, {
          params: {
            'filter[startdate]': startdate,
            'filter[enddate]': enddate,
          },
        })
        .then((res) => {
          if (res.data.status === 'OK') {
            const new_data = res.data.result.dataList
            this.setState({
              data: new_data,
              todayCheckin: res.data.result.todaycheckin,
              checkinMode: res.data.result.checkinMode,
              end_date: res.data.result.enddate,
              start_date: res.data.result.startdate,
            })
          } else {
            // console.log(res.data.result)
            alert('獲取資料失敗')
          }
        })
        .catch((e) => {
          // console.log(e)
          alert('獲取資料失敗')
        })
    }, 300)
  }

  handleSearch = (e) => {
    e.preventDefault()
    this.fetchData(this.state.start_date, this.state.end_date)
  }

  handleChange = (e) => {
    let col = e.target.name
    this.setState({ [col]: e.target.value })
  }

  handleModal = () => {
    this.setState({ modalStatus: !this.state.modalStatus })
  }

  componentDidMount() {
    this.fetchData()
  }

  handleCheckin = (e) => {
    e.preventDefault()
    let BACKEND_API = process.env.REACT_APP_BACKEND_API

    let lang = this.props.lang
    let content
    if (lang === 'zh') {
      content = this.content.chinese
    } else if (this.props.lang === 'en') {
      content = this.content.english
    }
    this.setState({ loadingshow: !this.state.loadingshow })
    const body = {
      token: this.props.login.token,
      checkinMode: this.state.checkinMode,
      todayCheckin: this.state.todayCheckin,
    }
    axios
      .post(BACKEND_API + '/en/api/tutorCheckinNow', body)
      .then((res) => {
        this.setState({ loadingshow: !this.state.loadingshow })
        if (res.data.status === 'OK') {
          this.setState({ modalStatus: !this.state.modalStatus })
          this.fetchData()
          alert(content.checkinsuccess)
        } else {
          // console.log(res.data.result)
          alert(res.data.result)
        }
      })
      .catch((e) => {
        this.setState({ loadingshow: !this.state.loadingshow })
        // console.log(e)
        alert('發生錯誤')
      })
  }

  render() {
    let lang = this.props.lang
    let content
    if (lang === 'zh') {
      content = this.content.chinese
    } else if (this.props.lang === 'en') {
      content = this.content.english
    }
    return (
      <div className='container mb-5' id='record'>
        <div className='absolute-bg'></div>
        <h1 className='size-1 my-5 text-left text-sm-center'>{lang === 'zh' ? '打卡記錄' : 'Check-In records'}</h1>

        <Form.Row className='mb-3'>
          <Col xs={true} sm={3}>
            <Form.Control
              type='date'
              onChange={this.handleChange}
              value={this.state.start_date}
              name='start_date'
              placeholder={content.starttime}
            ></Form.Control>
          </Col>
          <Col xs={true} sm={3}>
            <Form.Control
              type='date'
              onChange={this.handleChange}
              value={this.state.end_date}
              name='end_date'
              placeholder={content.endtime}
            ></Form.Control>
          </Col>
          <Col xs={'auto'} sm='auto'>
            <Button variant='primary' bsPrefix='db-btn' onClick={this.handleSearch}>
              {content.search}
            </Button>
          </Col>
          <Col xs={12} sm={true}>
            <div className='text-right h-100 mt-2 mt-sm-0'>
              <Button variant='primary' onClick={this.handleModal}>
                {content.iwantcheck}
              </Button>
            </div>
          </Col>
        </Form.Row>
        <div className='mobile-table d-block d-lg-none size-6'>
          {this.state.data.length <= 0 ? content.noData : null}
          {this.state.data.map((d, i) => (
            <div className='mobile-table-item p-3' key={i}>
              <div className='d-flex justify-content-between'>
                <span className='text-bold'>{content.date}</span>
                <span className='text-grey'>{d.date}</span>
              </div>
              <div className='d-flex justify-content-between'>
                <span className='text-bold'>{content.starttime}</span>
                <span className='text-grey'>{d.start_time}</span>
              </div>
              <div className='d-flex justify-content-between'>
                <span className='text-bold'>{content.endtime}</span>
                <span className='text-grey'>{d.end_time}</span>
              </div>
            </div>
          ))}
        </div>

        <Table borderless striped className='text-grey text-center d-none d-lg-table'>
          <thead>
            <tr>
              <th scope='col'>{content.date}</th>
              <th scope='col'>{content.starttime}</th>
              <th scope='col'>{content.endtime}</th>
              <th scope='col'></th>
            </tr>
          </thead>
          <tbody className='reserve-record-tobdy'>
            {this.state.data.map((d, i) => (
              <tr key={i}>
                <td>{d.date}</td>
                <td>{d.start_time}</td>
                <td>{d.end_time}</td>
                <td></td>
                <td></td>
              </tr>
            ))}
            {this.state.data.length == 0 && (
              <tr>
                <td colSpan='3'>
                  <h5>
                    <strong>-- {content.noRecord} --</strong>
                  </h5>
                </td>

                <td></td>
              </tr>
            )}
          </tbody>
        </Table>

        <Modal centered show={this.state.modalStatus} onHide={this.handleModal}>
          <Modal.Header closeButton>
            <Modal.Title className='text-grey size-3'>{content.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body className='size-4'>
            <Container>
              <h4 class='text-center'>{content.lastcheckin}</h4>
              <p>
                {content.lastcheckintime}:{' '}
                {this.state.todayCheckin ? this.state.todayCheckin.start_time : content.noRecord}
                <br />
                {content.lastcheckouttime}:{' '}
                {this.state.todayCheckin ? this.state.todayCheckin.end_time : content.noRecord}
                <br />
              </p>

              <hr />
              <div align='center' class='mt-4 mb-4'>
                <Button variant='primary' onClick={this.handleCheckin}>
                  {this.state.checkinMode == 'in' ? content.iwantcheckin : content.iwantcheckout}{' '}
                  {this.state.loadingshow && <i class='fa fa-spin fa-spinner'></i>}
                </Button>
              </div>
            </Container>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
    lang: state.front.lang,
    token: state.login.token,
  }
}

export default connect(mapStateToProps)(CheckinRecordPage)
