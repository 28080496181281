import { Suspense, lazy } from 'react'
import SpecialPage from 'containers/special_page/main/special_page'
import { Switch, Route } from 'react-router-dom'
import { Provider } from 'react-redux'
import { special_store } from 'store/special/configureStore'
import PrivateRoute from 'private-route.js'
import SetTokenFromCookie from 'set-token.js'
const SpecialDBPage = lazy(() => import('containers/special_db/main/special_db'))

function Special() {
  return (
    <Switch>
      <Provider store={special_store}>
        <SetTokenFromCookie cate='special' />
        <Suspense fallback={<div>載入中...</div>}>
          <PrivateRoute path='/special_db' check_role='admin' cate='special' Component={SpecialDBPage} />
        </Suspense>
        <Route path='/special' component={SpecialPage} />
      </Provider>
    </Switch>
  )
}

export default Special
