import { Switch, Route } from 'react-router-dom'
import Footer from 'components/main_page/main_page_footer.js'
import BasicPageNavbar from 'containers/basic_page/main/navbar.js'
import NewsPage from 'containers/basic_page/news/news_page.js'
import NewsShow from 'components/basic_page/news/news_show.js'
import ServicePage from 'containers/basic_page/service/service_page.js'
import QAPage from 'containers/basic_page/question/question_page.js'
import ContactPage from 'containers/basic_page/contact/contact_page.js'
import SchedulePage from 'containers/basic_page/schedule/schedule_page'
import ReservePage from 'containers/basic_page/reserve/reserve_page'
import ReserveRecordPage from 'containers/basic_page/reserve/reserve_record_page'
import ReserveHistoryPage from 'containers/basic_page/reserve/reserve_history_page'
import CheckinRecordPage from 'containers/basic_page/checkin/checkin_record_page'
import ProfilePage from 'containers/basic_page/profile/profile_page'
import BasicPageIndex from 'containers/basic_page/main/index'
import PrivateRoute from 'private-route.js'
import CheckinChecked from 'containers/basic_page/main/checkin_checked'
import DownloadPage from 'containers/basic_page/download/download_page'

function BasicPage({ match }) {
  return (
    <div>
      <BasicPageNavbar match={match} />

      <Switch>
        <Route exact path={`${match.path}/news`} component={({ match }) => <NewsPage match={match} />} />
        <Route path={`${match.path}/news/:id`} component={({ match }) => <NewsShow match={match} />} />
        <Route path={`${match.path}/question`} component={({ match }) => <QAPage match={match} />} />
        <Route path={`${match.path}/schedule`} component={({ match }) => <SchedulePage match={match} />} />
        <Route path={`${match.path}/contact`} component={({ match }) => <ContactPage match={match} />} />
        <PrivateRoute path={`${match.path}/reserve`} check_role='student' Component={ReservePage} />
        <Route path={`${match.path}/record`} component={({ match }) => <ReserveRecordPage match={match} />} />
        <Route path={`${match.path}/checkin`} component={({ match }) => <CheckinRecordPage match={match} />} />
        <Route path={`${match.path}/history`} component={({ match }) => <ReserveHistoryPage match={match} />} />
        <Route path={`${match.path}/service`} component={ServicePage} />
        <Route path={`${match.path}/download/:type`} render={({ match }) => <DownloadPage match={match} />} />
        <PrivateRoute path={`${match.path}/profile`} check_role='tutor' cate='basic' Component={ProfilePage} />

        <Route exact path={match.path} component={({ match }) => <BasicPageIndex match={match} />} />
      </Switch>
      <CheckinChecked />
      <Footer />
    </div>
  )
}

export default BasicPage
