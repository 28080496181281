import 'other.css'
import { Switch, Route } from 'react-router-dom'
import Footer from 'components/main_page/main_page_footer.js'
import PageNavbar from 'containers/special_page/main/navbar.js'
import NewsPage from 'containers/special_page/news/news_page.js'
import NewsShow from 'components/special_page/news/news_show.js'
import ServicePage from 'containers/special_page/service/service_page.js'
import DownloadPage from 'containers/special_page/download/download_page.js'
import QAPage from 'containers/special_page/question/question_page.js'
import ContactPage from 'containers/special_page/contact/contact_page.js'
import ApplyPage from 'containers/special_page/apply/apply_page'
import ApplyRecord from 'containers/special_page/apply/record'
import ApplyRecordShow from 'containers/special_page/apply/apply_show.js'
import TutorList from 'containers/special_page/tutor/tutor_list.js'
import TutorSalary from 'containers/special_page/tutor/salary.js'
import ConsultRecordPage from 'containers/special_page/consult/record_page'
import NewConsultPage from 'containers/special_page/consult/new_record'
import PageIndex from 'containers/special_page/main/index'
import PrivateRoute from 'private-route.js'

function BasicPage({ match }) {
  return (
    <div>
      <PageNavbar match={match} />

      <Switch>
        <Route exact path={`${match.path}/news`} render={({ match }) => <NewsPage match={match} />} />
        <Route path={`${match.path}/news/:id`} render={({ match }) => <NewsShow match={match} />} />
        <Route path={`${match.path}/question`} render={({ match }) => <QAPage match={match} />} />
        <Route path={`${match.path}/service`} render={({ match }) => <ServicePage match={match} />} />
        <Route path={`${match.path}/download/:type`} render={({ match }) => <DownloadPage match={match} />} />
        <Route path={`${match.path}/contact`} render={({ match }) => <ContactPage match={match} />} />
        <Route path={`${match.path}/record`} render={() => <ConsultRecordPage />} />
        <PrivateRoute path={`${match.path}/new-consult`} check_role='tutor' cate='special' Component={NewConsultPage} />
        <PrivateRoute path={`${match.path}/apply`} check_role='dept_admin' cate='special' Component={ApplyPage} />
        <PrivateRoute
          path={`${match.path}/apply-record/:id`}
          check_role='dept_admin'
          cate='special'
          Component={ApplyRecordShow}
        />
        <PrivateRoute
          path={`${match.path}/apply-record`}
          check_role='dept_admin'
          cate='special'
          Component={ApplyRecord}
        />
        <PrivateRoute path={`${match.path}/tutor`} check_role='dept_admin' cate='special' Component={TutorList} />
        <PrivateRoute path={`${match.path}/salary`} check_role='tutor' cate='special' Component={TutorSalary} />

        <Route exact path={match.path} render={({ match }) => <PageIndex match={match} />} />
      </Switch>

      <Footer />
    </div>
  )
}

export default BasicPage
