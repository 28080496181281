import { Route } from 'react-router-dom'
import Cookies from 'js-cookie'
import { useSelector, useDispatch } from 'react-redux'
import { setLoginRoleAndVerify as basicSet } from 'actions/basic/actions'
import { setLoginRoleAndVerify as specialSet } from 'actions/special/actions'
import { setLoginRoleAndVerify as individualSet } from 'actions/individual/actions'

function PrivateRoute({ Component, cate, check_role, ...rest }) {
  const role = useSelector((s) => s.login.role)
  const token = useSelector((s) => s.login.token)
  let authed = token !== '' && role === check_role

  return (
    <Route
      {...rest}
      render={(props) => (authed ? <Component {...props} /> : <Auth cate={cate} check_role={check_role} />)}
    />
  )
}

export default PrivateRoute

function Auth({ cate, check_role }) {
  const role = useSelector((s) => s.login.role)
  const token = useSelector((s) => s.login.token)
  const dispatch = useDispatch()
  let text = '認證中...'
  let tokenKey
  if (cate === 'basic') tokenKey = 'basic_token'
  else if (cate === 'special') tokenKey = 'special_token'
  else if (cate === 'individual') tokenKey = 'individual_token'

  // console.log({ token, role, check_role })

  if (token === '' && !Cookies.get('token') && !Cookies.get(tokenKey)) {
    text = '跳轉登入頁面...'
    goLogin(check_role, cate)
  } else if (token !== '') {
    if (role !== check_role) {
      if (cate === 'basic') dispatch(basicSet(check_role, token))
      else if (cate === 'special') dispatch(specialSet(check_role, token))
      else if (cate === 'individual') dispatch(individualSet(check_role, token))
    } else {
      text = '跳轉登入頁面...'
      goLogin(check_role, cate)
    }
  }

  return <div>{text}</div>
}

function goLogin(role, cate) {
  let c = ''
  if (cate === 'special') c = '/prof'
  else if (cate === 'individual') c = '/spcl'

  if (process.env.NODE_ENV === 'production')
    window.location.href = `${process.env.REACT_APP_BACKEND_API}/en${c}/api/signin/${role}?to=${
      window.location.href.split(/[?#]/)[0]
    }?role=${role}`
  else
    alert(
      `redirect to: ${process.env.REACT_APP_BACKEND_API}/en${c}/api/signin/${role}?to=${
        window.location.href.split(/[?#]/)[0]
      }?role=${role}`
    )
}
