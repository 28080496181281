import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setLang } from 'actions/basic/actions'

function SmallNavbar(props) {
  const dispatch = useDispatch()
  const lang = useSelector((s) => s.front.lang)
  const role = useSelector((s) => s.login.role)
  const userdata = useSelector((s) => s.login.data)
  const usertoken = useSelector((s) => s.login.token)
  //console.log(userdata);
  let content = {
    english: {
      reserve: 'Online Reservation',
      reserve_record: 'Reservation Records',
      checkin: 'Check-In',
      history: 'Tutoring Records',
      class_history: 'Tutoring Feedbacks',
      index: 'Home',
      news: 'News',
      service: 'About the Service',
      schedule: 'Course Schedule',
      qna: 'FAQ',
      contact: 'Contact Us',
      download_important: 'Regulations',
      download_normal: 'Form Download',
      eval: 'End-of-term Evaluation',
    },
    chinese: {
      reserve: '線上預約',
      reserve_record: '預約紀錄',
      checkin: '打卡',
      history: '歷史紀錄',
      class_history: '輔導紀錄',
      index: '首頁',
      news: '最新消息',
      service: '關於服務',
      schedule: '課表',
      qna: '常見問題',
      download_important: '重要法規',
      download_normal: '線上教材',
      contact: '聯絡我們',
      eval: '期末意見調查',
    },
  }

  if (lang === 'zh') {
    content = content.chinese
  } else if (lang === 'en') {
    content = content.english
  }

  let navItem = [];
  let evalNav = [];
  if (role === 'tutor') {
    navItem = [
      { path: 'record/tutor', text: content.reserve_record },
      { path: 'history/tutor', text: content.history },
      { path: 'checkin/checkin_record', text: content.checkin },
    ]
   
  } else if (role === 'student') {
    navItem = [
      { path: 'reserve', text: content.reserve },
      { path: 'record/student', text: content.reserve_record },
      { path: 'history/student', text: content.class_history },
    ]
  
  }
  if(userdata.startReponse && userdata.questionnaire_array[role]){
    evalNav.push({ path:  `${process.env.REACT_APP_BACKEND_API}/zh/feedback/${userdata.questionnaire_array[role]}/${usertoken}`, text: content.eval },);
  }
  //console.log(navItem);
  return (
    <div className={'side-navbar' + (props.open ? '' : ' d-none')}>
      <div className='side-bg' onClick={props.smallNavbarClick}></div>
      <div className='side-navbar-area'>
        <div className='side-navbar-text-area text-right'>
          <div className='lang text-bold'>
            <div
              className={'mx-2 lang-option d-inline ' + (lang === 'zh' ? 'lang-active' : '')}
              type='button'
              onClick={() => dispatch(setLang('zh'))}
            >
              中文
            </div>
            |
            <div
              className={'mx-2 lang-option d-inline ' + (lang === 'en' ? 'lang-active' : '')}
              type='button'
              onClick={() => dispatch(setLang('en'))}
            >
              EN
            </div>
          </div>

          <ul className='navbar-nav pt-2 pr-3'>
            {navItem.map((i) => (
              <li className='nav-item text-bold py-3'>
                <Link to={`${props.match.path}/${i.path}`} onClick={props.smallNavbarClick}>
                  {i.text}
                </Link>
              </li>
            ))}
             {evalNav.map((i) => (
              <li className='nav-item text-bold py-3'>
                <a href={`${i.path}`} onClick={props.smallNavbarClick}>
                  {i.text}
                </a>
              </li>
            ))}
            <hr />
           
            <li className='nav-item text-bold py-3'>
              <Link to={props.match.path} onClick={props.smallNavbarClick}>
                {content.index}
              </Link>
            </li>
            <li className='nav-item text-bold py-3'>
              <Link to={`${props.match.path}/news`} onClick={props.smallNavbarClick}>
                {content.news}
              </Link>
            </li>
            <li className='nav-item text-bold py-3'>
              <Link to={`${props.match.path}/service`} onClick={props.smallNavbarClick}>
                {content.service}
              </Link>
            </li>
            <li className='nav-item text-bold py-3'>
              <Link to={`${props.match.path}/schedule`} onClick={props.smallNavbarClick}>
                {content.schedule}
              </Link>
            </li>
            <li className='nav-item text-bold py-3'>
              <Link to={`${props.match.path}/question`} onClick={props.smallNavbarClick}>
                {content.qna}
              </Link>
            </li>
            <li className='nav-item text-bold py-3'>
              <Link to={`${props.match.path}/contact`} onClick={props.smallNavbarClick}>
                {content.contact}
              </Link>
            </li>
            <li className='nav-item text-bold py-3'>
              <Link to={`${props.match.path}/download/regulation`} onClick={props.smallNavbarClick}>
                {content.download_important}
              </Link>
            </li> <li className='nav-item text-bold py-3'>
              <Link to={`${props.match.path}/download/form`} onClick={props.smallNavbarClick}>
                {content.download_normal}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default SmallNavbar
