function MainPageCard({ img_src, text }) {
  return (
    <div className='bg-white px-3 pt-3 my-md-5 px-md-5 pt-md-5 pb-md-2 main-page-card'>
      <img src={img_src} alt='' />
      <h2 className='text-center main-page-text pb-4 py-sm-4'>{text}</h2>
    </div>
  )
}

export default MainPageCard
