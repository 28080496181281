import { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import dayjs from 'dayjs'
import dayOfYear from 'dayjs/plugin/dayOfYear'
import axios from 'axios'
dayjs.extend(dayOfYear)

class ReservePage extends Component {
  initState = {
    tel: '',
    email: '',
    college: '',
    semester: '',
    std_id: '',
    std_account_no: '',
    std_email: '輸入學號後自動顯示',
    std_name: '輸入學號後自動顯示',
    std_type: '輸入學號後自動顯示',
    std_tel: '',
    subject: '',
    plan: '',
    duration: '',
    check: '',
    step: 1,
    isLoading: false,
    tnc: false,
  }
  constructor(props) {
    super(props)
    this.state = this.initState
  }
  fetchSemester = () => {
    axios
      .get('/en/spcl/api/getSemesterFrontList')
      .then((res) => {
        if (res.data.status === 'OK') {
          const today = dayjs()
          let semesterList = res.data.result.semesterList
          let new_s = semesterList.find((s) => today.isAfter(s.start_date) && today.isBefore(s.end_date))
          new_s = new_s ? new_s : semesterList[semesterList.length - 1]
          this.setState({ semester: new_s })
        } else {
          // console.log(res.data.result)
          alert('獲取資料失敗')
        }
      })
      .catch((e) => {
        // console.log(e)
        alert('獲取資料失敗')
      })
  }

  componentDidMount() {
    this.fetchSemester()
  }

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value })
  }
  nextStep = (step) => {
    this.setState({ step: step + 1 })
  }
  handleSubmit = async (e) => {
    e.preventDefault()

    const body = {
      mode: 'Add',
      token: this.props.login.token,
      semester_id: parseInt(this.state.semester.semester_id),
      subject: this.state.subject,
      plan: this.state.plan,
      tel: this.state.tel,
      duration: this.state.duration,
      student_id: parseInt(this.state.std_id),
      student_account_no: this.state.std_account_no,
      student_tel: this.state.std_tel,
    }
    // console.log(body)
    this.setState({ isLoading: true })
    axios
      .post('/en/spcl/api/lecturerApplicationSubmit', body)
      .then((res) => {
        if (res.data.status === 'OK') {
          this.nextStep(2)
        } else {
          // console.log(res.data.result)
          alert(res.data.result)
        }
      })
      .catch((e) => {
        // console.log(e)
        alert('發生錯誤')
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }

  getStdName = (e) => {
    const account_no = e.target.value
    if (account_no !== '')
      axios
        .get(`/en/spcl/api/checkStudentDetail/${account_no}/${this.props.token}`)
        .then((res) => {
          if (res.data.status === 'OK') {
            // console.log(res.data.result.studentData)
            this.setState({
              std_name: res.data.result.studentData.name,
              std_email: res.data.result.studentData.email,
              std_type: res.data.result.studentData.category,
              std_id: res.data.result.studentData.user_id,
            })
          } else {
            this.setState({
              std_name: res.data.result,
              std_email: res.data.result,
              std_type: res.data.result,
              std_account_no: '',
            })
          }
        })
        .catch((e) => {
          // console.log(e)
          alert('發生錯誤')
        })
  }

  reset = () => {
    this.setState((s) => ({
      ...this.initState,
      semester: s.semester,
    }))
  }
  render() {
    return (
      <div className='container mb-5'>
        <div className='absolute-bg'></div>
        <h1 className='size-1 my-5 text-left text-sm-center'>線上申請</h1>

        <div className={'p-2 p-sm-5 bg-white reserve-box size-5' + (this.state.step !== 1 ? ' d-none' : '')}>
          <h2 className='size-4 text-center mb-2 mb-sm-5'>請填寫您的聯絡資料</h2>

          <Form.Group as={Row} controlId='name'>
            <Form.Label column sm='2'>
              教師
            </Form.Label>
            <Col sm='10'>
              <Form.Control plaintext readOnly defaultValue={this.props.login.data.name} />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId='college'>
            <Form.Label column sm='2'>
              學院
            </Form.Label>
            <Col sm='10'>
              <Form.Control plaintext readOnly defaultValue={this.props.login.data.degree} />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId='dept'>
            <Form.Label column sm='2'>
              學系
            </Form.Label>
            <Col sm='10'>
              <Form.Control plaintext readOnly defaultValue={this.props.login.data.dept} />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId='email'>
            <Form.Label column sm='2'>
              信箱
            </Form.Label>
            <Col sm='10'>
              <Form.Control plaintext readOnly defaultValue={this.props.login.data.email} />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId='tel'>
            <Form.Label column sm='2'>
              電話號碼
            </Form.Label>
            <Col sm='10'>
              <Form.Control
                onChange={this.handleChange}
                value={this.state.tel}
                required
                type='phone'
                placeholder='例：(02)0000-0000 #123'
              />
            </Col>
          </Form.Group>

          <div className='text-center'>
            <button className='submit-btn text-white' onClick={() => this.nextStep(1)} disabled={!this.state.tel}>
              下一步
            </button>
          </div>
        </div>

        <div className={'p-2 p-sm-5 bg-white reserve-box size-5' + (this.state.step !== 2 ? ' d-none' : '')}>
          <h2 className='size-4 text-center mb-2 mb-sm-5'>請徵得學生同意後方得填寫資料</h2>

          <Form.Group as={Row} controlId='semester'>
            <Form.Label column sm='2'>
              學期
            </Form.Label>
            <Col sm='10'>
              <Form.Control plaintext readOnly defaultValue={this.state.semester.title} />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId='std_account_no'>
            <Form.Label column sm='2'>
              學生學號
            </Form.Label>
            <Col sm='10'>
              <Form.Control
                onChange={this.handleChange}
                value={this.state.std_account_no}
                required
                type='text'
                placeholder='請輸入學生學號'
                onBlur={this.getStdName}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId='std_name'>
            <Form.Label column sm='2'>
              學生姓名
            </Form.Label>
            <Col sm='10'>
              <Form.Control plaintext defaultValue='輸入學號後自動顯示' value={this.state.std_name} readOnly />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId='std_type'>
            <Form.Label column sm='2'>
              學生身份
            </Form.Label>
            <Col sm='10'>
              <Form.Control plaintext defaultValue='輸入學號後自動顯示' value={this.state.std_type} readOnly />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId='std_email'>
            <Form.Label column sm='2'>
              學生信箱
            </Form.Label>
            <Col sm='10'>
              <Form.Control plaintext defaultValue='輸入學號後自動顯示' value={this.state.std_email} readOnly />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId='std_tel'>
            <Form.Label column sm='2'>
              學生電話
            </Form.Label>
            <Col sm='10'>
              <Form.Control
                onChange={this.handleChange}
                value={this.state.std_tel}
                required
                type='phone'
                placeholder='例：0987-654-321'
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId='subject'>
            <Form.Label column sm='2'>
              申請科目
            </Form.Label>
            <Col sm='10'>
              <Form.Control
                onChange={this.handleChange}
                value={this.state.subject}
                required
                type='text'
                placeholder='請輸入小老師預計輔導的科目全稱'
              />
              <Form.Text className='text-muted'>科目之間請以全形或半形逗號隔開</Form.Text>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId='plan'>
            <Form.Label column sm='2'>
              輔導規劃
            </Form.Label>
            <Col sm='10'>
              <Form.Control
                onChange={this.handleChange}
                value={this.state.plan}
                required
                type='text'
                as='textarea'
                placeholder='請輸入您預計的輔導規劃'
              />
              <Form.Text className='text-muted'>請說明為何需要針對該科目開設課業輔導</Form.Text>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId='duration'>
            <Form.Label column sm='2'>
              小老師預計聘任期程
            </Form.Label>
            <Col sm='10'>
              <Form.Control as='select' onChange={this.handleChange} value={this.state.duration}>
                <option disabled selected hidden value=''>
                  請選擇預計聘任期程
                </option>
                {Array.from(Array(Math.round(dayjs().endOf('year').diff(dayjs(), 'month', true)))).map((y, idx) => (
                  <option value={`${idx + 1} 個月`}>{idx + 1} 個月</option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>

          <Row>
            <Col sm='2'>
            </Col>
            <Col className='text-LEFT' sm={10}>
              <div>
                <input
                  class='fcustom-control-label'
                  type='checkbox'
                  value='1'
                  id='invalidCheck'
                  required
                  onClick={(e) => this.setState({ check: e.target.checked })}
                  // onChange={(e) => console.log(e.target.checked)}
                ></input>
                <label class='size-4 text-right mb-2 ' for='invalidCheck'>
                  我已徵得受輔學生同意以申請個案諮詢輔導專案
                </label>
                <div class='invalid-feedback'>需同意才能送出此表單</div>
              </div>
              <div>
                <input
                  class='fcustom-control-label'
                  type='checkbox'
                  value='1'
                  id='invalidCheck2'
                  required
                  onClick={(e) => this.setState({ tnc: e.target.checked })}
                  // onChange={(e) => console.log(e.target.checked)}
                ></input>
                <label class='size-4 text-right mb-2 mb-sm-5' for='invalidCheck2'>
                  我已詳閱 <Link to='/individual/download' target="_blank"><u>個案學習諮詢計劃實施要點</u></Link> 與 <Link to='/individual/download' target="_blank"><u>行政作業須知</u></Link>
                </label>
                <div class='invalid-feedback'>需同意才能送出此表單</div>
              </div>
            </Col>

          </Row>


          <Row>
            <Col className='text-right' xs={6}>
              <button className='submit-btn text-white' onClick={() => this.nextStep(0)}>
                上一步
              </button>
            </Col>
            <Col className='text-left' xs={6}>
              <button
                className='submit-btn text-white'
                onClick={this.handleSubmit}
                disabled={
                  !(
                    this.state.semester &&
                    this.state.subject &&
                    this.state.std_account_no &&
                    this.state.std_tel &&
                    this.state.duration &&
                    this.state.plan &&
                    this.state.check &&
                    this.state.tnc
                  ) || this.state.isLoading
                }
              >
                {this.state.isLoading ? '請稍等' : '送出'}
              </button>
            </Col>
          </Row>
        </div>

        <div
          className={'p-2 p-sm-5 bg-white reserve-box size-5 text-center' + (this.state.step !== 3 ? ' d-none' : '')}
        >
          <i class='fas fa-check reserve-success-icon'></i>

          <p className='size-4 my-3'>如有進一步消息會儘速通知您</p>

          <div>
            <Link to='/individual/apply-record'>
              <Button variant='orange'>查詢申請紀錄</Button>
            </Link>
          </div>
          <div className='mt-3'>
            <Button variant='orange' onClick={this.reset}>
              繼續申請
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
    token: state.login.token,
  }
}

export default connect(mapStateToProps)(ReservePage)
