// import { createStore, applyMiddleware, compose } from 'redux'
// import { createLogger } from 'redux-logger'
import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import rootReducer from '../../reducers/individual/reducers'

// const loggerMiddleware = createLogger()

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

function configureBasicStore(preloadedState) {
  return createStore(
    rootReducer,
    preloadedState,
    applyMiddleware(thunkMiddleware)
    // composeEnhancers(applyMiddleware(thunkMiddleware, loggerMiddleware))
  )
}

export const individual_store = configureBasicStore()
