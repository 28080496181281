import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Table from 'react-bootstrap/Table'
import { Link, useParams } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import axios from 'axios'

function RecordPage() {
  const token = useSelector((s) => s.login.token)
  const { id } = useParams()
  const [data, setData] = useState({})

  useEffect(() => {
    axios
      .get(`/en/spcl/api/getLecturerApplicationDetail/${id}/${token}`)
      .then((res) => {
        if (res.data.status === 'OK') {
          // console.log(res.data.result.detail)
          setData(res.data.result.detail)
        } else {
          // console.log(res.data)
          alert(res.data.result)
        }
      })
      .catch((err) => {
        // console.log(err)
        alert('發生錯誤')
      })
  }, [token])

  return (
    <div className='container mb-5'>
      <div className='absolute-bg'></div>
      <h1 className='size-1 my-5 text-left text-sm-center'>申請紀錄詳情</h1>

      <Table striped variant='green' className='text-grey'>
        <tbody>
          <tr>
            <td className='font-weight-bold'>欲執行學期</td>
            <td>{data.lecturer_detail ? data.semester.title : ''}</td>
          </tr>
          <tr>
            <td className='font-weight-bold'>教師</td>
            <td>{data.lecturer_detail ? data.lecturer_detail.name : ''}</td>
          </tr>
          <tr>
            <td className='font-weight-bold'>學院</td>
            <td>{data.lecturer_detail ? data.lecturer_detail.degree : ''}</td>
          </tr>
          <tr>
            <td className='font-weight-bold'>學系</td>
            <td>{data.lecturer_detail ? data.lecturer_detail.dept : ''}</td>
          </tr>
          <tr>
            <td className='font-weight-bold'>信箱</td>
            <td>{data.lecturer_detail ? data.lecturer_detail.email : ''}</td>
          </tr>
          <tr>
            <td className='font-weight-bold'>電話</td>
            <td>{data.lecturer_detail ? data.lecturer_detail.tel : ''}</td>
          </tr>
          <tr>
            <td className='font-weight-bold'>學生學號</td>
            <td>{data.student_detail ? data.student_detail.account_no : ''}</td>
          </tr>
          <tr>
            <td className='font-weight-bold'>學生姓名</td>
            <td>{data.student_detail ? data.student_detail.name : ''}</td>
          </tr>
          <tr>
            <td className='font-weight-bold'>學生身份</td>
            <td>{data.student_detail ? data.student_detail.category : ''}</td>
          </tr>
          <tr>
            <td className='font-weight-bold'>學生信箱</td>
            <td>{data.student_detail ? data.student_detail.email : ''}</td>
          </tr>
          <tr>
            <td className='font-weight-bold'>學生電話</td>
            <td>{data.student_detail ? data.student_detail.tel : ''}</td>
          </tr>
          <tr>
            <td className='foㄑnt-weight-bold'>申請科目</td>
            <td>{data.subject}</td>
          </tr>
          <tr>
            <td className='font-weight-bold'>輔導規劃</td>
            <td>{data.plan}</td>
          </tr>
          <tr>
            <td className='font-weight-bold'>小老師預計聘任時間</td>
            <td>{data.duration}</td>
          </tr>
          <tr>
            <th>我已徵得受輔學生同意以申請個案諮詢輔導專案</th>
            <td>是</td>
          </tr>
        </tbody>
      </Table>

      <div className='text-center mt-5'>
        <Link to='/individual/apply-record'>
          <Button variant='orange'>回到申請列表</Button>
        </Link>
      </div>
    </div>
  )
}

export default RecordPage
