import { Component } from 'react'
import { connect } from 'react-redux'
import Form from 'react-bootstrap/Form'
import ScheduleTable from 'components/basic_page/schedule/schedule_table.js'
import axios from 'axios'
import dayjs from 'dayjs'
import { fetchShowTimeSlot } from 'actions/basic/actions'
class SchedulePage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showSubject: true,
      schedule: [],
    }

    this.content = {
      english: {
        title: 'Course Schedule',
        tips: 'Click on the time slots to see course details',
        this_week: 'This week',
        next_week: 'Next week',
        next_next_week: 'The week after next week',
        subject: 'View Subjects',
        tutor: 'View Tutors',
      },
      chinese: {
        title: '課表',
        tips: '點選時間區段，查看課程詳情',
        this_week: '本週',
        next_week: '下週',
        next_next_week: '下下週',
        subject: '顯示科目',
        tutor: '顯示小老師',
      },
    }
  }

  fetchSchedule = (week, token) => {
    axios
      .get(`/en/api/getTimeTableSchedule/${week}`, { params: { token: token === '' ? undefined : token } })
      .then((res) => {
        // console.log({ res })
        const sche = res.data.result.schedule
        let new_a = []
        for (const [key, value] of Object.entries(sche)) {
          const date = dayjs(key)
          new_a.push({
            date: date,
            course: value.course,
          })
        }
        // console.log(new_a)
        this.setState({ schedule: new_a })
      })
      .catch((err) => {
        // console.log(err)
      })
  }

  componentDidMount() {
    const { dispatch } = this.props
    dispatch(fetchShowTimeSlot())
    this.fetchSchedule(1, this.props.token)
  }

  showSubject = () => {
    this.setState({ showSubject: true })
  }
  showTeacher = () => {
    this.setState({ showSubject: false })
  }
  changeWeek = (e) => {
    this.fetchSchedule(e.target.value, this.props.token)
  }

  render() {
    let content
    if (this.props.lang === 'zh') {
      content = this.content.chinese
    } else if (this.props.lang === 'en') {
      content = this.content.english
    }

    return (
      <div className='container mb-5'>
        <h1 className='size-1 my-5 text-left text-sm-center'>{content.title}</h1>
        <p className='text-grey text-center schedule-tips'>{content.tips}</p>
        <div className='mx-auto schedule-top'>
          <Form.Group>
            <Form.Control as='select' id='select-time-slut' onChange={this.changeWeek} custom>
              <option selected value='1'>
                {content.this_week}
              </option>
              <option value='2'>{content.next_week}</option>
              <option value='3'>{content.next_next_week}</option>
            </Form.Control>
          </Form.Group>

          <div className='d-flex mt-3 text-center'>
            <div
              onClick={this.showSubject}
              className={'schedule-btn p-1 p-sm-2' + (this.state.showSubject ? ' schedule-btn-active' : '')}
            >
              {content.subject}
            </div>
            <div
              onClick={this.showTeacher}
              className={'schedule-btn p-1 p-sm-2' + (this.state.showSubject ? '' : ' schedule-btn-active')}
            >
              {content.tutor}
            </div>
          </div>
        </div>

        <ScheduleTable showSubject={this.state.showSubject} schedule={this.state.schedule} />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    lang: state.front.lang,
    token: state.login.token,
  }
}

export default connect(mapStateToProps)(SchedulePage)
