import { Switch, Route } from 'react-router-dom'
import Footer from 'components/main_page/main_page_footer.js'
import Navbar from 'containers/individual_page/main/navbar.js'
import NewsPage from 'containers/individual_page/news/news_page.js'
import NewsShow from 'components/individual_page/news/news_show.js'
import ServicePage from 'containers/individual_page/service/service_page.js'
import QAPage from 'containers/individual_page/question/question_page.js'
import DownloadPage from 'containers/individual_page/download/download_page.js'
import ContactPage from 'containers/individual_page/contact/contact_page.js'
import ApplyPage from 'containers/individual_page/apply/apply_page'
import ApplyRecord from 'containers/individual_page/apply/record'
import ApplyRecordShow from 'containers/individual_page/apply/apply_show.js'
import TutorList from 'containers/individual_page/tutor/tutor_list.js'
import ConsultRecordPage from 'containers/individual_page/consult/record_page'
import NewConsultPage from 'containers/individual_page/consult/new_record'
import TutorSalary from 'containers/individual_page/tutor/salary.js'
import Index from 'containers/individual_page/main/index'
import PrivateRoute from 'private-route.js'

function BasicPage({ match }) {
  return (
    <div>
      <Navbar match={match} />

      <Switch>
        <Route exact path={`${match.path}/news`} component={({ match }) => <NewsPage match={match} />} />
        <Route path={`${match.path}/news/:id`} component={({ match }) => <NewsShow match={match} />} />
        <Route path={`${match.path}/service`} component={ServicePage} />
        <Route path={`${match.path}/question`} component={({ match }) => <QAPage match={match} />} />
        <Route path={`${match.path}/download/:type`} render={({ match }) => <DownloadPage match={match} />} />
        <Route path={`${match.path}/contact`} component={({ match }) => <ContactPage match={match} />} />
        <PrivateRoute path={`${match.path}/apply`} check_role='lecturer' cate='individual' Component={ApplyPage} />
        <PrivateRoute
          path={`${match.path}/apply-record/:id`}
          check_role='lecturer'
          cate='individual'
          Component={ApplyRecordShow}
        />
        <PrivateRoute
          path={`${match.path}/apply-record`}
          check_role='lecturer'
          cate='individual'
          Component={ApplyRecord}
        />
        <PrivateRoute path={`${match.path}/tutor`} check_role='lecturer' cate='individual' Component={TutorList} />
        <Route path={`${match.path}/record`} render={() => <ConsultRecordPage />} />
        <PrivateRoute
          path={`${match.path}/new-consult`}
          check_role='tutor'
          cate='individual'
          Component={NewConsultPage}
        />
        <PrivateRoute path={`${match.path}/salary`} check_role='tutor' cate='individual' Component={TutorSalary} />

        <Route exact path={match.path} component={({ match }) => <Index match={match} />} />
      </Switch>

      <Footer />
    </div>
  )
}

export default BasicPage
