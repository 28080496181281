import { useState } from 'react'
import { useSelector } from 'react-redux'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import onlineReservePic from 'img/logos/weblogo/online_reserve.svg'
import consultPic from 'img/logos/weblogo/consult.svg'
import feedbackPic from 'img/logos/weblogo/feedback.svg'

function ServicePage() {
  const [open, setOpen] = useState(0)

  const cards = (title, text, img_src) => {
    return (
      <div className='bg-white text-center service-cards2 rounded'>
        <div className='service-content2 text-dark'>
          <h2 className='service-card-text'>{title}</h2>
          <img src={img_src} alt='logo' />
          <p className='mt-1'>{text}</p>
        </div>
      </div>
    )
  }

  let content
  let lang = useSelector((s) => s.front.lang)
  if (lang === 'zh') {
    content = words.chinese
  } else if (lang === 'en') {
    content = words.english
  }

  return (
    <div className='bg-green text-white py-5'>
      <section id='s-about' className='text-left container'>
        <h2>{content.title}</h2>

        <p className='size-3 mb-4'>
          {content.text1}
          <br />
          {content.text2}
        </p>

        <Button
          variant='outline-orange'
          className={'mx-sm-1 p-1 mt-1 mt-sm-0' + (open === 0 ? ' active' : '')}
          onClick={() => setOpen(0)}
        >
          {content.service}
        </Button>
        <Button
          variant='outline-orange'
          className={'mx-sm-1 p-1 mt-1 mt-sm-0' + (open === 1 ? ' active' : '')}
          onClick={() => setOpen(1)}
        >
          {content.apply}
        </Button>
        <Button
          variant='outline-orange'
          className={'mx-sm-1 p-1 mt-1 mt-sm-0' + (open === 2 ? ' active' : '')}
          onClick={() => setOpen(2)}
        >
          {content.apply2}
        </Button>

        <Row className={'mt-4' + (open === 0 ? '' : ' d-none')}>
          <Col lg='4' className='pr-2 p-sm-3 mt-2 mt-lg-0'>
            {cards(content.step1, content.step1_text, onlineReservePic)}
          </Col>
          <Col lg='4' className='pr-2 p-sm-3 mt-2 mt-lg-0'>
            {cards(content.step2, content.step2_text, consultPic)}
          </Col>
          <Col lg='4' className='pr-2 p-sm-3 mt-2 mt-lg-0'>
            {cards(content.step3, content.step3_text, feedbackPic)}
          </Col>
        </Row>

        <Card body style={{ minHeight: 'unset' }} className={'text-dark mt-4' + (open === 1 ? '' : ' d-none')}>
          {content.apply_text}
        </Card>

        <Row className={'text-dark mt-4 text-center' + (open === 2 ? '' : ' d-none')}>
          <Col sm='3' className='bg-white rounded p-3'>
            {content.apply2_text1}
          </Col>
          <Col>
            <i class='fas fa-caret-right text-blue size-2 d-none d-sm-block vertical-center'></i>
            <i class='fas fa-caret-down text-blue size-2 d-block d-sm-none my-2'></i>
          </Col>
          <Col sm='3' className='bg-white rounded p-3'>
            {content.apply2_text2}
          </Col>
          <Col>
            <i class='fas fa-caret-right text-blue size-2 d-none d-sm-block vertical-center'></i>
            <i class='fas fa-caret-down text-blue size-2 d-block d-sm-none my-2'></i>
          </Col>
          <Col sm='3' className='bg-white rounded p-3'>
            {content.apply2_text3}
          </Col>
        </Row>
      </section>
    </div>
  )
}
export default ServicePage

const words = {
  english: {
    title: 'About the Service',
    text1:
      'In order to enhance the learning effectiveness of NTU students and help them deal with problems encountered in basic subjects, the CTLD hires tutors to provide tutoring service for students in need.',
    service: 'Service Flow',
    apply: 'General Information',
    apply_text: (
      <div>
        <p>
          The CTLD's would fund tutors' wages with subsidy, which can be applied by departments or colleges. Each
          department or college can apply for 2 tutors at most.
        </p>
        <p>
          The hourly wage of tutors is NT$500 (according to the "Individual Counselling Service Regulations"). Tutors
          would have to work at least 6 hours a month (monthly wage NT$ 3,000) and at most 24 hours a month (monthly
          wage NT$ 12,000). The subsidy received would be the average wages of tutors. After the application is
          accepted, tutors' wages would be paid according to the actual teaching hours of the month.
        </p>
        More information please go to "
        <a
          target='_blank'
          rel='noopener noreferrer'
          href='https://drive.google.com/file/d/1dIW2au__9G3za91hbdc3HREdbqXinML9/view?usp=sharing'
          style={{ color: '#06386b' }}
        >
          Specialized Courses Tutoring Service Regulations
        </a>
        ."
      </div>
    ),
    apply2: 'Application Procedures',
    apply2_text1: 'Each department recruits tutors on their own.',
    apply2_text2: 'Submit data needed following the guideline.',
    apply2_text3: 'After the application is accepted, complete the following hiring procedures.',
    step1: 'Online Reservation',
    step1_text:
      'The service should be applied by departments. After logging in to this website, departments can start the online application.',
    step2: 'Counselling Service',
    step2_text:
      'After the application is accepted, tutors recruited by departments can start negotiating for the time and place to hold tutoring sessions.',
    step3: 'Fill In the Records',
    step3_text:
      'After tutoring sessions, both tutors and students should log in to the website and fill in the tutoring records and feedbacks.',
  },
  chinese: {
    title: '關於服務',
    text1:
      '為提升本校學生專業學科學習成效，並鼓勵各學系主動聯絡關懷有學習困難之學生，規劃課業諮詢服務，解決學生課業疑難，國立臺灣大學教務處教學發展中心特辦理「專業學科學習諮詢補助計畫」。',
    service: '服務流程',
    apply: '申請概要',
    apply_text: (
      <div>
        <p>
          本中心提供課輔小老師輔導費補助，由本校各學系或各學院作為申請單位提出申請。 每單位可申請之課輔小老師名額至多 2
          人。
        </p>
        <p>
          小老師時薪以新臺幣 500 元核實支給（依照本中心「個別學習諮詢實施要點」辦理），每月值班至少 6 小時（月薪 3,000
          元），至多 24 小時（月薪 12,000
          元）。所申請之補助金額為預估之平均薪資，通過審核後將依小老師每月實際輔導時數核給薪資。
        </p>
        詳情請見
        <a
          target='_blank'
          rel='noopener noreferrer'
          href='https://drive.google.com/file/d/1dIW2au__9G3za91hbdc3HREdbqXinML9/view?usp=sharing'
          style={{ color: '#06386b' }}
        >
          專業諮詢實施要點
        </a>
      </div>
    ),
    apply2: '申請流程',
    apply2_text1: '各單位自行徵選課輔小老師',
    apply2_text2: '按照公告時程提交所需資料',
    apply2_text3: '審核通過後完成後續聘僱流程',
    step1: '線上申請',
    step1_text: '由各系辦為申請單位，於本站登入後進行線上申請',
    step2: '輔導諮詢',
    step2_text: '申請通過後，系辦推派之小老師即可自行安排時間、地點授課',
    step3: '填寫記錄',
    step3_text: '授課結束，小老師與學生須於本站登入填寫授課紀錄及回饋',
  },
}
