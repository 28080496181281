import { useEffect, useState } from 'react'
import Jumbotron from 'components/basic_page/main/jumbotron.js'
import News from 'components/basic_page/news/news.js'
import Service from 'components/basic_page/service/service.js'
import Team from 'components/basic_page/service/team.js'
import ContactUs from 'components/basic_page/contact/contact_us.js'

import axios from 'axios'

function BasicPageIndex({ match }) {
  const [urgent, setUrgent] = useState('')
  const [news, setNews] = useState([])
  const [teacher, setTeacher] = useState([])

  useEffect(() => {
    axios
      .get('/en/api/getHomeData')
      .then((res) => {
        let new_news = []
        let new_teacher_list = []
        // console.log(res.data.result)
        for (const article of res.data.result.article) {
          if (article.is_deleted === '0') {
            new_news.push(article)
          }
        }

        for (const teacher of res.data.result.tutor) {
          new_teacher_list.push(teacher)
        }

        new_news = new_news.sort((a, b) => {
          if (a.stick_to_top == 1 && b.stick_to_top != 1) return -1
          else if (b.stick_to_top == 1 && a.stick_to_top != 1) return 1
          else return new Date(b.date) - new Date(a.date)
        })
        setNews(new_news)
        setTeacher(new_teacher_list)
        if (res.data.result.news[0]) setUrgent(res.data.result.news[0])
      })
      .catch((err) => {
        // console.log(err)
        //alert('獲取主頁資料錯誤')
      })
  }, [])

  return (
    <>
      <section id='jumbotron'>
        <div className='container py-5'>
          <Jumbotron path={match.path} alert={urgent} />
        </div>
      </section>

      <section id='news'>
        <div className='container py-5'>
          <News news={news} />
        </div>
      </section>

      <section id='service'>
        <div className='container py-5'>
          <Service />
        </div>
      </section>

      <section id='team'>
        <div className='container py-5'>
          <Team teacher_list={teacher} />
        </div>
      </section>

      <section id='contactus'>
        <div className='container py-5'>
          <ContactUs />
        </div>
      </section>
    </>
  )
}

export default BasicPageIndex
