import MainPageCard from 'components/main_page/main_page_card'
import { NavLink } from 'react-router-dom'
import logo from 'img/logos/logo/logo_color_bg.svg'
import basicLogo from 'img/logos/weblogo/basic_1.svg'
import advancedogo from 'img/logos/weblogo/advanced_1.svg'
import caseLogo from 'img/logos/weblogo/case_1.svg'

function Main() {
  return (
    <div>
      <div className='row py-3'>
        <div className='col-sm-5'>
          <img src={logo} alt='logo' />
        </div>
        <div className='col-sm-7 mt-3 mt-sm-0 d-flex flex-column justify-content-center main-page-text'>
          <h1 className='main-page-title text-center'>臺大學習諮詢</h1>
          <h2 className='main-page-subtitle text-center'>NTU Academic Counseling Service</h2>
        </div>
      </div>

      <div className='row py-5'>
        <NavLink to='/basic' className='col-4'>
          <MainPageCard img_src={basicLogo} text='基礎學科' />
        </NavLink>
        <NavLink to='/special' className='col-4'>
          <MainPageCard img_src={advancedogo} text='專業學科' />
        </NavLink>
        <NavLink to='/individual' className='col-4'>
          <MainPageCard img_src={caseLogo} text='個案諮詢' />
        </NavLink>
      </div>
    </div>
  )
}

export default Main
