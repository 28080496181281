import { combineReducers } from 'redux'
import {
  SET_LOGIN_ROLE,
  SET_LOGIN_TOKEN,
  SET_ISLOGIN,
  RECEIVE_LOGIN_PROFILE,
  USER_FAKE_LOGIN,
  USER_LOGOUT,
  RECEIVE_NEWS,
  RECEIVE_FAQ,
  RECEIVE_SEMESTER,
  RECEIVE_SUSPEND,
  RECEIVE_TUTOR,
  RECEIVE_STUDENT,
  RECEIVE_SUBJECT,
  RECEIVE_DEPT,
  POP_ALERT,
  SET_LANG,
  RECEIVE_IMPORTANTDOCUMENT,
  RECEIVE_NORMALDOCUMENT,
} from '../../actions/individual/actions'

const initialLogin = {
  role: '',
  data: {},
  name: '',
  token: '',
  isAdmin: undefined,
}

function login(state = initialLogin, action) {
  switch (action.type) {
    case SET_LOGIN_ROLE:
      return {
        ...state,
        role: action.role,
      }
    case SET_LOGIN_TOKEN:
      return {
        ...state,
        token: action.token,
      }
    case SET_ISLOGIN:
      return {
        ...state,
        role: action.role || state.role,
      }
    case RECEIVE_LOGIN_PROFILE:
      return {
        ...state,
        name: action.data.name,
        data: action.data,
      }
    case USER_FAKE_LOGIN:
      return { ...initialLogin, ...action.login }
    case USER_LOGOUT:
      return initialLogin
    case 'SET_ISADMIN':
      return {
        ...state,
        isAdmin: action.isAdmin,
      }
    default:
      return state
  }
}

function front(state = { lang: 'zh' }, action) {
  switch (action.type) {
    case SET_LANG:
      return {
        ...state,
        lang: action.lang,
      }
    default:
      return state
  }
}

// dashboard
const initialDB = {
  error: undefined,
  news: [],
  faq: [],
  normaldocs: [],
  importantdocs: [],
  semester: {
    list: [],
    current: undefined,
  },
  tutor: [],
  student: [],
  suspend: [],
  subject: [],
  dept: [],
  alert: {
    msg: '',
    isSuccess: true,
    time: '',
  },
}

function alert(state, action) {
  switch (action.type) {
    case POP_ALERT:
      return {
        ...state,
        msg: action.msg,
        isSuccess: action.success,
        time: new Date(),
        hasShown: false,
      }
    case 'HAS_SHOWN':
      return {
        ...state,
        hasShown: true,
      }
    default:
      return state
  }
}

function db(state = initialDB, action) {
  switch (action.type) {
    case RECEIVE_NEWS:
      return { ...state, news: action.data }
    case RECEIVE_FAQ:
      return { ...state, faq: action.data }
    case RECEIVE_NORMALDOCUMENT:
      return { ...state, normaldocs: action.data }
    case RECEIVE_IMPORTANTDOCUMENT:
      return { ...state, importantdocs: action.data }
    case RECEIVE_SEMESTER:
      return {
        ...state,
        semester: {
          list: action.data,
          current: action.current,
        },
      }
    case RECEIVE_SUSPEND:
      return { ...state, suspend: action.data }
    case RECEIVE_TUTOR:
      return { ...state, tutor: action.data }
    case RECEIVE_STUDENT:
      return { ...state, student: action.data, student_total_count: action.count }
    case RECEIVE_SUBJECT:
      return { ...state, subject: action.data }
    case RECEIVE_DEPT:
      return { ...state, dept: action.data }
    case POP_ALERT:
      return { ...state, alert: alert(state.alert, action) }
    default:
      return state
  }
}

const rootReducer = combineReducers({
  login,
  front,
  db,
})

export default rootReducer
