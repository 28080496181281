import { useState } from 'react'
import { useSelector } from 'react-redux'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import onlineReservePic from 'img/logos/weblogo/online_reserve.svg'
import consultPic from 'img/logos/weblogo/consult.svg'
import feedbackPic from 'img/logos/weblogo/feedback.svg'

function ServicePage() {
  const [open, setOpen] = useState(0)

  const cards = (title, text, img_src) => {
    return (
      <div className='bg-white text-center service-cards2 rounded'>
        <div className='service-content2 text-dark'>
          <h2 className='service-card-text'>{title}</h2>
          <img src={img_src} alt='logo' />
          <p className='mt-1'>{text}</p>
        </div>
      </div>
    )
  }

  let content
  let lang = useSelector((s) => s.front.lang)
  if (lang === 'zh') {
    content = words.chinese
  } else if (lang === 'en') {
    content = words.english
  }

  const arrow = (
    <Col>
      <i class='fas fa-caret-right text-blue size-2 d-none d-sm-block vertical-center'></i>
      <i class='fas fa-caret-down text-blue size-2 d-block d-sm-none my-2'></i>
    </Col>
  )

  return (
    <div className='bg-green text-white py-5'>
      <section id='s-about' className='text-left container'>
        <h2>{content.title}</h2>

        <p className='size-3 mb-4'>
          {content.text1}
          <br />
          {content.text2}
        </p>

        <Button
          variant='outline-orange'
          className={'mx-sm-1 p-1 mt-1 mt-sm-0' + (open === 0 ? ' active' : '')}
          onClick={() => setOpen(0)}
        >
          {content.service}
        </Button>
        <Button
          variant='outline-orange'
          className={'mx-sm-1 p-1 mt-1 mt-sm-0' + (open === 1 ? ' active' : '')}
          onClick={() => setOpen(1)}
        >
          {content.apply}
        </Button>
        <Button
          variant='outline-orange'
          className={'mx-sm-1 p-1 mt-1 mt-sm-0' + (open === 2 ? ' active' : '')}
          onClick={() => setOpen(2)}
        >
          {content.apply2}
        </Button>

        <Row className={'mt-4' + (open === 0 ? '' : ' d-none')}>
          <Col lg='4' className='pr-2 p-sm-3 mt-2 mt-lg-0'>
            {cards(content.step1, content.step1_text, onlineReservePic)}
          </Col>
          <Col lg='4' className='pr-2 p-sm-3 mt-2 mt-lg-0'>
            {cards(content.step2, content.step2_text, consultPic)}
          </Col>
          <Col lg='4' className='pr-2 p-sm-3 mt-2 mt-lg-0'>
            {cards(content.step3, content.step3_text, feedbackPic)}
          </Col>
        </Row>

        <Card body style={{ minHeight: 'unset' }} className={'text-dark mt-4' + (open === 1 ? '' : ' d-none')}>
          {content.apply_text}
        </Card>

        <Row className={'text-dark mt-4 text-center' + (open === 2 ? '' : ' d-none')}>
          <Col sm='2' className='bg-white rounded p-3'>
            {content.apply2_text1}
          </Col>
          {arrow}
          <Col sm='2' className='bg-white rounded p-3'>
            {content.apply2_text2}
          </Col>
          {arrow}
          <Col sm='2' className='bg-white rounded p-3'>
            {content.apply2_text3}
          </Col>
          {arrow}
          <Col sm='2' className='bg-white rounded p-3'>
            {content.apply2_text4}
          </Col>
          {arrow}
          <Col sm='2' className='bg-white rounded p-3'>
            {content.apply2_text5}
          </Col>
        </Row>
      </section>
    </div>
  )
}
export default ServicePage

const words = {
  english: {
    title: 'About the Service',
    text1:
      'In order to encourage NTU teachers to help individual students that struggle academically, the CTLD plans a “Individual Counseling Service” program to support teachers with related counseling resources and administrative assistance.',
    service: 'Service Flow',
    apply: 'General Information',
    apply_text: (
      <div>
        <p>
          Advisors can submit applications for students after obtaining consent from students. After the CTLD passes
          their applications, teachers can recruit appropriate tutors. The departments of the students would hire the
          tutors, and also have to hand in the application form for hiring tutors and insurance forms, etc. in order to
          complete the employment procedure.
        </p>
        <p>
          The hourly wage of tutors is NT$500 (according to the "Individual Counseling Service Regulations" of the
          CTLD). Tutors would have to work at least 6 hours a month (monthly wage NT$ 3,000) and at most 24 hours a
          month (monthly wage NT$ 12,000). The subsidy amount received would be the estimated average wages of tutors.
          After the application is accepted, tutors' wages would be paid according to the actual teaching hours of the
          month.
        </p>
        More information please go to "
        <a
          target='_blank'
          rel='noopener noreferrer'
          href='https://drive.google.com/file/d/17LBKWBbJWlD4OqdP5c6IBBKtx_h6eXuM/view?usp=sharing'
          style={{ color: '#06386b' }}
        >
          Individual Counseling Service Regulations
        </a>
        ."
      </div>
    ),
    apply2: 'Application Procedures',
    apply2_text1: 'Advisor recruits tutors',
    apply2_text2: 'CTLD prepares employment documents and input them to the system',
    apply2_text3: 'Advisor confirms employment contents',
    apply2_text4: 'Department validates employment forms',
    apply2_text5: 'CTLD submits employment forms to the Office of Academic Affairs',
    step1: 'Online Reservation',
    step1_text:
      'The service should be applied by teachers. After logging in to this website, advisors can start the online application.',
    step2: 'Counseling Service',
    step2_text:
      'After the application is accepted, tutors recruited by advisors can start negotiating for the time and place to hold tutoring sessions.',
    step3: 'Fill In the Records',
    step3_text:
      'After tutoring sessions, both tutors and students should log in to the website and fill in the tutoring records and feedbacks.',
  },
  chinese: {
    title: '關於服務',
    text1:
      '為鼓勵本校教師協助個別有學習困難之學生加強課業學習，臺大教務處教學發展中心特辦理「個案學習諮詢輔導專案」，以提供教師相關輔導資源及行政協助。',
    service: '服務流程',
    apply: '申請概要',
    apply_text: (
      <div>
        <p>
          導師徵得學生同意後，得為學生提出申請，經本中心審核通過後，即可徵選適任課輔小老師，並由學生所屬學系作為聘用單位，繳交課輔小老師之聘僱申請書、勞健保加保申請書等，以完成聘僱程序。
        </p>
        <p>
          小老師時薪以新臺幣 500 元核實支給（依照本中心「個別學習諮詢實施要點」辦理），每月值班至少 6 小時（月薪 3,000
          元），至多 24 小時（月薪 12,000
          元）。所申請之補助金額為預估之平均薪資，通過審核後將依小老師每月實際輔導時數核給薪資。
        </p>
        詳情請見
        <a
          target='_blank'
          rel='noopener noreferrer'
          href='https://drive.google.com/file/d/17LBKWBbJWlD4OqdP5c6IBBKtx_h6eXuM/view?usp=sharing'
          style={{ color: '#06386b' }}
        >
          個案諮詢實施要點
        </a>
      </div>
    ),
    apply2: '申請流程',
    apply2_text1: '導師招聘課輔小老師',
    apply2_text2: '教發準備聘僱資料並於系統登錄',
    apply2_text3: '導師確認聘僱內容',
    apply2_text4: '系所檢核聘僱申請',
    apply2_text5: '教發呈送紙本予教務處檢核',
    step1: '線上申請',
    step1_text: '由各系導師作為申請人，於本站登入後進行線上申請',
    step2: '輔導諮詢',
    step2_text: '申請通過後，導師徵選之小老師即可與學生協調授課時間、地點',
    step3: '填寫紀錄',
    step3_text: '授課結束，小老師與學生須於本站登入填寫授課紀錄及回饋',
  },
}
