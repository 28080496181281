import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import axios from 'axios'

function DownloadPage() {
  const lang = useSelector((s) => s.front.lang)
  const { type } = useParams()
  const [data, setData] = useState([])

  useEffect(() => {
    axios
      .get(`/en/api/getFrontdocumentList/${type}`)
      .then((res) => {
        if (res.data.status === 'OK') {
          // console.log(res.data.result.detail)
          setData(res.data.result)
        } else {
          // console.log(res.data)
          alert(res.data.result)
        }
      })
      .catch((err) => {
        // console.log(err)
        alert('發生錯誤')
      })
  },[type])

  return (
    <div className='container mb-5'>
      <h1 className='size-1 my-5 text-left text-sm-center'>{lang === 'zh' ?( type === "regulation"?'重要法規':'線上教材') +' 檔案下載' : ( type === "regulation"?'Regulation':'Form') +' Download'}</h1>

      {data.map((e) => (
          <a href={e.url} target='_blank' className='download-area mb-3 d-block p-2 p-sm-3' type='button'>
          <div className='blue-circle mr-3 d-inline-block'>
            <i class='fas fa-file-alt'></i>
          </div>
          <span>{lang === 'zh' ?e.title:e.title_en}</span>
        </a>
      ))}
    </div>
  )
}

export default DownloadPage
