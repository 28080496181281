import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setLang, userFakeLogin } from 'actions/basic/actions'

function SmallNavbar(props) {
  const dispatch = useDispatch()
  const lang = useSelector((s) => s.front.lang)
  const role = useSelector((s) => s.login.role)
  const token = useSelector((s) => s.login.token)
  const userdata = useSelector((s) => s.login.data)

  let content = {
    english: {
      apply: 'Online Apply',
      reserve_record: 'Reservation Records',
      apply_record: 'Tutoring Records',
      consult_record: 'Tutoring Feedbacks',
      salary: 'Salary',
      tutor: 'Tutor List',
      eval: 'End-of-term Evaluation',
      mideval: 'Mid-of-term Evaluation',
      index: 'Home',
      news: 'News',
      service: 'About the Service',
      download_important: 'Regulations',
      download_normal: 'Form Download',
      qna: 'FAQ',
      contact: 'Contact Us',
      lecturer_login: 'Tutor Login',
    },
    chinese: {
      apply: '線上申請',
      reserve_record: '預約紀錄',
      apply_record: '申請紀錄',
      consult_record: '諮詢紀錄',
      salary: '薪資查詢',
      tutor: '小老師列表',
      eval: '期末意見調查',
      mideval: '期中意見調查',
      index: '首頁',
      news: '最新消息',
      service: '關於服務',
      download_important: '重要法規',
      download_normal: '常用表單',
      qna: '常見問題',
      contact: '聯絡我們',
      lecturer_login: '教師登入',
    },
  }

  if (lang === 'zh') {
    content = content.chinese
  } else if (lang === 'en') {
    content = content.english
  }

  let navItem = [];
  let evalNav = [];
  if (role === 'lecturer') {
    navItem = [
      { path: 'apply', text: content.apply },
      { path: 'apply-record', text: content.apply_record },
      { path: 'tutor', text: content.tutor },
    ]
  } else if (role === 'tutor') {
    navItem = [
      { path: 'record/tutor', text: content.consult_record },
      { path: 'salary', text: content.salary },
    ]
  } else if (role === 'student') {
    navItem = [{ path: 'record/student', text: content.consult_record }]
  }
  if(userdata.startReponse && userdata.questionnaire_array[role]){
    evalNav.push({ path:  `${process.env.REACT_APP_BACKEND_API}/zh/special_feedback/${userdata.questionnaire_array[role]}/${token}`, text: content.eval },);
  }
  if(userdata.startMidReponse && userdata.mid_questionaire_array[role]){
    evalNav.push({ path:  `${process.env.REACT_APP_BACKEND_API}/zh/special_feedback/${userdata.mid_questionaire_array[role]}/${token}`, text: content.mideval },);
  }

  return (
    <div className={'side-navbar' + (props.open ? '' : ' d-none')}>
      <div className='side-bg' onClick={props.smallNavbarClick}></div>
      <div className='side-navbar-area'>
        <div className='side-navbar-text-area text-right'>
          <div className='lang text-bold'>
            <div
              className={'mx-2 lang-option d-inline ' + (lang === 'zh' ? 'lang-active' : '')}
              type='button'
              onClick={() => dispatch(setLang('zh'))}
            >
              中文
            </div>
            |
            <div
              className={'mx-2 lang-option d-inline ' + (lang === 'en' ? 'lang-active' : '')}
              type='button'
              onClick={() => dispatch(setLang('en'))}
            >
              EN
            </div>
          </div>

          <ul className='navbar-nav pt-2 pr-3'>
            <li className={'nav-item text-bold py-3' + (token === '' ? '' : ' d-none')}>
              <a
                href={
                  process.env.NODE_ENV === 'production'
                    ? `${process.env.REACT_APP_BACKEND_API}/en/spcl/api/signin/lecturer?to=${
                        window.location.href.split(/[?#]/)[0]
                      }?role=lecturer`
                    : '#'
                }
                onClick={() => {
                  if (process.env.NODE_ENV !== 'production') dispatch(userFakeLogin('lecturer'))
                }}
              >
                {content.lecturer_login}
              </a>
            </li>
            {navItem.map((i) => (
              <li className='nav-item text-bold py-3'>
                <Link to={`${props.match.path}/${i.path}`} onClick={props.smallNavbarClick}>
                  {i.text}
                </Link>
              </li>
            ))}
             {evalNav.map((i) => (
              <li className='nav-item text-bold py-3'>
                <a href={`${i.path}`} onClick={props.smallNavbarClick}>
                  {i.text}
                </a>
              </li>
            ))}
            
            <hr />
            <li className='nav-item text-bold py-3'>
              <Link to={props.match.path} onClick={props.smallNavbarClick}>
                {content.index}
              </Link>
            </li>
            <li className='nav-item text-bold py-3'>
              <Link to={`${props.match.path}/news`} onClick={props.smallNavbarClick}>
                {content.news}
              </Link>
            </li>
            <li className='nav-item text-bold py-3'>
              <Link to={`${props.match.path}/service`} onClick={props.smallNavbarClick}>
                {content.service}
              </Link>
            </li>
            <li className='nav-item text-bold py-3'>
              <Link to={`${props.match.path}/question`} onClick={props.smallNavbarClick}>
                {content.qna}
              </Link>
            </li>
            <li className='nav-item text-bold py-3'>
              <Link to={`${props.match.path}/download/regulation`} onClick={props.smallNavbarClick}>
                {content.download_important}
              </Link>
            </li> <li className='nav-item text-bold py-3'>
              <Link to={`${props.match.path}/download/form`} onClick={props.smallNavbarClick}>
                {content.download_normal}
              </Link>
            </li>
            <li className='nav-item text-bold py-3'>
              <Link to={`${props.match.path}/contact`} onClick={props.smallNavbarClick}>
                {content.contact}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default SmallNavbar
