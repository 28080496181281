import Footer from 'components/main_page/main_page_footer.js'
import Main from './main.js'

function MainPage() {
  return (
    <div id='main-page' className='bg-blue'>
      <div className='container py-5'>
        <Main />
      </div>
      <Footer />
    </div>
  )
}

export default MainPage
