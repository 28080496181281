import Cookies from 'js-cookie'

function MainPageFooter() {
  function clearcookie() {
    window.localStorage.clear()
    Cookies.remove('token')
    alert('清除成功')
    window.location.reload()
  }

  return (
    <div id='footer' className='py-4'>
      Copyright © 2021 Center for Teaching and Learning Development. All Rights Reserved
      <br />
      國立臺灣大學教務處教學發展中心　版權所有
      <br />
      <div style={{ textAlign: 'right', paddingRight: '40px' }}>
        <small onClick={clearcookie}>
          <a href='javascript:;'>清除異常狀態</a>
        </small>
      </div>
    </div>
  )
}

export default MainPageFooter
