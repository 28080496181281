import { Component } from 'react'
import CreatableSelect from 'react-select'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import axios from 'axios'

class NewConsultPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      account_no: '',
      students: [],
      options: [],
      selectValue: [],
      subject: '',
      date: '',
      start_time: '',
      end_time: '',
      class_record: '',
      success: false,
    }
  }
  fetchSubject = () => {
    axios
      .get('/en/spcl/api/tokenChecknValidation/' + this.props.token + '/tutor')
      .then((res) => {
        if (res.data.status === 'OK') {
          let new_s = res.data.result.result.tutor_subjects_array.map((s) => ({
            value: s,
            label: s,
          }))
          this.setState({ options: new_s })
        } else {
          // console.log(res.data.result)
          alert('獲取資料失敗')
        }
      })
      .catch((e) => {
        // console.log(e)
        alert('獲取資料失敗')
      })
  }

  componentDidMount() {
    this.fetchSubject()
  }

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value })
  }

  handleSubmit = (e) => {
    e.preventDefault()

    const body = {
      mode: 'Add',
      token: this.props.login.token,
      date: this.state.date,
      student_id: this.state.students.map((s) => s.id).join(),
      student_account_no: this.state.students.map((s) => s.account_no).join(),
      student_name: this.state.students.map((s) => s.name).join(),
      subject: this.state.subject.replace(/,+$/, ''),
      start_time: this.state.start_time + ':00',
      end_time: this.state.end_time + ':00',
      class_record: this.state.class_record,
    }
    // console.log(body)
    axios
      .post('/en/spcl/api/tutorAddConsultation', body)
      .then((res) => {
        if (res.data.status === 'OK') {
          this.setState({ success: true })
        } else {
          // console.log(res.data.result)
          alert(res.data.result)
        }
      })
      .catch((e) => {
        // console.log(e)
        alert('發生錯誤')
      })
  }

  addStdName = (account_no) => {
    if (account_no !== '')
      axios
        .get(`/en/spcl/api/checkStudentDetail/${account_no}/${this.props.token}`)
        .then((res) => {
          if (res.data.status === 'OK') {
            // console.log(res.data.result.studentData)
            if (this.state.students.find((s) => s.id === res.data.result.studentData.user_id)) {
              this.setState({
                account_no: '',
              })
              return alert('已新增該學生')
            }
            let newStudents = this.state.students.slice()
            newStudents.push({
              name: res.data.result.studentData.name,
              id: res.data.result.studentData.user_id,
              account_no,
            })

            this.setState({
              students: newStudents,
              account_no: '',
            })
          } else {
            this.setState({ students: [], account_no: '' })
          }
        })
        .catch((e) => {
          // console.log(e)
          alert('發生錯誤')
        })
  }
  rmStdName = (idx) => {
    let newStdudents = this.state.students
    newStdudents = newStdudents.slice(0, idx).concat(newStdudents.slice(idx + 1, newStdudents.length))
    this.setState({ students: newStdudents })
  }

  handleSelect = (obj) => {
    let subject = ''
    if (obj) {
      obj.map((e) => (subject += e.value + ','))
    }
    subject = subject.replace(/,+$/, '')
    this.setState({ subject: subject })
    this.setState({ selectValue: obj })
  }

  render() {
    const customStyles = {
      multiValue: (provided, state) => ({
        ...provided,
        backgroundColor: '#9FCECD',
        color: 'white',
      }),
      multiValueLabel: (provided, state) => ({
        color: 'white',
        paddingLeft: '3px',
      }),
    }

    return (
      <div className='container mb-5'>
        <div className='absolute-bg'></div>
        <h1 className='size-1 my-5 text-left text-sm-center'>新增諮詢紀錄</h1>

        <div className='p-2 p-sm-5 bg-white reserve-box size-5'>
          <h2 className='size-4 text-center mb-2 mb-sm-5'>請填寫諮詢紀錄基本資料</h2>

          <Form.Group as={Row} controlId='account_no'>
            <Form.Label column sm='2'>
              學生學號
            </Form.Label>
            <Col sm='10'>
              <Form.Control
                onChange={this.handleChange}
                value={this.state.account_no}
                required
                type='text'
                placeholder='請輸入學生學號'
                onBlur={(e) => this.addStdName(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') this.addStdName(e.target.value)
                }}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId='std_name'>
            <Form.Label column sm='2'>
              學生姓名
            </Form.Label>
            <Col sm='10'>
              <Form.Control plaintext value='輸入學號後自動顯示' readOnly hidden={this.state.students.length > 0} />
              {this.state.students.map((s, idx) => (
                <span
                  style={{ cursor: 'pointer' }}
                  class='badge badge-success text-white p-2 mr-1'
                  key={s.name}
                  onClick={() => this.rmStdName(idx)}
                >
                  {s.name}
                </span>
              ))}
              <Form.Text className='text-muted text-left' hidden={this.state.students.length <= 0}>
                點擊可移除或是繼續新增
              </Form.Text>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId='subject'>
            <Form.Label column sm='2'>
              輔導科目
            </Form.Label>
            <Col sm='10'>
              <CreatableSelect
                isMulti
                closeMenuOnSelect={false}
                value={this.state.selectValue}
                options={this.state.options}
                styles={customStyles}
                classNamePrefix='courses'
                placeholder='請選擇科目'
                onChange={this.handleSelect}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId='date'>
            <Form.Label column sm='2'>
              輔導日期
            </Form.Label>
            <Col sm='10'>
              <Form.Control type='date' onChange={this.handleChange} value={this.state.date}></Form.Control>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId='start_time'>
            <Form.Label column sm='2'>
              開始時間
            </Form.Label>
            <Col sm='10'>
              <Form.Control
                type='time'
                max={this.state.end_time}
                onChange={(e) => {
                  if (this.state.end_time !== '' && e.target.value >= this.state.end_time)
                    return alert('開始時間不能晚於結束時間')
                  this.handleChange(e)
                }}
                value={this.state.start_time}
              ></Form.Control>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId='end_time'>
            <Form.Label column sm='2'>
              結束時間
            </Form.Label>
            <Col sm='10'>
              <Form.Control
                type='time'
                min={this.state.start_time}
                onChange={(e) => {
                  if (this.state.start_time !== '' && e.target.value <= this.state.start_time)
                    return alert('結束時間不能早於開始時間')
                  this.handleChange(e)
                }}
                value={this.state.end_time}
              ></Form.Control>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId='class_record'>
            <Form.Label column sm='2'>
              輔導紀錄
            </Form.Label>
            <Col sm='10'>
              <Form.Control
                onChange={this.handleChange}
                value={this.state.plan}
                required
                type='text'
                as='textarea'
                placeholder='請輸入本次輔導學生之情形'
              />
            </Col>
          </Form.Group>

          <div className='text-center'>
            <button
              className='submit-btn text-white'
              onClick={this.handleSubmit}
              disabled={
                this.state.students.length === 0 ||
                this.state.end_date ||
                this.state.date === '' ||
                this.state.start_time === '' ||
                this.state.end_time === '' ||
                this.state.class_record === ''
              }
            >
              送出
            </button>
          </div>
        </div>

        {this.state.success ? <Redirect push to='/individual/record/tutor' /> : <></>}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
    token: state.login.token,
  }
}

export default connect(mapStateToProps)(NewConsultPage)
