import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Cookies from 'js-cookie'
import { useSelector, useDispatch } from 'react-redux'
import {
  setLang as basic_setLang,
  setLoginRoleAndVerify as basic_setLoginRoleAndVerify,
  setLoginToken as basic_setLoginToken,
} from 'actions/basic/actions'
import {
  setLang as special_setLang,
  setLoginRoleAndVerify as special_setLoginRoleAndVerify,
  setLoginToken as special_setLoginToken,
} from 'actions/special/actions'
import {
  setLang as individual_setLang,
  setLoginRoleAndVerify as individual_setLoginRoleAndVerify,
  setLoginToken as individual_setLoginToken,
} from 'actions/individual/actions'

function SetTokenFromCookie({ cate }) {
  let tokenCookie = Cookies.get('token')
  let token = useSelector((s) => s.login.token)
  const dispatch = useDispatch()

  const location = useLocation()
  const url = new URLSearchParams(location.search)
  const role = url.get('role') || window.localStorage.getItem('role')
  useEffect(() => {
    let setLang, setLoginRoleAndVerify, setLoginToken, tokenKey
    if (cate === 'basic') {
      setLang = basic_setLang
      setLoginRoleAndVerify = basic_setLoginRoleAndVerify
      setLoginToken = basic_setLoginToken
      tokenKey = 'basic_token'
    } else if (cate === 'special') {
      setLang = special_setLang
      setLoginRoleAndVerify = special_setLoginRoleAndVerify
      tokenKey = 'special_token'
      setLoginToken = special_setLoginToken
    } else if (cate === 'individual') {
      setLang = individual_setLang
      setLoginRoleAndVerify = individual_setLoginRoleAndVerify
      setLoginToken = individual_setLoginToken
      tokenKey = 'individual_token'
    }
    if (tokenCookie) {
      Cookies.set(tokenKey, tokenCookie, { expires: 14 })
      Cookies.remove('token')
    }
    tokenCookie = Cookies.get(tokenKey)

    if (url.get('login_error')) {
      alert(url.get('login_error'))
    }
    if (window.localStorage.getItem('lang')) {
      dispatch(setLang(window.localStorage.getItem('lang')))
    }
    if (tokenCookie && tokenCookie !== '' && token !== tokenCookie) {
      if (role) dispatch(setLoginRoleAndVerify(role, tokenCookie))
      else dispatch(setLoginToken(tokenCookie))
    }
  }, [])
  return null
}

export default SetTokenFromCookie
