import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import TeamCard from 'components/basic_page/service/team_card.js'

function Team({ teacher_list }) {
  const lang = useSelector((s) => s.front.lang)
  let content = {
    english: {
      title: 'Counselling Team',
      button: 'See More Tutors',
    },
    chinese: {
      titile: '諮詢團隊',
      button: '查看更多小老師',
    },
  }
  if (lang === 'zh') {
    content = content.chinese
  } else if (lang === 'en') {
    content = content.english
  }

  const result_list = teacher_list
    .map((x, i) => {
      return i % 3 === 0 ? teacher_list.slice(i, i + 3) : null
    })
    .filter((x) => x != null)

  return (
    <div>
      <h3 className='size-2 text-blue text-center py-3'>{content.title}</h3>

      {result_list.map((result, index) => {
        if (index + 1 === result_list.length) {
          return (
            <div className='row' key={index}>
              {result.map((t) => (
                <div className='col-sm-4 my-3'>
                  <TeamCard teacher={t} />
                </div>
              ))}
              <div class='col-sm-4 my-3'>
                <div className='card h-100'>
                  <div className='my-auto text-center'>
                    <Link to='/basic/service'>
                      <Button variant='orange'>{content.button}</Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )
        } else {
          return (
            <div className='row' key={index}>
              {result.map((t) => (
                <div className='col-sm-4 my-3'>
                  <TeamCard teacher={t} />
                </div>
              ))}
            </div>
          )
        }
      })}
    </div>
  )
}

export default Team
