import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Collapse from 'react-bootstrap/Collapse'
import Button from 'react-bootstrap/Button'

import axios from 'axios'

function QAPage() {
  const [qas, setQA] = useState([])
  const lang = useSelector((s) => s.front.lang)

  useEffect(() => {
    axios
      .get('/en/prof/api/getqnaFrontList')
      .then((res) => {
        if (res.data.status === 'OK') {
          // console.log(res.data.result.qna)
          setQA(res.data.result.qna)
        } else {
          // console.log(res.data)
          alert(res.data.result)
        }
      })
      .catch((err) => {
        // console.log(err)
      })
  }, [])

  return (
    <div className='container mb-5'>
      <h1 className='size-1 my-5 text-left text-sm-center'>{lang === 'zh' ? '常見問題' : 'FAQ'}</h1>
      {qas.map((p, idx) => {
        return (
          <Qacard
            key={idx}
            Que={lang === 'zh' ? p.question_zh : p.question_en}
            Ans={lang === 'zh' ? p.answer_zh : p.answer_en}
            idx={idx}
          />
        )
      })}
    </div>
  )
}

export default QAPage

function Qacard(props) {
  const [open, setOpen] = useState(false)
  const { Que, Ans, idx } = props

  return (
    <div className='q-box size-3 p-3 mb-3'>
      <Button
        onClick={() => setOpen(!open)}
        aria-controls={'collapse' + idx}
        aria-expanded={open}
        href='#'
        bsPrefix='no'
      >
        <Row>
          <Col xs='auto' className='my-auto'>
            <div className='q-circle bg-green'>{`Q${idx + 1}`}</div>
          </Col>
          <Col className='my-auto'>
            <p className='m-0'>{Que}</p>
          </Col>
          <Col xs={1} className='my-auto p-0'>
            <i class='fas fa-chevron-down text-blue'></i>
            <i class='fas fa-chevron-up text-blue d-none'></i>
          </Col>
        </Row>
      </Button>

      <Collapse in={open}>
        <div id={'collapse' + idx}>
          <hr />
          <div className='row'>
            <div className='col-auto'>
              <div className='q-circle bg-red'>{`A${idx + 1}`}</div>
            </div>
            <div className='col my-auto'>
              <p className='m-0'>{Ans}</p>
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  )
}
