import { useSelector } from 'react-redux'
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom'

function ContactUs() {
  const lang = useSelector((s) => s.front.lang)
  let content = {
    english: {
      title: 'If you have any problems',
      button: 'Please Contact Us',
    },
    chinese: {
      title: '如有任何問題',
      button: '歡迎聯絡我們',
    },
  }
  if (lang === 'zh') {
    content = content.chinese
  } else if (lang === 'en') {
    content = content.english
  }

  return (
    <div className='contact-sec'>
      <h3 className='size-2 text-dark text-center py-3'>{content.title}</h3>

      <div className='text-center mt-3'>
        <Link to='/basic/contact'>
          <Button variant='orange'>{content.button}</Button>
        </Link>
      </div>
    </div>
  )
}

export default ContactUs
