import { useSelector } from 'react-redux'
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom'
import jumbotronPic from 'img/logos/weblogo/case_1.svg'

function Jumbotron({ alert }) {
  const lang = useSelector((s) => s.front.lang)
  let content = {
    english: {
      emergency: 'Emergency',
      title: 'Individual Counseling Service',
      text: 'Tackle your problem with tutors and improve your learning results',
      button: 'Tutor apply',
      important_note_title: '',
      important_note_1: '',
      important_note_2: '',
    },
    chinese: {
      emergency: '緊急通知',
      title: '個案學習諮詢',
      text: '與小老師一同面對課業問題，提升學習成效',
      button: '教師申請',
      important_note_title: '課輔小老師聘僱重要提醒說明：',
      important_note_1: '※小老師受僱投保期間，每月皆須報支薪資，同時勞（小老師）、雇（本中心）雙方分別繳納「個人負擔之勞健保費」及「雇主負擔之勞健保費及勞退金」。小老師每月皆須有輔導事實，方可支薪，在有支薪的情況下，本中心才會支應上述費用。',
      important_note_2: '※小老師可能會遇到學生失約或無人需要諮詢之問題，導致整個月無輔導，在無工作事實的情況下，本中心無法核發薪資及支付「雇主負擔之勞健保費」，請相關學系自行支應此筆勞健保費，並代小老師支應其「個人負擔之勞健保費」（若查該情形為小老師本人失職所致，小老師個人勞健保費則請其自行負擔）。若有此情形，請各系洽人事室綜合業務組，人事室將開具繳費單，請各系至出納組繳納。',
    },
  }
  if (lang === 'zh') {
    content = content.chinese
  } else if (lang === 'en') {
    content = content.english
  }
  // console.log(alert)

  let alertItem = <div></div>
  if (alert) {
    alertItem = (
      <div class='alert alert-danger mt-3' role='alert'>
        {content.emergency}
        <div
          className='alert-danger-text'
          dangerouslySetInnerHTML={{ __html: lang === 'zh' ? alert.content_zh : alert.content_en }}
        ></div>
      </div>
    )
  }

  return (
    <div className='row jumbotron-container'>
      <div className='col-sm-6 order-sm-1 order-2 d-flex flex-column justify-content-center px-5'>
        <h3 className='size-1 text-center text-sm-left'>{content.title}</h3>
        <h4 className='size-4 py-3 text-center text-sm-left'>{content.text}</h4>
        <div className='text-center text-sm-left'>
          <Link to='/individual/apply'>
            <Button variant='orange'>{content.button}</Button>
          
          </Link>
          <small>
              <p style={{marginTop : '10px'}}><strong>{content.important_note_title}</strong></p>
              <p>
              {content.important_note_1}
              </p>
              <p>
              {content.important_note_2}
              </p>

            </small>
        </div>

        {alertItem}
      </div>
      <div className='col-sm-6 order-sm-2 order-1 text-center text-sm-right mb-5 mb-sm-0'>
        <img src={jumbotronPic} className='basic-1' alt='logo' />
      </div>
    </div>
  )
}

export default Jumbotron
