import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import axios from 'axios'

const BACKEND_API = process.env.REACT_APP_BACKEND_API

function TutorListPage() {
  const token = useSelector((s) => s.login.token)
  const [query, setQuery] = useState('')
  const [data, setData] = useState([])

  const fetchData = (name, account) => {
    axios
      .get('/en/prof/api/getDeptTutorList/' + token + '/9999999/1', {
        params: {
          'filter[name]': name,
          'filter[account_no]': account,
        },
      })
      .then((res) => {
        if (res.data.status === 'OK') {
          const new_data = res.data.result.tutorList
          // console.log(new_data)
          setData(new_data)
        } else {
          // console.log(res.data)
          alert(res.data.result)
        }
      })
      .catch((err) => {
        // console.log(err)
        alert('發生錯誤')
      })
  }

  useEffect(() => {
    fetchData()
  }, [token])

  const handleSearch = (e) => {
    e.preventDefault()

    if (/^[a-zA-z]/.test(query)) {
      fetchData(undefined, query)
    } else if (query !== '') {
      fetchData(query)
    } else {
      fetchData()
    }
  }

  return (
    <div className='container mb-5' id='record'>
      <div className='absolute-bg'></div>
      <h1 className='size-1 my-5 text-left text-sm-center'>小老師列表</h1>

      <Form.Row className='mb-3'>
        <Col xs={10} lg={4}>
          <Form.Control
            className='h-100'
            placeholder='請輸入姓名或學號'
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        </Col>
        <Col xs={2} lg={'auto'}>
          <Button variant='primary' bsPrefix='db-btn' onClick={handleSearch}>
            搜尋
          </Button>
        </Col>
      </Form.Row>

      <div className='mobile-table d-block d-lg-none size-6'>
        {data.length <= 0 ? '目前尚無申請記錄' : null}
        {data.map((d) => (
          <div className='mobile-table-item p-3'>
            <div className='d-flex justify-content-between'>
              <span className='text-bold'>小老師</span>
              <span className='text-grey'>{d.name}</span>
            </div>
            <div className='d-flex justify-content-between'>
              <span className='text-bold'>學號</span>
              <span className='text-grey'>{d.account_no}</span>
            </div>
            <div className='d-flex justify-content-between'>
              <span className='text-bold'>輔導科目</span>
              <span className='text-grey'>
                {d.tutor_subjects.map((s, idx) => {
                  if (idx === d.tutor_subjects.length - 1) return s
                  else return s + ','
                })}
              </span>
            </div>
            <div className='d-flex justify-content-between'>
              <span className='text-bold'>輔導總時數</span>
              <span className='text-grey'>{d.total_hour}</span>
            </div>
            <div className='d-flex justify-content-between'>
              <span className='text-bold'>輔導總人數</span>
              <span className='text-grey'>{d.total_student}</span>
            </div>
            <div className='d-flex justify-content-between'>
              <span className='text-bold'>輔導總人次</span>
              <span className='text-grey'>{d.total_consultation}</span>
            </div>
            <div className='d-flex justify-content-between'>
              <span className='text-bold'>匯出紀錄</span>
              <span className='text-grey'>
                <Button
                  size='sm'
                  variant='green'
                  href={`${BACKEND_API}/en/prof/api/exportDeptTutorConsultList/${d.user_id}/${token}`}
                  className='size-6'
                >
                  匯出紀錄
                </Button>
              </span>
            </div>
          </div>
        ))}
      </div>

      <Table borderless striped className='text-grey text-center d-none d-lg-table'>
        <thead>
          <tr>
            <th scope='col'>小老師</th>
            <th scope='col'>學號</th>
            <th scope='col'>輔導科目</th>
            <th scope='col'>輔導總時數</th>
            <th scope='col'>輔導總人數</th>
            <th scope='col'>輔導總人次</th>
            <th scope='col'>匯出紀錄</th>
          </tr>
        </thead>
        <tbody className='reserve-record-tobdy'>
          {data.map((d) => (
            <tr>
              <td>{d.name}</td>
              <td>{d.account_no}</td>
              <td>
                {d.tutor_subjects.map((s, idx) => {
                  if (idx === d.tutor_subjects.length - 1) return s
                  else return s + ','
                })}
              </td>
              <td>{d.total_hour}</td>
              <td>{d.total_student}</td>
              <td>{d.total_consultation}</td>
              <td>
                <Button
                  variant='green'
                  href={`${BACKEND_API}/en/prof/api/exportDeptTutorConsultList/${d.user_id}/${token}`}
                >
                  匯出紀錄
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

export default TutorListPage
