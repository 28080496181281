import { StrictMode, useEffect } from 'react'
import ReactDOM from 'react-dom'
import './custom.scss'
import './index.css'
import App from './App'
import { BrowserRouter, useLocation } from 'react-router-dom'

ReactDOM.render(
  <StrictMode>
    <BrowserRouter basename='/web'>
      <ScrollToTop />
      <App />
    </BrowserRouter>
  </StrictMode>,
  document.getElementById('root')
)

function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}
