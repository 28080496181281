import { Suspense, lazy } from 'react'
import IndividualPage from 'containers/individual_page/main/individual_page'
import { Switch, Route } from 'react-router-dom'
import { Provider } from 'react-redux'
import { individual_store } from 'store/individual/configureStore'
import PrivateRoute from 'private-route.js'
import SetTokenFromCookie from 'set-token.js'
const IndividualDBPage = lazy(() => import('containers/individual_db/main/individual_db'))

function Individual() {
  return (
    <Switch>
      <Provider store={individual_store}>
        <SetTokenFromCookie cate='individual' />
        <Suspense fallback={<div>載入中...</div>}>
          <PrivateRoute path='/individual_db' check_role='admin' cate='individual' Component={IndividualDBPage} />
        </Suspense>
        <Route path='/individual' render={({ match }) => <IndividualPage match={match} />} />
      </Provider>
    </Switch>
  )
}

export default Individual
