import { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Jumbotron from 'components/individual_page/main/jumbotron.js'
import News from 'components/individual_page/news/news.js'
import Service from 'components/individual_page/service/service.js'
import ContactUs from 'components/individual_page/contact/contact_us.js'

import axios from 'axios'

function BasicPageIndex({ match }) {
  const [urgent, setUrgent] = useState('')
  const [news, setNews] = useState([])

  useEffect(() => {
    axios
      .get('/en/spcl/api/getHomeData')
      .then((res) => {
        let new_news = []
        for (const article of res.data.result.article) {
          if (article.is_deleted === '0') {
            new_news.push(article)
          }
        }

        new_news = new_news.sort((a, b) => {
          if (a.stick_to_top == 1 && b.stick_to_top != 1) return -1
          else if (b.stick_to_top == 1 && a.stick_to_top != 1) return 1
          else return new Date(b.date) - new Date(a.date)
        })
        setNews(new_news)
        // if (res.data.result.news[0]) setUrgent(res.data.result.news[0])
      })
      .catch((err) => {
        // console.log(err)
        //alert('獲取主頁資料錯誤')
      })
  }, [])

  return (
    <>
      <section id='jumbotron'>
        <Container className='py-5'>
          <Jumbotron path={match.path} alert={urgent} />
        </Container>
      </section>

      <section id='news'>
        <Container className='py-5'>
          <News news={news} />
        </Container>
      </section>

      <section id='service' className='bg-white'>
        <Container className='py-5'>
          <Service />
        </Container>
      </section>

      <section id='contactus'>
        <Container className='py-5'>
          <ContactUs />
        </Container>
      </section>
    </>
  )
}

export default BasicPageIndex
