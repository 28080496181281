import { Component } from 'react'
import Button from 'react-bootstrap/Button'

class NewsItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isHovering: false,
    }
  }

  handleMouseEnter = () => {
    this.setState({ isHovering: true })
  }

  handleMouseLeave = () => {
    this.setState({ isHovering: false })
  }

  render() {
    const arrow = (
      <div className='news-item-arrow'>
        <i class='fas fa-arrow-right text-light'></i>
      </div>
    )

    return (
      <div
        className='news-item my-3 p-3 px-4 d-flex flex-nowrap justify-content-between align-center'
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <div className='d-flex flex-column flex-md-row news-item-text'>
          <div className='mb-2 mb-md-0 mr-3'>
            <Button variant={this.props.isNewest ? 'danger' : 'green'}>{this.props.date}</Button>
          </div>
          <div className='my-auto'>
            <p className='size-4 m-0'>{this.props.title}</p>
          </div>
        </div>
        <div className='my-auto'>{this.state.isHovering ? arrow : <div></div>}</div>
      </div>
    )
  }
}

export default NewsItem
