import { useSelector } from 'react-redux'
import Accordion from 'react-bootstrap/Accordion'

function Rules() {
  const lang = useSelector((s) => s.front.lang)

  const rules = [
    {
      title: '預約資格',
      title_en: 'Qualification for Reservation',
      content: (
        <div style={{ 'font-size': '16px', color: '#05386b' }}>
          <p>✎ 本服務申請者以本校學生為限。</p>
        </div>
      ),
      content_en: (
        <div style={{ 'font-size': '16px', color: '#05386b' }}>
          <p>✎ This service is provided for NTU students only.</p>
        </div>
      ),
    },
    {
      title: '預約規定',
      title_en: 'Reservation Regulations',
      content: (
        <div style={{ 'font-size': '16px', color: '#05386b' }}>
          <p>✎ 每人可預約 14 天內的時段，每人 7 天內至多可預約 2 個時段。</p>
          <p>✎ 完成諮詢後，請回到預約系統「歷史紀錄」中填寫回饋問卷，若問卷未完成，將無法預約下次諮詢。</p>
          <p>
            ✎
            請注意，系統不開放當日預約，但歡迎先至預約系統查看欲諮詢時段是否預約額滿，若未額滿，仍可至綜合學習開放空間現場登記諮詢。
          </p>
          <p>
            ✎ 若同學想找其他同學、朋友來諮詢相同的科目或主題，請派一位同學代表預約，預約時人數上限為 2 人，
            該預約代表務必出席，因此，同一時段小老師最多可教 4 位學生。
          </p>
        </div>
      ),
      content_en: (
        <div style={{ 'font-size': '16px', color: '#05386b' }}>
          <p>✎ Every student may only reserve 14 days in advance, with a maximum of 2 sessions in 7 days.</p>
          <p>
            ✎ After the tutoring session is over, please log back in to the online reservation system and fill out the
            questionnaire for that session. You will not be able to make any more reservations until you fill out the
            questionnaire.
          </p>
          <p>
            ✎ If the online reservation system is closed on a certain day or is not working properly for you, you can
            make a reservation in person at the Learning Commons front desk. We recommend checking the online calendar
            to make sure that the tutoring session you wish to reserve isn’t already full before coming to the Learning
            Commons. This will help save you some time!
          </p>
          <p>
            ✎ If you wish to bring a friend to the same tutoring session, please designate one person to place the
            reservation online. Every student may only bring one other person with them, so please make sure to show up
            on time. Please note that if two different students reserve the same session and both bring a friend with
            them, the tutor will be helping a maximum of 4 students per session.
          </p>
        </div>
      ),
    },
    {
      title: '出缺規定',
      title_en: 'Attendance Regulations',
      content: (
        <div style={{ 'font-size': '16px', color: '#05386b' }}>
          <p>✎ 未於預約前 1 日取消，視為缺席。每學期取消 5 次以上或無故缺席 2 次以上，則取消當學期之諮詢資格。</p>
        </div>
      ),
      content_en: (
        <div style={{ 'font-size': '16px', color: '#05386b' }}>
          <p>
            ✎ If you do not cancel your reservation at least one day in advance, it will be counted as a missed session.
            If you cancel a reservation more than 5 times or miss a reserved session more than 2 times during the
            semester, your right to make reservations will be revoked for the rest of the semester.
          </p>
        </div>
      ),
    },
    {
      title: '校內法規',
      title_en: 'Campus Regulations',
      content: (
        <div style={{ 'font-size': '16px', color: '#78797f' }}>
          <h4>國立臺灣大學教務處教學發展中心個別學習諮詢實施要點</h4>
          <br />
          <div class='version'>
            中華民國 96 年 2 月 6 日校第 2467 次行政會議通過
            <br />
            中華民國 96 年 2 月 26 日校教字第 0960005575 號公告發布
            <br />
            中華民國 96 年 5 月 22 日校第 2481 次行政會議修正通過
            <br />
            中華民國 96 年 6 月 4 日校教字第 0960017319 號公告修正
            <br />
            中華民國 100 年 12 月 13 日校第 2696 次行政會議修正通過
            <br />
            中華民國 100 年 12 月 29 日校教字第 1000109335 號公告修正
            <br />
            中華民國 105 年 12 月 27 日校第 2932 次行政會議討論通過
            <br />
            中華民國 106 年 1 月 12 日校教字第 1060002764 號公告修正
          </div>
          <br />
          <ol style={{ 'list-style-type': 'trad-chinese-informal', 'line-height': '1.6em' }}>
            <li>
              國立臺灣大學教務處教學發展中心 (以下簡稱本中心)
              為因應本校學生課業需求,提供個別化之學習諮詢服務,由課業輔導小老師於學習開放空間進行課業輔導,以有效解決課業方面之疑難,特訂定本要點。
            </li>
            <li>
              諮詢之申請應依下列規定行之:
              <ol>
                <li>
                  (一)申請者以本校學生為限。個別學習諮詢由個人提出申請,採一對一或一對二之輔導方式。團體學習諮詢時,由五人至十二人自行組成學習小組後,提出諮詢申請。
                </li>
                <li>
                  (二)諮詢時間:星期一至星期五下午十二點二十分至晚間九點二十分。學習諮詢每時段以不超過一小時為原則,每人每週預約至多兩個時段。
                </li>
                <li>(三)諮詢地點:本中心學習開放空間。</li>
                <li>(四)諮詢課程:諮詢課程按本中心網站及學習開放空間之公告。</li>
                <li>
                  (五)預約方式:採線上預約方式,或至學習開放空間現場登記。預約以當月及次月時段為限。有更改或取消者,應於預約日一日前通知。每學期取消五次以上或無故缺席超過二次者,立即喪失諮詢資格六個月。
                </li>
              </ol>
            </li>
            <li>
              「課業輔導小老師」(以下簡稱課輔小老師) 職務之行使,應依下列規定行之:
              <ol>
                <li>
                  (一)擔任課輔小老師者以本校學生為限。應徵者應完成培訓課程,並遵守「課輔小老師研習會手冊」各項規範,其服務表現良好,且無違反出勤及請假規定者,由本中心於學期末授予服務證明書。
                </li>
                <li>
                  (二)課輔小老師提供課業諮詢,協助線上學習資源之建置,並於每次輔導結束時,填寫輔導紀錄表,紀錄課業輔導情形。
                </li>
                <li>(三)課輔小老師每週應值班二小時以上,工讀金以時薪新台幣五百元核實支給。</li>
                <li>(四)課輔小老師不得代替學生完成作業。</li>
                <li>(五)課輔小老師應配合進行本中心不定期舉辦之課輔小老師團體督導。</li>
                <li>
                  (六)課輔小老師請假應於二日前告知本中心,每月請假時數不得超過三小時。但有正當事由經本中心同意者,不在此限。未經請假而缺席達二次以上者,不頒予服務證明書,且終止聘任。
                </li>
                <li>(七)課輔小老師於排定值班時間內,應準時出席,不得遲到或早退,出席紀錄列入本中心續聘與否之參考。</li>
                <li>(八)課輔小老師之教學評鑑值連續三個月低於 3.5 者,終止聘任。</li>
                <li>
                  (九)課輔小老師應遵守本中心各項規定,及「課輔小老師研習會手冊」所定之事項,確實保障受輔導學生之權益。有嚴重違背該手冊中規定者,本中心得隨時終止聘任。
                </li>
              </ol>
            </li>
            <li>
              諮詢程序應依下列規定行之:
              <ol>
                <li>(一)諮詢者於課業輔導進行之前,應先備妥問題。</li>
                <li>(二)諮詢者於接受課業輔導之後,須填寫「學習諮詢回饋單」。</li>
                <li>(三)諮詢者不得遲到或缺席。</li>
                <li>(四)諮詢討論以課業內容為限。</li>
                <li>(五)諮詢進行中不得使用手機及其他通訊用品。</li>
                <li>(六)課輔小老師與諮詢者應共同維護環境之整潔。</li>
              </ol>
            </li>
            <li>本要點未盡事宜時,按本中心公告辦理。</li>
            <li>本要點經本校行政會議通過後,自發布日施行。</li>
          </ol>
        </div>
      ),
      content_en: (
        <div style={{ 'font-size': '16px', color: '#78797f' }}>
          <h4 style={{ 'font-weight': 'bold', 'text-align': 'center' }}>NATIONAL TAIWAN UNIVERSITY</h4>
          <h4 style={{ 'font-weight': 'bold', 'text-align': 'center' }}>Office of Academic Affairs</h4>
          <h4 style={{ 'font-weight': 'bold', 'text-align': 'center' }}>
            Center for Teaching and Learning Development
          </h4>
          <h4 style={{ 'text-align': 'center' }}>Directives for the Implementation of Common Courses Tutoring</h4>
          <br />
          <div class='version'>
            February 6, 2007— Approved at the 2467th Administrative Meeting of the University
            <br />
            February 26, 2007— Promulgated via NTU Xiao-Jiao-Zi No. 0960005575
            <br />
            May 22, 2007— Amended and approved at the 2481st Administrative Meeting of the University
            <br />
            June 4, 2007— Amendment promulgated via NTU Xiao-Jiao-Zi No. 0960017319
            <br />
            December 13, 2011— Amended and approved at the 2696th Administrative Meeting of the University
            <br />
            December 29, 2011— Amendment promulgated via NTU Xiao-Jiao-Zi No. 1000109335
            <br />
            December 27, 2016— Amended and approved at the 2932nd Administrative Meeting of the University
            <br />
            January 12, 2017— Amendment promulgated via NTU Xiao-Jiao-Zi No. 1060002764
          </div>
          <br />
          <ol style={{ 'line-height': '1.6em' }}>
            <li>
              The Center for Teaching and Learning Development under the Office of Academic Affairs–(hereinafter
              referred to as “the Center”) has formulated the following directives to establish a tutoring program that
              addresses individual challenges and needs, with academic counseling provided by tutors at the Learning
              Commons of National Taiwan University (hereinafter, “the University”).
            </li>
            <li>
              Tutoring sessions may be scheduled in accordance with the following provisions:
              <ol type='a'>
                <li>
                  Only students enrolled in the University may apply. Individual tutoring sessions may be reserved by
                  individual students and will be conducted on a one-on-one or one-on-two basis. Group tutoring sessions
                  may be reserved by an autonomously formed learning group consisting of five to twelve students.
                </li>
                <li>
                  Time: Tutoring sessions may be scheduled between 12:20 p.m. and 9:20 p.m., Monday through Friday, with
                  each session lasting no longer than one hour. Each student may reserve up to two sessions per week.。
                </li>
                <li>Place: The Center’s Learning Commons</li>
                <li>
                  Available subjects: Please refer to announcements posted on the Center website and at the Learning
                  Commons.
                </li>
                <li>
                  Method of reservation: Tutoring sessions for the current and following month may be reserved online or
                  in person at the Learning Commons. Changes or cancelations must be made at least one day in advance.
                  Students who cancel five times or miss an appointment without excuse more than twice in the same
                  semester shall be barred from making reservations for a period of six months.
                </li>
              </ol>
            </li>
            <li>
              Matters pertaining to academic counseling tutors (hereinafter, “tutor(s)”) shall be handled in accordance
              with the following provisions:
              <ol type='a'>
                <li>
                  Only students of the University may apply to be tutors. Applicants must complete the onboarding
                  training and agree to follow the rules set forth in the tutor training handbook. Tutors who show
                  satisfactory service performance and have no record of violating attendance and leave guidelines shall
                  be issued a certificate of service by the Center at the end of each semester.
                </li>
                <li>
                  Tutors shall be responsible for providing academic counseling, helping expand the Center’s online
                  learning resources, and filling out a tutoring record form after each tutoring session.
                </li>
                <li>Each tutor must serve at least a two-hour shift per week at an hourly wage of NT$ 500.</li>
                <li>Tutors shall not complete homework assignments for other students.</li>
                <li>Tutors shall participate in the group counseling sessions held nonperiodically by the Center.</li>
                <li>
                  Tutors may request a leave of absence two days prior to a scheduled shift. Unless with just cause and
                  the express approval of the Center, leaves of absence shall not exceed three hours per month. Tutors
                  with two or more unexcused absences shall be terminated without being issued a certificate of service.
                </li>
                <li>
                  Tutors are required to show up for their shifts on time. Tardiness and early departure will not be
                  tolerated. The Center may consider tutors’ attendance records when making renewal decisions.
                </li>
                <li>
                  Tutors receiving an evaluation score of less than 3.5 points for three consecutive months shall be
                  terminated.
                </li>
                <li>
                  Tutors shall abide by all regulations of the Center and rules set forth in the tutor training handbook
                  to safeguard the rights of students seeking counseling. Serious violations shall be grounds for
                  immediate termination.
                </li>
              </ol>
            </li>
            <li>
              Students seeking counseling shall abide by the following rules:
              <ol type='a'>
                <li>Students shall prepare a list of questions in advance.</li>
                <li>Students are required to fill out a tutoring feedback form after each session.</li>
                <li>Students shall not be late for or absent from a scheduled session.</li>
                <li>The scope of tutoring shall be restricted to University coursework only.</li>
                <li>
                  The use of cellphones and other telecommunication devices is forbidden during tutoring sessions.
                </li>
                <li>
                  Both tutor and student(s) are responsible for keeping the classroom environment clean and tidy.{' '}
                </li>
              </ol>
            </li>
            <li>
              Matters not addressed herein shall be handled in accordance with the relevant announcements posted by the
              Center.
            </li>
            <li>These directives shall take effect upon approval of the Administrative Meeting of the University.</li>
          </ol>
        </div>
      ),
    },
  ]

  return (
    <div className='container py-5'>
      <h3 className='size-3 text-blue pb-3 text-left'>{lang === 'zh' ? '相關規定' : 'Rules'}</h3>

      <Accordion>
        {rules.map((e, id) => (
          <div className='s-rule-box size-3 p-3 mb-3'>
            <Accordion.Toggle
              as='div'
              eventKey={id.toString()}
              type='button'
              className='d-flex justify-content-between'
            >
              <p className='m-0'>{lang === 'zh' ? e.title : e.title_en}</p>
              <i class='fas fa-chevron-down text-blue'></i>
              <i class='fas fa-chevron-up text-blue d-none'></i>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={id.toString()}>
              <div>
                <hr />
                <p className='m-0'>{lang === 'zh' ? e.content : e.content_en}</p>
              </div>
            </Accordion.Collapse>
          </div>
        ))}
      </Accordion>
    </div>
  )
}

export default Rules
